<template>
    <div :class="['navbar-area', {'is-sticky': isSticky}]">
        <div class="valzo-nav">
            <div class="container">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/images/logo.png" alt="logo">
                    </router-link>

                    <b-navbar-toggle target="navbarSupportedContent" class="toggle-x">
                        <span class="icon-bar top-bar"></span>
                        <span class="icon-bar middle-bar"></span>
                        <span class="icon-bar bottom-bar"></span>
                    </b-navbar-toggle>

                    <b-collapse class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" is-nav>
                        <div v-if="this.$user.ID_USER == 'dareadmin'" class="login-box"><a href="/user-login">로그인</a></div>
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <router-link to="/" class="nav-link">
                                    Home
                                </router-link>
                            </li>

                            <li class="nav-item">
                                <router-link  to="/about" class="nav-link">
                                    회사소개
                                    <i class='bx bx-chevron-down'></i>
                                </router-link>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link  to="/about" class="nav-link">
                                            회사소개
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link  to="/history" class="nav-link">
                                            연혁
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link  to="/organization-chart" class="nav-link">
                                            조직도
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link  to="/location" class="nav-link">
                                            찾아오시는 길
                                        </router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <router-link  to="/services-one" class="nav-link">
                                    제품소개
                                    <i class='bx bx-chevron-down'></i>
                                </router-link>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link  to="/services-one" class="nav-link">
                                            ERP솔루션
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link  to="/services-two" class="nav-link">
                                            MES
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link  to="/services-link" class="nav-link">
                                            LINK(링크)
                                        </router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <router-link  to="/projects-one" class="nav-link">
                                    정부지원사업
                                    <i class='bx bx-chevron-down'></i>
                                </router-link>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link  to="/projects-one" class="nav-link">
                                            스마트 공장
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link  to="/projects-two" class="nav-link">
                                            스마트 공방
                                        </router-link>
                                    </li>

                                    <li v-if="this.$user.ID_USER == 'dareadmin'" class="nav-item">
                                        <router-link  to="/projects-three" class="nav-link">
                                            세제 환급 사업
                                        </router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <router-link  to="/notice" class="nav-link">
                                    고객센터
                                    <i class='bx bx-chevron-down'></i>
                                </router-link>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link  to="/notice" class="nav-link">
                                            공지사항
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link  to="/download" class="nav-link">
                                            데모프로그램 신청&다운로드
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link  to="/video" class="nav-link">
                                            교육 동영상
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link  to="/manual" class="nav-link">
                                            사용자매뉴얼
                                        </router-link>
                                    </li>

                                    <!-- <li class="nav-item">
                                        <router-link  to="/faq" class="nav-link">
                                            자주하는 질문
                                        </router-link>
                                    </li> -->

                                    <li class="nav-item">
                                        <router-link  to="/qna" class="nav-link">
                                            1:1 문의사항
                                        </router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <router-link  to="/customer" class="nav-link">
                                    주요고객
                                </router-link>
                            </li>

                            <li class="nav-item">
                                <router-link  to="/dare-people" class="nav-link">
                                    인재채용
                                    <i class='bx bx-chevron-down'></i>
                                </router-link>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link  to="/dare-people" class="nav-link">
                                            인사제도&직무
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link  to="/promotion" class="nav-link">
                                            승진제도
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link  to="/benefits" class="nav-link">
                                            복리후생
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link  to="/job-posting" class="nav-link">
                                            채용공고
                                        </router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <router-link  to="/apply-join" class="nav-link">
                                    사업신청
                                </router-link>
                            </li>
                            <!-- <li v-if="this.$user.ID_USER == 'dareadmin'" class="nav-item">
                                <router-link  to="/apply-join" class="nav-link">
                                    사업신청
                                </router-link>
                            </li> -->
                        </ul>

                        <div class="others-options">

                            <div class="burger-menu" v-b-modal.modal-1>
                                <img src="../../assets/images/icon-mail.png" alt="image">
                            </div>

                            <SidebarModal />
                        </div>
                    </b-collapse>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarModal from '../Layout/SidebarModal'

    export default {
        name: 'Navbar',
        components: {
            SidebarModal
        },

        data(){
            return {
                isSticky: false
            }
        },

        mounted(){
            const that = this
            window.addEventListener('scroll', () => {
                let scrollPos = window.scrollY
                if(scrollPos >= 100){
                    that.isSticky = true
                } else {
                    that.isSticky = false
                }
            })
        },

        methods: {
        }
    }
</script>
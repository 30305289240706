<template>
    <div class="page-title-area item-bg-2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-title-content">
                        <h2>BAR CODE</h2>
                        <ul>
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/services-one">제품소개</router-link></li>
                            <li>BAR CODE</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTitle'
    }
</script>
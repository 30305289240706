<template>
    <div class="contact-box pt-100 pb-70 d-location">
        <div class="container">
            <div class="section-title">
                <h2>위치 및 연락처</h2>
                <div class="bar"></div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="single-contact-box d-min-h-260">
                        <i class="flaticon-pin"></i>
                        <div class="content-title">
                            <h3 class="font-blue">Address</h3>
                            <p class="d-w-b-keep-all"><i class="flaticon-tick"></i> <span class="font-blue">도로명 : </span>(07229)서울특별시 영등포구 경인로 775, 1동 1509호</p>
                            <p class="d-w-b-keep-all"><i class="flaticon-tick"></i> <span class="font-blue">지번 : </span>서울특별시 영등포구 문래동 3가, 에이스하이테크시티 1동 1509호</p>
                            <!-- <p class="d-w-b-keep-all"><i class="flaticon-tick"></i> <span class="font-blue">영문도로명 : </span>ACE High Tech City2 908-909, 25, Seonyu-ro 13-gil, Yeongdeungpo-gu, Seoul, Korea (07282)</p> -->
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-7">
                    <div class="single-contact-box d-min-h-260">
                        <i class="flaticon-phone-call"></i>
                        <div class="m-0 content-title">
                            <h3 class="mb-0 font-blue">Phone</h3>
                            <p class="m-0 mt-2"><i class="flaticon-tick"></i> <span>대표번호(컨설팅, 기타문의): </span><a class="mb-0" href="tel:0269250995">02-6925-0995</a></p>
                            <p><i class="flaticon-tick"></i> <span>영업/프로그램 구입문의: </span><a class="mb-0" href="tel:0269250995">02-6925-0995</a></p>
                            <p><i class="flaticon-tick"></i> <span>개발문의: </span><a class="mb-0" href="tel:0269250995">02-6925-0995</a></p>
                            <!-- <p><i class="flaticon-tick"></i> <span>영업/프로그램 구입문의: </span><a class="mb-0" href="tel:01057380963">010-5738-0963</a></p> -->
                            <!-- <p><i class="flaticon-tick"></i> <span>개발문의: </span><a class="mb-0" href="tel:07046400077">070-4640-0076</a></p> -->
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 col-md-5">
                    <div class="single-contact-box d-min-h-260">
                        <i class="flaticon-envelope"></i>
                        <div class="m-0 content-title">
                            <h3 class="mb-0 font-blue">Email</h3>
                            <p class="m-0 mt-2"><i class="flaticon-tick"></i> <span>이메일: </span><a class="mb-0 d-w-b-break-all" href="mailto:dareinc2012@gmail.com">dareinc2012@gmail.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContactBox'
    }
</script>
<template>
    <div class="clients-section pt-100">
        <div class="container">
            <div class="section-title">
                <h2>지원내용</h2>
                <p>주요 검토 공제감면</p>
                <div class="bar"></div>
            </div>

            <div class="details-image">
                <img src="../../assets/images/projects/projects-three-2.jpg" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProjectsAbout'
    }
</script>
<template>
    <div>
        <Navbar />
        <PageTitle />
        <Benefits />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import PageTitle from '../Benefits/PageTitle'
    import Benefits from '../Benefits/Benefits'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'BenefitsPage',
        components: {
            Navbar,
            PageTitle,
            Benefits,
            Footer,
            CopyRight,
        }
    }
</script>
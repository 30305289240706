<template>
    <div class="copyright-area">
        <div class="container">
            <div class="copyright-area-content">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12">
                        <p class="text-center">
                            Copyright @ by DARE Inc. Co. Ltd. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CopyRight'
    }
</script>
<template>
    <div>
        <v-stepper-step
        :complete="getComplete()"
        :step="stepnum"
        @click="clicked"
        >
            <!-- e나라도움 가입정보(ID/PW) -->
            {{TITLE}}
            <small v-if="bSaved==true">작성완료</small>
            <small v-if="bSaved==false"><span>미완료</span></small>
        </v-stepper-step>

        <!-- <v-stepper-content step="21"> -->
        <v-stepper-content :step="stepnum">
            <v-card
                :loading="loading"
                class="mb-12"
                max-width="560"
            >
                <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/6UjZiu8VoqA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                ></iframe>
                <div class="form-group mt-4">
                    <label for="input-name"><h5 class="font-w-normal">e나라도움 가입정보(ID/PW)</h5></label>
                    <a href="https://www.gosims.go.kr/hf/login/loginView.do" target="_blank" class="d-flex dare-btn read-more bg-color-white font-blue justify-center border-blue mt-2">e나라 도움 사이트 바로가기</a>
                    <input type="text" name="joininfo" id="input-joininfo" v-model="JOIN_INFO_ID" class="form-control mt-4" required placeholder="ID(아이디)">
                    <input type="text" name="joininfo" id="input-joininfo" v-model="JOIN_INFO_PW" class="form-control mt-1" required placeholder="PW(비밀번호)">
                </div>
            </v-card>

            <div class="text-center my-6">
                <v-btn
                    class="d-inline-block mr-1"
                    color="primary"
                    v-if="CLOSE_YN === 'N'"
                    @click="saveClicked"
                >
                    저장
                </v-btn>
                <v-btn
                    class="d-inline-block mr-1"
                    color="primary"
                    @click="nextClicked"
                >
                    계속
                </v-btn>
                <v-btn
                    class="d-inline-block bg-color-gray"
                    text
                    @click="cancelClicked"
                >
                    닫기
                </v-btn>
            </div>
        </v-stepper-content>
    </div>
</template>

<script>

    export default {
        components: {

		},
        name: 'DOC_joininfo',
        props: {
            psteptitle: {type:String, default:'e나라도움 가입정보(ID/PW)'},
            psteptp: {type:String, default:'1'},
            pstepnum: {type:Number , default:1},
            // pdocno: {type:Number, default:1},
            // pmodcd: {type:String, default:''},
            // pbizcd: {type:String, default:''},
            // pcloseyn: {type:String, default:'N'},
        },
        data() {
            return {
                TITLE: '',
                bSaved: false, //작성완료여부
                CLOSE_YN: 'N',  //신청서가 진행중이면 'N', 마감상태면 'Y' 로 관리함
                STEP_TP: '1',
                stepnum: 1,
                DOC_NO: 1,
                MOD_CD: '',
                JOIN_INFO: '',
                JOIN_INFO_ID: '',
                JOIN_INFO_PW: '',
            };
        },
        created() {
            this.TITLE = this.psteptitle;
            this.STEP_TP = this.psteptp;
            this.stepnum = this.pstepnum;
            this.DOC_NO = this.$route.query.pdocno;
            this.MOD_CD = this.$route.query.pmodcd;
            this.BIZ_CD = this.$route.query.pbizcd;
            this.CLOSE_YN = this.$route.query.pcloseyn;

            // console.log('DOC_JOININFO.vue >>>> created >>>> this.DOC_NO : ', this.DOC_NO, ', this.STEP_TP : ', this.STEP_TP);
        },
        mounted() {

            // console.log('DOC_JOININFO.vue >>>> mounted >>>> this.DOC_NO : ', this.DOC_NO, ', this.STEP_TP : ', this.STEP_TP);

            this.getStepSelect();
        },
        methods:{
            clicked() {
                this.$emit('click');
            },
            getStepSelect() {
                this.$http.get('/board/apply-step-select', {DOC_NO: this.DOC_NO, STEP_TP: this.STEP_TP}).then(res => {

                    if(res.errorcode=="") {
                        if(res.result[0] == null || res.result[0].length == 0 )
                        {
                            // this.$msgBox.show('조회조건을 만족하는 데이터가 없습니다.');
                        }
                        else
                        {
                            this.JOIN_INFO = res.result[0][0].JOIN_INFO;
                            this.JOIN_INFO_ID = this.JOIN_INFO.split('||')[0];
                            this.JOIN_INFO_PW = this.JOIN_INFO.split('||')[1];
                            //this.stepnum = res.result[0][0].STEP_NUM;

                        }
                    }
                    else
                    {
                        alert(res.errorMsg);
                    }
                }).catch(err => {
                    this.loading = false;
                    alert(err)
                })
            },
            async saveClicked() {
                let res = await this.$confirm('작성하신 내용을 저장하시겠습니까?', {
					title: '확인'
				})

                if (res) {
                    this.JOIN_INFO = this.JOIN_INFO_ID + '||' + this.JOIN_INFO_PW;
					this.$http.post('/board/apply-update', {
                        DOC_NO: this.DOC_NO,
                        STEP_TP: this.STEP_TP,
                        STEP_NUM: this.stepnum,
						ETC1: this.JOIN_INFO, //e나라도움 가입정보(ID/PW)
						ETC2: '',
                        ETC3: '',
                        ETC4: '',
                        ETC5: '',
                        ETC6: '',
                        ETC7: '',
                        ETC8: '',
                        ETC9: '',
                        ETC10: '',
                        ETC11: '',
                        ETC12: '',
                        ETC13: '',
                        ETC14: '',
                        ETC15: '',
                        ETC16: '',
                        IMG1: null,
                        CLIENT_IP: this.$applyuser.CLIENT_IP,  //수정자IP
					}).then(res => {

						if (res.result) {
                            this.bSaved = true;
							this.$msgBox.show('저장되었습니다.');
							// this.movePage('/apply-link');
						}
					}).catch(err => {
						alert(err)
					})
				}

                this.$emit('saveclick');
            },
            nextClicked() {
                this.$emit('nextclick');
            },
            cancelClicked() {
                this.$emit('cancelClick');
            },
            getComplete() {
                //e나라도움 가입정보(ID/PW)
                if(this.JOIN_INFO != '') {
                    this.bSaved = true;
                }
                else {
                    this.bSaved = false;
                }

                return this.bSaved;
            },
        }
    }
</script>
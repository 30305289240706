<template>
    <div>
        <Navbar />
        <UserPageTitle />
        <UserLogin />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import UserPageTitle from '../LogIn/UserPageTitle'
    import UserLogin from '../LogIn/UserLogin'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'UserLoginPage',
        components: {
            Navbar,
            UserPageTitle,
            UserLogin,
            Footer,
            CopyRight,
        }
    }
</script>
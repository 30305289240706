<template>
    <div class="login-section ptb-100 d-join">
        <div class="container">
            <div class="login-form">
                <div class="login-title">
                    <h3>관리자 로그인!!!</h3>
                    <p>관리자 로그인 정보를 입력하세요.</p>
                </div>

                <form>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <b-form-input id="id" v-model="id" type="text" placeholder="아이디"></b-form-input>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <b-form-input id="passwd" v-model="passwd" type="password" placeholder="패스워드"></b-form-input>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" v-model="isAutoLogin" id="isAutoLogin">
                                <label class="form-check-label" for="checkme">자동로그인</label>
                            </div>
                        </div>


                        <div class="col-lg-12">
                            <div class="send-btn text-center">
                                <b-button squared variant="outline-secondary" @click="loginCheck" class="w-50 dare-btn mr-1 d-btn-admin"><i class="mdi mdi-account-cog-outline"></i> 로그인</b-button>
                            </div>
                        </div>
                  
                    </div>
                </form>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Login',

        data () {
            return {
                valid: false,
                overlay: false,
                msg: '',
                id:'',
                passwd:'',
                isAutoLogin: this.$user.IS_AUTO_LOGIN == 'y',
                 rules: {
                    idRules: [value => !!value || this.$t('message.REQUIRED', {'msg':this.$t('login.ID')})],
                    pwdRules: [value => !!value || this.$t('message.REQUIRED', {'msg':this.$t('login.PWD')})],
                },
                kakaoId:-1,
            }
        },
        methods: {
            loginCheck() {
                this.overlay = true;
                this.$http.postBeforeAuth('/users/user-check', {"ID_USER":this.id, "PW_USER":this.passwd}).then((res) => {
                    this.overlay = false
                    // console.log(res)
                    if(res.result[0] == null || res.result[0].length == 0)
                    {
                        alert("아이디 혹은 비밀번호가 정확하지 않습니다.");
                        this.$refs.idCtrl.focus()
                        return;
                    }

                    //토큰,사업자번호 받기
                    this.$user.ID_USER = this.id;
                    this.$user.TOKEN = res.token;

                    //로그인상태
                    this.$user.IS_LOGIN = 'y';
                    this.$user.IS_AUTO_LOGIN = this.isAutoLogin ? 'y' : 'n'; //자동로그인 여부
                    // this.$refs.pwdCtrl.blur()

                    //메인 페이지로 이동
                    this.$router.push('/home');
                }).catch(err => {
                    this.overlay = false
                    alert(err);
                })
            },

            autologinChange() {
                localStorage.setItem('isAutoLogin', this.isAutoLogin ? 'y' : 'n');
            },

        }
    }
</script>
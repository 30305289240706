<template>

<v-overlay :value="showMbox">
        <v-dialog v-model="showMbox" max-width="400" persistent scrollable>
            <v-card
                class="mx-auto"
                color="#ffffff"
            >

                <v-card-title dence class="pl-4 py-2 text-left white--text primary lighten-2 align-center">
                    <i aria-hidden="true" class="mr-1 v-icon notranslate v-icon--left mdi mdi-help-circle-outline theme--light white--text"></i>
                    <span class="subtitle-1 font-weight-regular align-center">{{title}}</span>
                </v-card-title>
                
                <v-card-text class="pa-4 px-11"><span class="text--secondary">{{msg}}</span></v-card-text>

                <!-- <v-divider style="margin:0"></v-divider> -->

                <v-card-actions class="py-4 justify-center">
                    
                    <v-btn class="pl-3 pr-4 py-2 align-center" color="primary" @click="btnClicked">
                        <i aria-hidden="true" class="mr-1 v-icon notranslate v-icon--left mdi mdi-check theme--light"></i>
                        확인
                    </v-btn>
     
                </v-card-actions>
            </v-card>

        </v-dialog>
</v-overlay>

</template>
<script>
export default {
    props: {
        title: {
            type:String,
            default: "도움창"
        },
        msg: String,
    },
    data(){
        return {
            showMbox: false 
        }
    },
    methods: {
        btnClicked() {
            this.showMbox = false;
        } 
    } 
} 
</script>
<style scoped>

</style>
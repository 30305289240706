<template>
    <div class="audience-section ptb-100 d-business d-apply-manager">
        <div class="container">
            <div class="section-title">
                <h2>신청서내역(관리자용)</h2>
                <div class="bar"></div>
            </div>

            <div class="d-mt-30">
                <v-row>
                    <v-col class="col-lg-3 col-md-6 col-sm-12 d-date">
                        <v-text-field
                            v-model="FROM_DT"
                            label="조회시작일"
                            placeholder="년월일8자리"
                        ></v-text-field>
                    </v-col>
                    <v-col class="col-lg-3 col-md-6 col-sm-12 d-date">
                        <v-text-field
                            v-model="TO_DT"
                            label="조회종료일"
                            placeholder="년월일8자리"
                        ></v-text-field>
                    </v-col>
                    <v-col class="col-lg-3 col-md-6 col-sm-12">
                        <v-select
                            v-model="PRODUCT_CD"
                            :items="pitems"
                            item-text="name"
                            item-value="code"
                            label="제품구분"
                            persistent-hint
                            return-object
                            single-line
                        ></v-select>
                    </v-col>
                    <v-col class="col-lg-3 col-md-6 col-sm-12">
                        <v-select
                            v-model="MOD_CD"
                            :items="mitems"
                            item-text="name"
                            item-value="code"
                            label="구매방법"
                            persistent-hint
                            return-object
                            single-line
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="col-lg-4 col-md-12 col-sm-12">
                        <v-text-field
                            v-model="BIZ_CD"
                            label="사업자등록번호"
                            placeholder="하이픈(-) 없이 10자리"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        ></v-text-field>
                    </v-col>
                    <v-col class="col-lg-4 col-md-12 col-sm-12">
                        <v-text-field
                            v-model="CORP_NM"
                            label="상호명"
                            placeholder="상호명"
                        ></v-text-field>
                    </v-col>
                    <v-col class="col-lg-4 col-md-12 col-sm-12">
                        <v-btn @click="getApplySelect()" class="w-100 d-sch-btn">
                    <i class="mdi mdi-magnify"></i> <b>조회하기</b>
                </v-btn>
                    </v-col>
                </v-row>
            </div>
            <div class="row pb-100 d-mt-30 d-write-history">
                <div>
                    <p style="color:red; font-size: 20px;">※ 사용시 참고사항</p>
                    <p>- 조회시작일, 조회종료일은 년월일(YYYYmmDD) 값을 정확하게 입력해야 합니다.</p>  
                    <p>- 마감처리 버튼을 클릭하면 다운로드 버튼이 활성화 됩니다.</p>
                    <p>- 다운로드 버튼을 클릭하면 상호명-작성일자.zip 로 다운받을 수 있습니다.</p>
                    <p>- 삭제처리를 하면 해당 신청서는 사용자에게 보여지지 않습니다. (데이터가 삭제 되는것이 아니라 숨김처리 됨)</p>
                </div>                
                <div>
                    <table hover small caption-top responsive class="text-center" style="width:100%" >
                            <thead>
                                <tr>
                                    <td style="width:5%">NO</td>
                                    <td style="width:11%">작성일자</td>
                                    <td style="width:10%">제품구분</td>
                                    <td style="width:12%">구매방법</td>
                                    <td style="width:6%">마감<br>여부</td>
                                    <td style="width:6%">마감<br>처리</td>
                                    <td style="width:8%">작성내용<br>다운로드</td>
                                    <td style="width:6%" >파일<br>갯수</td>
                                    <td style="width:17%">상호명</td>
                                    <td style="width:13%">사업자번호</td>
                                    <td style="width:6%">삭제<br>처리</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in APPLYDATA" :key="i">
                                    <td>{{item.NUM}}</td>
                                    <td>{{setDateFormat('date', item.WRITE_DT)}}</td>
                                    <td>{{item.PRODUCT_NM}}</td>
                                    <td>{{item.MOD_NM}}</td>
                                    <td class="font-blue">{{item.CLOSE_YN == 'Y'? '완료':'미완료'}}</td>
                                    <td>
                                        <v-btn v-show="item.CLOSE_YN == 'N'" @click="setClose(item.DOC_NO, 'Y')" class="apply-btn">마감처리</v-btn>
                                        <v-btn v-show="item.CLOSE_YN == 'Y'" @click="setClose(item.DOC_NO, 'N')" class="apply-btn">마감취소</v-btn>
                                    </td>
                                    <td>
                                        <v-btn v-show="item.CLOSE_YN == 'N'" @click="goApplyMod(item.BIZ_CD, item.DOC_NO, item.PRODUCT_CD, item.MOD_CD, item.WRITE_DT, item.CLOSE_YN, item.STEP_NUM)" class="apply-btn">확인하기</v-btn>
                                        <S3DownloadBtn v-show="item.CLOSE_YN == 'Y'" :docNo='item.DOC_NO'/>
                                    </td>
                                    <td>{{item.FILE_CNT}}</td>
                                    <td>{{item.CORP_NM}}</td>
                                    <td>{{setDateFormat('bizcd', item.BIZ_CD)}}</td>
                                    <td>
                                        <v-btn v-show="item.USE_YN == 'Y'" @click="setDelete(item.DOC_NO, 'N')" class="apply-btn">삭제</v-btn>
                                        <v-btn v-show="item.USE_YN == 'N'" @click="setDelete(item.DOC_NO, 'Y')" class="apply-btn">삭제취소</v-btn>                                        
                                    </td>
                                </tr>
                            </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>

import S3DownloadBtn from '@/components/Controls/S3DownloadFile.vue'; //파일 다운로드 컨트롤
    export default {
        name: 'ApplyManager',
        components: {
            S3DownloadBtn
        },
        data() {
            return {
                FROM_DT: '',        //조회기간FROM [필수]
                TO_DT: '',          //조회기간TO [필수]

                pitems: [
                    {name: '전체', code: ''},
                    {name: 'LINK', code: 'PL'},
                    {name: 'ERP', code: 'PE'},
                    {name: '다래 LITE(라이트)', code: 'PI'},
                    {name: '세제환급사업', code: 'PT'},
                ],
                mitems: [
                    {name: '전체', code: ''},
                    {name: '일반구매', code: '01'},
                    {name: '스마트공장', code: '02'},
                    {name: '스마트공방', code: '03'},
                    {name: 'A/S사업', code: '04'},
                    {name: '세제환급사업', code: '05'},
                ],
                PRODUCT_CD: '',     //제품구분 PL:LINK, PE:ERP, PI:다래 LITE(라이트), PT:세제환급사업
                MOD_CD: '',         //구매방법 01.일반구매(1, 2단계), 02.스마트공장, 03.스마트공방, 04.A/S사업, 05.세제환급사업
                BIZ_CD: '',         //사업자등록번호
                CORP_NM : '',       //상호명
                APPLYDATA : [],     //DB조회 데이터
            };
        },
        mounted() {
            if (this.$user.ID_USER != 'dareadmin')
            {
                this.$msgBox.show('관리자만 접근 가능한 페이지 입니다.');
                this.movePage('/apply-join');
                return;
            }

            let today = new Date();
            // let getDate = today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2) + ('0' + today.getDate()).slice(-2);
            this.FROM_DT = today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2) + '01';
            this.TO_DT = today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2) + ('0' + today.getDate()).slice(-2);

            // this.BIZ_CD = this.$route.query.pbizcd;
            // this.getApplySelect();
        },
        methods: {
            //모바일 여부 모바일:true, PC:false
            isMobile() {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            },
            //작성된 신청서 조회
            async getApplySelect() {
                if((this.FROM_DT || '') === '' && (this.TO_DT || '') === '') {
                    this.$msgBox.show('조회시작일과 조회종료일을 입력하세요.');
                    return;
                }

                if((this.FROM_DT.length || '') != 8 || (this.TO_DT.length || '') != 8) {
                    this.$msgBox.show('년월일 8자리를 입력하세요.');
                    return;
                }

                await this.$http.get('/board/apply-manager-select', {
                                                                        FROM_DT: this.FROM_DT.replaceAll('-',''),
                                                                        TO_DT: this.TO_DT.replaceAll('-',''),
                                                                        PRODUCT_CD: this.PRODUCT_CD.code,
                                                                        MOD_CD: this.MOD_CD.code,
                                                                        BIZ_CD: this.BIZ_CD,
                                                                        CORP_NM: this.CORP_NM,
                                                                    }).then(res => {
                    if(res.errorcode=="") {
                        if(res.result[0] == null || res.result[0].length == 0 )
                        {
                            this.APPLYDATA = [];
                            this.$msgBox.show('조회조건을 만족하는 데이터가 없습니다.');
                        }
                        else
                        {
                            this.APPLYDATA = res.result[0];
                        }
                    }
                    else
                    {
                        alert(res.errorMsg);
                    }
                }).catch(err => {
                    this.loading = false;
                    alert(err)
                })
            },
            async setClose(docno, closeval) {
                let res = await this.$confirm((closeval === 'Y' ? '마감처리 하시겠습니까?' : '마감취소 하시겠습니까?'), {
					title: '확인'
				})

                if (res) {
					this.$http.post('/board/apply-close', {
                        DOC_NO: docno,
                        CLOSE_YN: closeval,
					}).then(res => {

						if (res.result) {
							this.$msgBox.show('처리되었습니다.');
                            //재조회
                            this.getApplySelect();
						}
					}).catch(err => {
						alert(err)
					})
				}
            },
            async setDelete(docno, useval) {
                let res = await this.$confirm((useval === 'N' ? '삭제처리 하시겠습니까?' : '삭제취소 하시겠습니까?'), {
					title: '확인'
				})

                if (res) {
					this.$http.post('/board/apply-delete', {
                        DOC_NO: docno,
                        USE_YN: useval,
					}).then(res => {

						if (res.result) {
							this.$msgBox.show('처리되었습니다.');
                            //재조회
                            this.getApplySelect();
						}
					}).catch(err => {
						alert(err)
					})
				}
            },
            //사업신청서 페이지 이동
            goApplyMod(bizcd, docno, productcd, modcd, writedt, closeyn, stepnum) {
                this.movePage('/applymod'+ modcd + '?pbizcd=' + bizcd
                            + '&pdocno='+ docno
                            + '&pproductcd=' + productcd
                            + '&pmodcd=' + modcd
                            + '&pwritedt=' + writedt
                            + '&pcloseyn=' + closeyn
                            + '&pstepnum=' + stepnum
                            + '&ppagetp=' + ''
                            );
            },
            setDateFormat(gubun, val) {
                let result = '';

                if(gubun === 'bizcd') {
                    //123-45-67890
                    result = val.substr(0,3) + '-' + val.substr(3, 2) + '-' + val.substr(5, 5);
                } else if(gubun === 'date') {
                    //2022-01-01
                    result = val.substr(0,4) + '-' + val.substr(4, 2) + '-' + val.substr(6, 2);
                }

                // console.log('setDateFormat >>> result : ', result);

                return result;
            },
        },
    }
</script>
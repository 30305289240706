<template>
    <div>
        <v-stepper-step
        :complete="bSaved"
        :step="stepnum"
        @click="clicked"
        >
            <!-- 3개년 부가가치세 표준 증명(첨부파일) -->
            {{TITLE}}
            <small v-if="bSaved==true">첨부완료</small>
            <small v-if="bSaved==false"><span>미완료</span></small>
        </v-stepper-step>

        <!-- <v-stepper-content step="7"> -->
        <v-stepper-content :step="stepnum">
            <v-card
                :loading="loading"
                class="mb-12"
                max-width="560"
            >
                <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/QCg5Mgkepog"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                ></iframe>
                <div class="form-group text-left">
                    <label for="input-name"><h5 class="text-left font-blue font-w-normal mt-4 d-apply-upload-title">1) 19~21년 부가가치세 과세 표준 증명 업로드하기<span class="font-size-15 font-w-normal"></span></h5></label>
                    <S3UploadFile stepNo="docnm5" :uploadBtn="false" :docInfo="docInfo" ref='docnm5' v-model="bSavedfile1" />
                </div>
                <div class="form-group text-left">
                    <label for="input-name"><h5 class="text-left font-blue font-w-normal mt-4 d-apply-upload-title">2) 21년 부가가치세 신고서 1 업로드하기<span class="font-size-15 font-w-normal"></span></h5></label>
                    <S3UploadFile stepNo="docnm6" :uploadBtn="false" :docInfo="docInfo" ref='docnm6' v-model="bSavedfile2"/>
                </div>
                <div class="form-group text-left">
                    <label for="input-name"><h5 class="text-left font-blue font-w-normal mt-4 d-apply-upload-title">3) 21년 부가가치세 신고서 2 업로드하기<span class="font-size-15 font-w-normal"></span></h5></label>
                    <S3UploadFile stepNo="docnm7" :uploadBtn="false" :docInfo="docInfo" ref='docnm7' v-model="bSavedfile3"/>
                </div>
                <!-- <div class="form-group" v-show="false">
                    <label for="input-name"><h5 class="font-w-normal">미사용(첨부파일)<span class="font-size-15 font-w-normal"></span></h5></label>
                    <S3UploadFile stepNo="docnm8" :uploadBtn="false" :docInfo="docInfo" ref='docnm8' v-model="bSavedfile4"/>
                </div>
                <br>
                <div class="form-group" v-show="false">
                    <label for="input-name"><h5 class="font-w-normal">미사용(첨부파일)<span class="font-size-15 font-w-normal"></span></h5></label>
                    <S3UploadFile stepNo="docnm9" :uploadBtn="false" :docInfo="docInfo" ref='docnm9' v-model="bSavedfile5"/>
                </div> -->
                <p class="text-left mt-6 font-red">* 설립 1년 미만 기업 업로드 제외</p>
            </v-card>

            <div class="text-center my-6">
                <v-btn
                    class="d-inline-block mr-1"
                    color="primary"
                    v-if="CLOSE_YN === 'N'"
                    @click="saveClicked"
                >
                    저장
                </v-btn>
                <v-btn
                    class="d-inline-block mr-1"
                    color="primary"
                    @click="nextClicked"
                >
                    계속
                </v-btn>
                <v-btn
                    class="d-inline-block bg-color-gray"
                    text
                    @click="cancelClicked"
                >
                    닫기
                </v-btn>
            </div>
        </v-stepper-content>
    </div>
</template>

<script>
    import S3UploadFile from '../../Controls/S3UploadFile.vue';
    export default {
        components: {
			S3UploadFile
		},
        name: 'DOC_NM5AND9',
        props: {
            psteptitle: {type:String, default:'3개년 부가가치세 표준 증명(첨부파일)'},
            psteptp: {type:String, default:'1'},
            pstepnum: {type:Number , default:1},
            pdocno: {type:Number, default:1},
            pmodcd: {type:String, default:''},
            pbizcd: {type:String, default:''},
            pwritedt: {type:String, default:''},
            pcloseyn: {type:String, default:'N'},
        },
        data() {
            return {
                TITLE: '',
                //bSaved: false, //작성완료여부
                bSavedfile1: false, //첨부파일1
                bSavedfile2: false, //첨부파일2
                bSavedfile3: false, //첨부파일3
                // bSavedfile4: false, //첨부파일4
                // bSavedfile5: false, //첨부파일5
                CLOSE_YN: 'N',  //신청서가 진행중이면 'N', 마감상태면 'Y' 로 관리함
                STEP_TP: '1',
                stepnum: 1,
                DOC_NO: 1,
                MOD_CD: '',
                BIZ_CD: '',
                WRITE_DT: '',
                docInfo : {},
            };
        },
        created() {
            this.TITLE = this.psteptitle;
            this.STEP_TP = this.psteptp;
            this.stepnum = this.pstepnum;
            this.DOC_NO = this.$route.query.pdocno;
            this.MOD_CD = this.$route.query.pmodcd;
            this.BIZ_CD = this.$route.query.pbizcd;
            this.WRITE_DT = this.$route.query.pwritedt;
            this.CLOSE_YN = this.$route.query.pcloseyn;
            this.docInfo = {
                DOC_NO: this.DOC_NO,
                BIZ_CD: this.BIZ_CD,
                MOD_CD: this.MOD_CD,
                STEP_NUM: this.stepnum,
                WRITE_DT: this.WRITE_DT,
            }
        },
        mounted() {
            // console.log('DOC_NM5AND9.vue >>>> mounted >>>> this.DOC_NO : ', this.DOC_NO
            // , ', this.BIZ_CD : ', this.BIZ_CD
            // , ', this.MOD_CD : ', this.MOD_CD
            // , ', this.STEP_NUM : ', this.stepnum
            // , ', this.WRITE_DT : ', this.WRITE_DT
            // );

            // console.log('this.bSavedfile1 : ', this.bSavedfile1, ', this.bSavedfile2 : ', this.bSavedfile2,', this.bSavedfile3 : ', this.bSavedfile3);

            // if(this.bSavedfile1 === true || this.bSavedfile2 === true || this.bSavedfile3 === true)
            //     this.bSaved = true;
            // else if(this.bSavedfile3 === true && this.bSavedfile4 === true)
            //     this.bSaved = true;
            // else if(this.bSavedfile5 === true)
            //     this.bSaved = true;
        },
        methods:{
            clicked() {
                this.$emit('click');
            },
            async saveClicked() {
                let res = await this.$confirm('작성하신 내용을 저장하시겠습니까?', {
					title: '확인'
				})

                if (res) {
                    //파일 업로드
                    this.bSavedfile1 = await this.$refs.docnm5.uploadFiles();
                    this.bSavedfile2 = await this.$refs.docnm6.uploadFiles();
                    this.bSavedfile3 = await this.$refs.docnm7.uploadFiles();
                    // this.bSavedfile4 = await this.$refs.docnm8.uploadFiles();
                    // this.bSavedfile5 = await this.$refs.docnm9.uploadFiles();

                    if(this.bSavedfile1 === true || this.bSavedfile2 === true || this.bSavedfile3 === true)
                        this.bSaved = true;
                    // else if(this.bSavedfile3 === true && this.bSavedfile4 === true)
                    //     this.bSaved = true;
                    // else if(this.bSavedfile5 === true )
                    //     this.bSaved = true;

                    if(this.bSaved === true)
                        this.$msgBox.show('저장되었습니다.');

                    this.$emit('saveclick');
				}
            },
            nextClicked() {
                this.$emit('nextclick');
            },
            cancelClicked() {
                this.$emit('cancelClick');
            },
        },
        computed: {
            bSaved() {
                return this.bSavedfile1 === true || this.bSavedfile2 === true || this.bSavedfile3 === true;
            }
        }
    }
</script>
<template>
    <div class="audience-section ptb-100 d-business">
        <div class="container">

            <div class="section-title" v-show="EXISTS_YN == 'Y'">
                <h2>작성내역</h2>
                <div class="bar"></div>
            </div>
            <div class="row pb-100 d-write-history" v-show="EXISTS_YN == 'Y'">
                <div>
                    <table hover small caption-top responsive class="text-center" style="width:100%" >
                        <thead>
                            <tr>
                                <td style="width:5%">NO</td>
                                <td style="width:11%">작성일자</td>
                                <td style="width:13%">제품구분</td>
                                <td style="width:12%">신청구분</td>
                                <td style="width:10%">완료여부</td>
                                <td style="width:12%">수정작성하기</td>
                                <td style="width:20%">상호명</td>
                                <td style="width:17%">사업자번호</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in APPLYDATA" :key="i">
                                <td>{{item.NUM}}</td>
                                <td>{{setDateFormat('date', item.WRITE_DT)}}</td>
                                <td>{{item.PRODUCT_NM}}</td>
                                <td>{{item.MOD_NM}}</td>
                                <td class="font-blue">{{item.CLOSE_YN == 'Y'? '완료':'미완료'}}</td>
                                <td>
                                    <!-- docno, productcd, modcd, writedt -->
                                    <v-btn v-show="item.CLOSE_YN == 'Y'" @click="goApplyMod(item.DOC_NO, item.PRODUCT_CD, item.MOD_CD, item.WRITE_DT, item.CLOSE_YN, item.STEP_NUM)" class="apply-btn">확인하기</v-btn>
                                    <v-btn v-show="item.CLOSE_YN != 'Y'" @click="goApplyMod(item.DOC_NO, item.PRODUCT_CD, item.MOD_CD, item.WRITE_DT, item.CLOSE_YN, item.STEP_NUM)" class="apply-btn">수정하기</v-btn>
                                </td>
                                <td>{{item.CORP_NM}}</td>
                                <td>{{setDateFormat('bizcd', item.BIZ_CD)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="section-title">
                <h2>구매방법</h2>
                <!-- <h1 class="font-blue">선택된 제품 : {{PRODUCT_NM}}</h1> -->
                <div class="bar"></div>
            </div>
            <!-- 구매방법 PC 일 때 -->
            <v-carousel show-arrows-on-hover="true" v-if="isMobile() == false" class="d-chouce-product-pc">
                <v-carousel-item>
                    <v-sheet
                        tile
                    >
                        <v-row
                        class="pb-100 d-select-a"
                        align="center"
                        justify="center"
                        >
                            <div class="col-lg-3 col-md-12 col-sm-12"><!-- A/S사업 -->
                                <div class="single-protfolio services-details-video d-as bg-color-white">
                                    <div class="image bg-color-white">
                                        <div class="d-construction2"><img src="../../assets/images/under-construction3.png" alt="공사중... 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                        <iframe
                                            width="342"
                                            height="178"
                                            src="https://www.youtube.com/embed/7JMpmiQLES0"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                            style="opacity: 0;"
                                            class="bg-color-white"
                                        ></iframe>
                                        <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                            <div
                                                class="video-btn popup-youtube"
                                                style="cursor: pointer"
                                            >
                                                <i class="bx bx-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content text-center">
                                        <h3>
                                            다래 A/S사업
                                        </h3>
                                        <p class="d-flex dare-btn read-more font-white justify-center" @click="goApplyPP('CM', '04')">신청하기</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12 col-sm-12"><!-- 스마트공방사업 -->
                                <div class="single-protfolio services-details-video d-p-two bg-color-white">
                                    <div class="image bg-color-white">
                                        <!-- <div class="d-construction2"><img src="../../assets/images/under-construction3.png" alt="공사중... 동영상은 제작중으로 곧 업데이트 예정입니다"></div> -->
                                        <iframe
                                            width="342"
                                            height="178"
                                            src="https://www.youtube.com/embed/IswPd2qdpSU"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                            style="opacity: 0;"
                                            class="bg-color-white buy-view"
                                        ></iframe>
                                        <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                            <div
                                                class="video-btn popup-youtube"
                                                style="cursor: pointer"
                                            >
                                                <i class="bx bx-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content text-center">
                                        <h3>
                                            스마트공방
                                        </h3>
                                        <p class="d-flex dare-btn read-more font-white justify-center" @click="goApplyPP('CM', '03')">신청하기</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12 col-sm-12" @click="toggleOn('PL')"><!-- LINK(링크) -->
                                <div class="single-protfolio services-details-video d-link bg-color-white">
                                    <div class="image bg-color-white">
                                        <!-- <div class="d-construction2"><img src="../../assets/images/under-construction3.png" alt="공사중... 동영상은 제작중으로 곧 업데이트 예정입니다"></div> -->
                                        <iframe
                                            width="342"
                                            height="178"
                                            src="https://www.youtube.com/embed/hqADvFHlbIk"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                            style="opacity: 0;"
                                            class="bg-color-white"
                                        ></iframe>
                                        <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                            <div
                                                class="video-btn popup-youtube"
                                                style="cursor: pointer"
                                            >
                                                <i class="bx bx-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content text-center">
                                        <h3>
                                            다래 LINK(링크)
                                        </h3>
                                        <p class="d-flex dare-btn read-more bg-color-white font-blue justify-center d-btn-buy-list">구매방법보기</p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="this.$user.ID_USER == 'dareadmin'" class="col-lg-3 col-md-12 col-sm-12"><!-- 세제 환급 사업 -->
                                <div class="single-protfolio services-details-video d-p-three bg-color-white">
                                    <div class="image bg-color-white">
                                        <div class="d-construction2"><img src="../../assets/images/under-construction3.png" alt="공사중... 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                        <iframe
                                            width="342"
                                            height="178"
                                            src=""
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                            style="opacity: 0;"
                                            class="bg-color-white buy-view"
                                        ></iframe>
                                        <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                            <div
                                                class="video-btn popup-youtube"
                                                style="cursor: pointer"
                                            >
                                                <i class="bx bx-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content text-center">
                                        <h3>
                                            세제환급사업
                                        </h3>
                                        <p class="d-flex dare-btn read-more font-white justify-center" @click="goApplyPP('PT', '05')">신청하기</p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-4 col-md-12 col-sm-12" @click="choiceProduct('PE')">
                                <div class="single-protfolio services-details-video d-erp bg-color-white">
                                    <div class="image bg-color-white">
                                        <iframe
                                            width="342"
                                            height="178"
                                            src="https://www.youtube.com/embed/6kfx-vTMWgo"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                            style="opacity: 0;"
                                            class="bg-color-white"
                                        ></iframe>
                                        <div class="details-video">
                                            <div
                                                class="video-btn popup-youtube"
                                                style="cursor: pointer"
                                            >
                                                <i class="bx bx-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content text-center">
                                        <h3>
                                            다래 ERP
                                        </h3>
                                    </div>
                                </div>
                            </div> -->
                        </v-row>
                    </v-sheet>
                </v-carousel-item>
            </v-carousel>

            <!-- *링크* 구매방법 PC 일 때 -->
            <v-carousel show-arrows-on-hover="true" v-if="isMobile() == false" v-show="isStatusOn" class="d-buy-item-pc">
                <div class="section-title d-pt-50">
                    <h2>링크 구매방법</h2>
                    <div class="bar"></div>
                </div>
                <v-carousel-item>
                    <v-sheet
                        tile
                    >
                        <v-row
                        class="d-select-b"
                        align="center"
                        justify="center"
                        >
                            <div class="col-lg-4 col-md-12">
                                <div class="single-blog services-details-video d-buy-general">
                                    <div class="image bg-color-white">
                                        <div class="d-construction2"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                        <iframe
                                                width="342"
                                                height="178"
                                                src="https://www.youtube.com/embed/7JMpmiQLES0"
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen
                                                allowTransparency="true"
                                                style="opacity: 0;"
                                        ></iframe>
                                        <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                            <div
                                                class="video-btn popup-youtube"
                                                style="cursor: pointer"
                                            >
                                                <i class="bx bx-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <h3>
                                            1. 일반구매
                                        </h3>
                                        <p class="d-flex dare-btn read-more font-white justify-center" @click="goApplyPP('PL', '01')">신청하기</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="single-blog services-details-video d-buy-factory">
                                    <div class="image bg-color-white">
                                        <div class="d-construction2"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                        <iframe
                                                width="342"
                                                height="178"
                                                src="https://www.youtube.com/embed/7JMpmiQLES0"
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen
                                                allowTransparency="true"
                                                style="opacity: 0;"
                                        ></iframe>
                                        <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                            <div
                                                class="video-btn popup-youtube"
                                                style="cursor: pointer"
                                            >
                                                <i class="bx bx-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <h3>
                                            2. A/S사업
                                        </h3>
                                        <p class="d-flex dare-btn read-more font-white justify-center" @click="goApplyPP('PL', '04')">신청하기</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="single-blog services-details-video d-buy-workshop">
                                    <div class="image bg-color-white">
                                        <!-- <div class="d-construction2"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div> -->
                                        <iframe
                                                width="342"
                                                height="178"
                                                src="https://www.youtube.com/embed/IswPd2qdpSU"
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen
                                                allowTransparency="true"
                                                class="buy-view"
                                                style="opacity: 0;"
                                        ></iframe>
                                        <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                            <div
                                                class="video-btn popup-youtube"
                                                style="cursor: pointer"
                                            >
                                                <i class="bx bx-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <h3>
                                            3. 스마트공방
                                        </h3>
                                        <p class="d-flex dare-btn read-more font-white justify-center" @click="goApplyPP('PL', '03')">신청하기</p>
                                    </div>
                                </div>
                            </div>
                        </v-row>
                    </v-sheet>
                </v-carousel-item>
            </v-carousel>

            <!-- 구매방법 모바일 일 때 -->
            <v-carousel show-arrows-on-hover="true" v-if="isMobile() == true" class="d-chouce-product" @change="isPlayVideo('ytVideo',false)">
                <v-carousel-item v-for="(item, i) in pItems" :key="i">
                    <v-sheet
                        tile
                    >
                        <v-row
                        class="pb-100 d-select-a"
                        align="center"
                        justify="center"
                        >
                            <div class="col-lg-12 col-md-12 col-sm-12" @click="toggleOn(item.productcd)">
                                <div :class=item.img>
                                    <div class="image bg-color-white">
                                        <!-- <div class="d-construction2"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div> -->
                                        <iframe
                                            id="ytVideo"
                                            width="342"
                                            height="178"
                                            :src=item.ytsrc
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                            style="opacity: 0;"
                                        ></iframe>
                                        <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                            <div
                                                class="video-btn popup-youtube"
                                                style="cursor: pointer"
                                            >
                                                <i class="bx bx-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content text-center" v-show="item.productcd != 'PL'">
                                        <h3>
                                            {{item.title}}
                                        </h3>
                                        <p class="d-flex dare-btn read-more font-white justify-center" @click="goApplyPP(item.productcd, item.modcd)" >신청하기</p>
                                    </div>
                                    <div class="content text-center" v-show="item.productcd == 'PL'">
                                        <h3>
                                            {{item.title}}
                                        </h3>
                                        <p class="d-flex dare-btn read-more bg-color-white font-blue justify-center d-btn-buy-list" >구매방법보기</p>
                                    </div>
                                </div>
                            </div>
                        </v-row>
                    </v-sheet>
                </v-carousel-item>
            </v-carousel>

            <!-- *링크* 구매방법 모바일 일 때 -->
            <v-carousel :show-arrows=false v-if="isMobile() == true" v-show="isStatusOn" class="d-buy-item" @change="isPlayVideo('ytVideo2', false)">
                <v-carousel-item v-for="(item, i) in buyItems" :key="i">
                    <div class="section-title pt-100">
                        <h2>링크 구매방법</h2>
                        <div class="bar"></div>
                    </div>
                    <v-sheet
                        tile
                    >
                        <v-row
                        class="d-select-b"
                        align="center"
                        justify="center"
                        >
                            <div class="col-lg-12 col-md-12">
                                <div :class=item.img>
                                    <div class="image bg-color-white">
                                        <!-- <div class="d-construction2"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div> -->
                                        <iframe
                                                id="ytVideo2"
                                                width="342"
                                                height="178"
                                                :src=item.ytsrc
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen
                                                allowTransparency="true"
                                                style="opacity: 0;"
                                        ></iframe>
                                        <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                            <div
                                                class="video-btn popup-youtube"
                                                style="cursor: pointer"
                                            >
                                                <i class="bx bx-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content text-center">
                                        <h3>
                                            {{item.title}}
                                        </h3>
                                        <p class="d-flex dare-btn read-more font-white justify-center" @click="goApplyPP(item.productcd, item.modcd)">신청하기</p>
                                    </div>
                                </div>
                            </div>
                        </v-row>
                    </v-sheet>
                </v-carousel-item>
            </v-carousel>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'ApplyList',
        components: {
        },
        props: {
            // pbizcd: {type:String, default:''},
            // ppwd: {type:String , default:''},
        },
        data() {

            return {
                isStatusOn: false, //구매방법 토글처리
                PRODUCT_CD: '',
                PRODUCT_NM: '미선택',
                MOD_CD: '',
                BIZ_CD: '',
                PWD: '',
                EXISTS_YN : 'N',
                PAGE_TP: '', //페이지를 구분을 나누어 시기마다 보여지는 스탭을 다르게 하기 위함.
                APPLYDATA : [],
                //제품선택(모바일 일 때 사용)
                pItems : [
                    {
                        title : '다래 A/S사업(동영상 준비중)',
                        img : 'single-protfolio services-details-video d-as bg-color-white',
                        ytsrc : '',
                        productcd : 'CM',
                        modcd : '04',
                    },
                    {
                        title : '스마트공방',
                        img : 'single-protfolio services-details-video d-p-two bg-color-white',
                        ytsrc : 'https://www.youtube.com/embed/IswPd2qdpSU',
                        productcd : 'CM',
                        modcd : '03',
                    },
                    {
                        title : '다래 LINK(링크)',
                        img : 'single-protfolio services-details-video d-link bg-color-white',
                        ytsrc : 'https://www.youtube.com/embed/hqADvFHlbIk',
                        productcd : 'PL',
                        modcd : '',
                    },
                    // {
                    //     title : '세제환급사업(동영상 준비중)',
                    //     img : 'single-protfolio services-details-video d-p-three bg-color-white',
                    //     ytsrc : '',
                    //     productcd : 'PT',
                    //     modcd : '05',
                    // },
                ],

                //구매방법(모바일 일 때 사용)
                buyItems : [
                    {
                        title : '일반구매(동영상 준비중)',
                        img : 'single-blog services-details-video d-buy-general',
                        ytsrc : '',
                        productcd : 'PL',
                        modcd : '01',
                    },
                    {
                        title : '다래 A/S사업(동영상 준비중)',
                        img : 'single-blog services-details-video d-buy-factory',
                        ytsrc : '',
                        productcd : 'PL',
                        modcd : '04',
                    },
                    {
                        title : '스마트공방',
                        img : 'single-blog services-details-video d-buy-workshop',
                        ytsrc : 'https://www.youtube.com/embed/IswPd2qdpSU',
                        productcd : 'PL',
                        modcd : '03',
                    },
                ],
            };
        },
        mounted() {
            this.BIZ_CD = this.$route.query.pbizcd;
            this.PWD = this.$applyuser.PWD;

            if(this.$route.query.ppagetp == '')
                this.PAGE_TP = '3';
            else
                this.PAGE_TP = this.$route.query.ppagetp;

            this.getApplySelect();
        },
        methods: {
            //작성된 신청서 조회
            async getApplySelect() {
                await this.$http.get('/board/apply-list-select', {BIZ_CD: this.BIZ_CD, PWD: this.PWD}).then(res => {
                    // console.log('ApplyList.vue >>>> res.result[0] : ', res.result[0]);
                    if(res.errorcode=="") {
                        if(res.result[0] == null || res.result[0].length == 0 )
                        {
                            this.EXISTS_YN = 'N';
                            // this.$msgBox.show('조회조건을 만족하는 데이터가 없습니다.');
                        }
                        else
                        {
                            this.EXISTS_YN = 'Y';
                            this.APPLYDATA = res.result[0];
                        }
                    }
                    else
                    {
                        alert(res.errorMsg);
                    }
                }).catch(err => {
                    this.loading = false;
                    alert(err)
                })
            },
            //모바일 여부 모바일:true, PC:false
            isMobile() {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            },
            //사업신청서 페이지 이동
            goApplyMod(docno, productcd, modcd, writedt, closeyn, stepnum) {

                if(modcd == '04')
                {
                    this.$msgBox.show('준비중입니다.');
                    return;
                }

                this.movePage('/applymod'+ modcd + '?pbizcd=' + this.BIZ_CD
                            + '&pdocno='+ docno
                            + '&pproductcd=' + productcd
                            + '&pmodcd=' + modcd
                            + '&pwritedt=' + writedt
                            + '&pcloseyn=' + closeyn
                            + '&pstepnum=' + stepnum
                            + '&ppagetp=' + this.PAGE_TP
                            );
            },
            //약관페이지 이동
            goApplyPP(productcd, modcd) {
                if(productcd === '') {
                    this.$msgBox.show('제품선택에서 제품을 선택하세요.');
                    return;
                }

                if(modcd == '04')
                {
                    this.$msgBox.show('준비중입니다.');
                    return;
                }

                //스마트공방 선택 시 추진절차2단계와 동일한 상태로 시작
                if(productcd === 'CM' && modcd === '03'
                    || productcd === 'PL' && modcd === '03')
                {
                    this.PAGE_TP = '3';
                }

                this.PRODUCT_CD = productcd;
                this.movePage('/apply-pp?pbizcd=' + this.BIZ_CD + '&pproductcd=' + this.PRODUCT_CD + '&pmodcd=' + modcd + '&ppagetp=' + this.PAGE_TP);
            },
            toggleOn(productcd) {
                if(productcd === 'PL')
                {
                    this.isStatusOn = !this.isStatusOn;
                }
            },
            // //제품선택 선택 시
            // choiceProduct(productcd) {
            //     this.PRODUCT_CD = productcd;

            //     if(productcd === 'PL') {
            //         this.isStatusOn = !this.isStatusOn;
            //     }

            //     // if(productcd === 'PL') {
            //     //     this.PRODUCT_NM = '다래 LINK(링크)';
            //     // }
            //     // else if (productcd === 'PE') {
            //     //     this.PRODUCT_NM = '다래 ERP';
            //     // }
            //     // else if (productcd === 'PI') {
            //     //     this.PRODUCT_NM = '다래 LITE(라이트)';
            //     // }
            // },
            //데이터 포멧설정
            setDateFormat(gubun, val) {
                let result = '';

                if(gubun === 'bizcd') {
                    //123-45-67890
                    result = val.substr(0,3) + '-' + val.substr(3, 2) + '-' + val.substr(5, 5);
                } else if(gubun === 'date') {
                    //2022-01-01
                    result = val.substr(0,4) + '-' + val.substr(4, 2) + '-' + val.substr(6, 2);
                }

                // console.log('setDateFormat >>> result : ', result);

                return result;
            },
            //유튜브 영상 재생, 일시정지
            isPlayVideo(id, pval) {
                if (pval === true) {
                    document
                        .querySelector('#'+id)
                        .contentWindow.postMessage(
                        '{"event":"command","func":"' + 'playVideo' + '","args":""}',
                        '*',
                        );
                }
                else if(pval=== false) {
                    document
                        .querySelector('#'+id)
                        .contentWindow.postMessage(
                        '{"event":"command","func":"' + 'pauseVideo' + '","args":""}',
                        '*',
                        );
                }
            },
        },
    }
</script>
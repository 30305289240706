<template>

		<v-container class="text-left">
			<v-card elevation="10" outlined width="100%" class="pa-2 mx-auto">
				<v-card-title class="px-4 py-2 ma-0 border-bottom">
					<span class="mr-2 primary--text">{{this.editerTitle}}</span>
				</v-card-title>
				<v-card-text class="px-4 py-0 d-notice">

					<v-row>
						<v-col class="pa-0 pt-4 ma-0">
							<v-select v-model="modCd" :items="items" readonly item-value="value" item-text="text"
								label="분류를 선택하세요."></v-select>
						</v-col>
						<v-col cols="6" class="pa-0 ma-0 mt-3 d-file-download"
							style="height: 53px !important;border-bottom: 1px solid #999;">
							<div v-if="this.fileExist === 'y'" class="pa-0 ma-0 dboard-sort dboard-search"
								style="height: 50px !important;">
								<v-btn class="mt-2 primary--text d-l-s--1" block @click="download"
									style="font-size: 14px;font-weight: 600;">
									파일다운로드
								</v-btn>
							</div>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="pa-0 ma-0 d-title-notice">
							<v-text-field label="제목" readonly :value="title" />
						</v-col>
					</v-row>

					<v-row>
						<v-col class="pa-0 ma-0">
							<v-text-field label="작성자" readonly dense :value="writer" />
						</v-col>
						<v-col cols="6" class="pa-0 ma-0">
							<v-text-field label="작성일자" readonly dense :value="regDttm" />
						</v-col>
						<v-col cols="2" class="pa-0 ma-0">
							<v-text-field label="조회수" readonly dense :value="view" />
						</v-col>
					</v-row>

					<div class="pa-0 ma-0 d-content-title-notice primary--text">
						내용
					</div>

					<Viewer v-show="this.userlogin !== 'y'" ref="viewer" />
					<Editor v-show="this.userlogin === 'y'" ref="edit" />
					<!-- <div class="pa-1 mb-2 border" style="height:250px;overflow-y:scroll;"><Viewer ref="viewer" /><br /></div> -->
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<!-- <v-btn v-if = "this.userlogin === 'y'" @click="movePage('/boardEdit?docNo=' + this.docNo + '&boardTp=' + this.boardTp)" color="primary"> -->
					<!-- <v-btn v-if = "this.userlogin === 'y'" @click="movePage('/boardEdit?docNo=' + this.docNo)" color="primary"></v-btn> -->
					<!-- <v-btn v-if = "this.userlogin === 'y'" @click="movePage('/boardEdit?docNo=' + docNo + '&boardTp=' + boardTp)" color="primary">
					수정
				</v-btn>	 -->
					<v-btn v-if="this.userlogin === 'y' && this.boardTp === '1'"
						@click="movePage('/boardEdit?docNo=' + docNo + '&boardTp=' + boardTp)" color="primary">
						수정
					</v-btn>
					<v-btn v-if="this.userlogin === 'y' && this.boardTp === '2'"
						@click="movePage('/manualEdit?docNo=' + docNo + '&boardTp=' + boardTp)" color="primary">
						수정
					</v-btn>
					<!-- manualEdit -->
					<v-btn v-if="this.userlogin === 'y'" @click="del" color="primary">
						삭제
					</v-btn>
					<v-btn v-if="this.boardTp === '1'" @click="movePage('/notice')" color="secondary">
						뒤로
					</v-btn>
					<v-btn v-if="this.boardTp === '2'" @click="movePage('/manual')" color="secondary">
						뒤로
					</v-btn>

					<!-- <Button
					@click="movePage('/boardEdit?docNo=' + docNo)"
					color="warning"
					rounded
					small
					iconName="mdi-pencil"
					btnName="수정"
				></Button>
				<Button
					@click="del"
					color="error"
					rounded
					small
					iconName="mdi-delete-forever"
					btnName="삭제"
				></Button>
				<Button
					@click="movePage('/notice')"
					color="grey darken-1"
					rounded
					small
					iconName="mdi-arrow-left"
					btnName="뒤로"
				></Button> -->
				</v-card-actions>
			</v-card>
		</v-container>

</template>

<script>
	import Viewer from '@/components/Controls/Viewer'
	import Editor from '@/components/Controls/Editor'
	import btnMixins from '@/utils/btnMixins'
	import globalinfo from '@/utils/globalinfo'

	export default {
		mixins: [btnMixins],
		components: {
			Viewer,
			Editor
		},
		data() {
			return {
				userlogin: '',
				modCd: '',
				docNo: 0,
				title: '',
				filepath: '',
				writer: '',
				regDttm: '',
				view: 0,
				comment: '',
				items: [{
						value: 'IP',
						text: '중요'
					},
					{
						value: 'NM',
						text: '일반'
					},
					{
						value: 'SY',
						text: '시스템'
					},
					{
						value: 'AC',
						text: '회계'
					},
					{
						value: 'HR',
						text: '인사'
					},
					{
						value: 'MM',
						text: '물류'
					},
					{
						value: 'GW',
						text: '그룹웨어'
					},
				],
				fileExist: "",
				boardTp: '',
				editerTitle: '',
			}
		},
		mounted() {
			this.doc_no = this.$route.query.docNo;
			this.boardTp = this.$route.query.boardTp;
			this.userlogin = globalinfo.UserInfo.IS_LOGIN;
			this.editerTitle = this.boardTp === "1" ? '공지사항' : '사용자매뉴얼';

			console.log('doc_no : ', this.doc_no);
			console.log('boardTp : ', this.boardTp);

			this.$http.get('/board/board-detail-select', {
				DOC_NO: this.doc_no
			}).then(res => {

				if (res.result && res.result.length > 0 && res.result[0].length > 0) {

					//공지내용
					this.modCd = res.result[0][0].MOD_CD
					this.docNo = res.result[0][0].DOC_NO
					this.title = res.result[0][0].TITLE
					this.filepath = res.result[0][0].FILE_NM
					this.$refs.viewer.setContent(res.result[0][0].CONTENT)
					this.$refs.edit.setContent(res.result[0][0].CONTENT)
					this.writer = res.result[0][0].WRITER
					this.regDttm = res.result[0][0].WRITE_DT
					this.view = res.result[0][0].SEARCH_CNT

					if (this.filepath != null && this.filepath != '') {
						this.fileExist = 'y';
						// console.log(this.fileExist)
					} else {
						this.fileExist = 'n';
						// console.log(this.fileExist)
					}
				} else {
					alert(res.errorMsg);
				}
			}).catch(err => {
				alert(err)
			})
		},

		methods: {
			async del() {
				// let res = await this.confirmDialog(
				// 	'Confirm Delete',
				// 	'Are you sure you want to delete it?',
				// )

				console.log(this.boardTp);

				let res = await this.$confirm('해당 게시물을 삭제하시겠습니까?', {
					title: 'Confirm Delete'
				})

				if (res) {
					this.$http.post('/board/board-delete', {
						DOC_NO: this.docNo
					}).then(res => {

						if (res.result) {
							// this.$msgBox.show('Delete complete.');
							this.$msgBox.show('삭제되었습니다.');

							if (this.boardTp == '1')
								this.movePage('/notice?boardTp=' + this.boardTp)
							else if (this.boardTp == '2')
								this.movePage('/manual?boardTp=' + this.boardTp)
						}
					}).catch(err => {
						alert(err)
					})
				}
			},

			download() {
				const url = this.filepath;
				window.location.href = url;
			},
			// replySearch(){
			// 	this.$http.get('/board/reply-list-select', {DOC_NO: this.docNo}).then(res => {

			// 		if(res.result) {
			// 			this.replies = res.result[0]
			// 		}
			// 	}).catch(err => {
			// 		alert(err)
			// 	})
			// },
			// replySave() {
			// 	if (this.comment !== null) {
			// 		this.$http.post('/board/reply-insert', {DOC_NO: this.docNo, CONTENT: this.comment, WRITER: this.$user.ID_USER}).then(res => {

			// 			if (res.result) {
			// 					this.replySearch();
			// 			}
			//         }).catch(err => {
			//             alert(err)
			//         })

			// 	}
			// },
			// async replyEdit(replyNo, comment) {
			// 	let res = await this.promptDialog('Edit Reply', 'Comment', comment)
			// 	if (res) {
			//         this.$http.post('/board/reply-update', {DOC_NO: this.docNo, REPLY_NO: replyNo, CONTENT: res}).then(res => {
			// 			if (res.result) {
			// 					this.replySearch();
			// 				}
			//         }).catch(err => {
			//             alert(err)
			//         })

			// 	}
			// },
			// async replyDel(replyNo) {
			// 	// let res = await this.confirmDialog(
			// 	// 	'Confirm Reply Delete',
			// 	// 	'Are you sure you want to delete it?',
			// 	// )

			// 	let res = await this.$confirm('해당 댓글을 삭제하시겠습니까?', {title: 'Confirm Reply Delete'})

			//     if (res) {
			// 		this.$http.post('/board/reply-delete', {DOC_NO: this.docNo, REPLY_NO: replyNo}).then(res => {

			// 			if (res.result) {
			// 					this.replySearch();
			// 				}
			//         }).catch(err => {
			//             alert(err)
			//         })
			// 	}
			// }
		}
	}
</script>

<style scoped>


</style>

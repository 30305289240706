<template>
    <div id="ProjectsOne-Process" class="services-section bg-background pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>추진절차</h2>
                <div class="bar"></div>
            </div>
            <div class="row">
                <!-- 1. 사업공고 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-01">
                        <div class="projects-image">
                            <!-- <h3 class="text-center">1. 사업공고</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-01.png" alt="01. 사업공고">
                            </div>
                            <p class="d-mt-20">스마트공장 구축 및 고도화 관련 사업 공고 개시<br>(스마트제조혁신추진단/스마트공장1번가)</p>
                            <div class="d-btn-group"><a href="#ProjectsOne-Support" target="_self" class="w-100 p-one-process-details dare-btn text-center">자세히보기</a></div>
                        </div>
                    </div>
                </div>
                <!-- 2. 사업 신청 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-02">
                        <div class="projects-image services-details-video">
                            <!-- <h3 class="text-center">2. 사업 신청</h3> -->
                            <img src="../../assets/images/projects/p-one-step-02.png" alt="02. 사업 신청">
                            <p class="d-mt-20">아래 서류 작성 및 발급 완료 후 스마트공장 사이트에서 사업 신청</p>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">자세히보기</a>
                                <a href="/apply-join" target="_self" class="w-100 dare-btn mt-1">사업신청 바로가기</a>
                            </div>
                            <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">2. 사업 신청</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-scroll-y">
                                    <div class="faq-accordion">
                                        <ul class="accordion" role="tablist">
                                            <b-card no-body class="accordion-item">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button class="accordion-title" block v-b-toggle.accordion-1 variant="info"><i class='bx bx-chevron-down'></i><h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4><span></span></b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <ul>
                                                            <li><i class="mdi mdi-menu-right"></i> 사업 신청서</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 국세 완납 증명서 (최근 3개월 이내 발행)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 지방세 완납 증명서 (최근 3개월 이내 발행)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 고용보험사업장 취득자 명부 증명원<br><span class="pl-4">(최근 2개년, 매년 12/31 기준 발행)</span></li>
                                                            <li><i class="mdi mdi-menu-right"></i> 중소기업 지원사업 통합관리시스템 정보활용<br><span class="pl-4">동의서 (스마트공장 사이트에서 양식 다운로드)</span></li>
                                                            <li><i class="mdi mdi-menu-right"></i> 회사 소개서</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 사업자등록증 (세금계산서 발행 주소 포함)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 사업자등록증명원 (최근 3개월 이내 발행)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 완제품 사진</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 공장 배치도 (평면도)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 공정도 및 공정별 사진</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 보유 인증서</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 기술서</li>
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>

                                            <b-card no-body class="accordion-item mt-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button class="accordion-title" block v-b-toggle.accordion-2 variant="info"><i class='bx bx-chevron-down'></i><h4 class="text-left"><i class='mdi mdi-file-outline'></i>  우대지원대상 증빙서류</h4><span></span></b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <ul>
                                                            <li><i class="mdi mdi-menu-right"></i> 스마트공장 교육</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 코로나19 대응 (* 가점 5점 부여)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 스마트 생태공장</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 산단대개조 산단</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 유턴기업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 조선기자재</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 광주형 일자리기업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 상생형지역일자리 참여기업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 뿌리기업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 위기 지역</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 일자리 안정자금 지원</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 노동시간 조기단축</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 청년 친화형 산단</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 노후산단 재생사업 선정 지구</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 민관협력 중소벤처 스마트혁신지구 조성사업 선정지구</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 사업재편 승인</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 로봇 도입</li>
                                                            <li><i class="mdi mdi-menu-right"></i> FEMS 솔루션 도입</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 에너지신산업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 정보보호 인증</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 스마트제조 표준</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 여성기업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 상생결제 우수기업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 군수품 제조기업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 사업전환계획 승인기업</li>
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </ul>
                                    </div>
                                </div>
                                <div class="d-btn-group mt-1">
                                    <a href="/apply-join" target="_self" class="w-100 p-one-process-details dare-btn mt-1">사업신청 바로가기</a>
                                    <!-- <button target="_self" class="close w-100 p-one-process-details dare-btn mt-1">닫기</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 3. 요건 검토 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-03">
                        <div class="projects-image">
                            <!-- <h3 class="text-center">3. 요건 검토</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-03.png" alt="03. 요건 검토">
                            </div>
                            <p class="d-mt-20">사업 신청에 대한 요건 검토 실시<br>(반려 시 내용 또는 서류 수정)</p>
                        </div>
                    </div>
                </div>
                <!-- 4. 사업계획서 작성 및 제출 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-04">
                        <div class="projects-image">
                            <!-- <h3 class="text-center">4. 사업계획서 작성 및 제출</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-04.png" alt="04. 사업계획서 작성 및 제출">
                            </div>
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="flaticon-tick"></i></b>
                                    <p>코디네이터 지원 사업  코디네이터가 도입기업에게 방문하여 함께 사업계획서 및 사업비 산출내역서 작성 후 제출</p>
                                </li>
                                <li>
                                    <b><i class="flaticon-tick"></i></b>
                                    <p>코디네이터 미지원 사업 다래에서 사업계획서 및 사업비 산출내역서 작성 후 제출</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- 5. 기술 발표 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-05">
                        <div class="projects-image">
                            <!-- <h3 class="text-center">5. 기술 발표</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-05.png" alt="05. 기술 발표">
                            </div>
                            <p class="d-mt-20">도입기업에 방문하여 사업에 대한 발표 후 구축 필요성, 지원 적정성 등에 대해 평가</p>
                        </div>
                    </div>
                </div>
                <!-- 6. 현장 평가 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-06">
                        <div class="projects-image">
                            <!-- <h3 class="text-center">6. 현장 평가</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-06.png" alt="06. 현장 평가">
                            </div>
                            <p class="d-mt-20">평가위원 1~2인, 테크노파크 연구원 1인으로 구성되어 도입기업 현장 방문 및 관계자 면담 등을 통해 평가</p>
                        </div>
                    </div>
                </div>
                <!-- 7. 원가 계산 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Seven">
                        <div class="projects-image services-details-video">
                            <!-- <h3 class="text-center">7. 원가 계산</h3> -->
                            <img src="../../assets/images/projects/p-one-step-07.png" alt="07. 원가 계산">
                            <p class="d-mt-20">원가계산기관에서 사업비 적절성에 대해 검토</p>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">자세히보기</a>
                            </div>
                            <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">7. 원가 계산</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-3"><i class="mdi mdi-menu-right"></i> 하드웨어 견적서<br><span class="pl-4">(도입기업에서 지정한 하드웨어 있을 경우)</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 8. 선정 심의 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-08">
                        <div class="projects-image">
                            <!-- <h3 class="text-center">8. 선정 심의</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-08.png" alt="08. 선정 심의">
                            </div>
                            <p class="d-mt-20">선정심의위원회에서 도입기업 선정 의결 후 발표</p>
                        </div>
                    </div>
                </div>
                <!-- 9. 사업 신청 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-02">
                        <div class="projects-image services-details-video">
                            <!-- <h3 class="text-center">9. 사업 신청</h3> -->
                            <img src="../../assets/images/projects/p-one-step-09.png" alt="09. 사업 신청">
                            <p class="d-mt-20">아래 서류 작성 및 발급 완료 후 스마트공장 사이트에서 사업 신청</p>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">자세히보기</a>
                                <a href="/apply-join" target="_self" class="w-100 dare-btn mt-1">사업신청 바로가기</a>
                            </div>
                            <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">9. 본사업 신청</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-scroll-y">
                                    <div class="faq-accordion">
                                        <ul class="accordion" role="tablist">
                                            <b-card no-body class="accordion-item">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button class="accordion-title" block v-b-toggle.accordion-1 variant="info"><i class='bx bx-chevron-down'></i><h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4><span></span></b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <ul class="d-mt-20">
                                                            <li><i class="mdi mdi-menu-right"></i> 재무제표 (최근 3개년)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 사업 신청서 (이전 작성 서류)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 국세 완납 증명서 (최근 3개월 이내 발행)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 지방세 완납 증명서 (최근 3개월 이내 발행)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 고용보험사업장 취득자 명부 증명원<br><span class="pl-4">(최근 2개년, 매년 12/31 기준 발행)</span></li>
                                                            <li><i class="mdi mdi-menu-right"></i> 중소기업 지원사업 통합관리시스템 정보활용<br><span class="pl-4">동의서(스마트공장 사이트에서 양식 다운로드)</span></li>
                                                            <li><i class="mdi mdi-menu-right"></i> 사업자등록증명원 (최근 3개월 이내 발행)</li>
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>

                                            <b-card no-body class="accordion-item mt-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button class="accordion-title" block v-b-toggle.accordion-2 variant="info"><i class='bx bx-chevron-down'></i><h4 class="text-left"><i class='mdi mdi-file-outline'></i>  우대지원대상 증빙서류</h4><span></span></b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <ul class="d-mt-20">
                                                            <li><i class="mdi mdi-menu-right"></i> 조선기자재</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 유턴기업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 광주형 일자리기업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 뿌리기업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 위기 지역</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 일자리 안정자금 지원</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 노동시간 조기단축</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 청년 친화형 산단</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 노후산단 재생사업 선정 지구</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 사업재편 승인</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 로봇 도입</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 에너지신산업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 정보보호 인증</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 스마트제조 표준</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 군수품 제조기업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 사업전환계획 승인기업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 스마트공장 교육</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 코로나19 대응 (* 가점 5점 부여)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 스마트 생태공장</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 산단대개조 산단</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 상생형지역일자리 참여기업</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 민관협력 중소벤처 스마트혁신지구 조성사업 선정지구</li>
                                                            <li><i class="mdi mdi-menu-right"></i> FEMS 솔루션 도입</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 상생결제 우수기업</li>
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </ul>
                                    </div>
                                </div>
                                <div class="d-btn-group mt-1">
                                    <a href="/apply-join" target="_self" class="w-100 p-one-process-details dare-btn mt-1">사업신청 바로가기</a>
                                    <!-- <button target="_self" class="close w-100 p-one-process-details dare-btn mt-1">닫기</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 10. 요건 검토 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-10 d-min-h-260">
                        <div class="projects-image d-hs-260">
                            <!-- <h3 class="text-center">10. 요건 검토</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-10.png" alt="10. 요건 검토">
                            </div>
                            <p class="d-mt-20">사업 신청에 대한 요건 검토 실시<br>(반려 시 내용 또는 서류 수정)</p>
                        </div>
                    </div>
                </div>
                <!-- 11. 원가 계산 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-11 d-min-h-260">
                        <div class="projects-image d-hs-260">
                            <!-- <h3 class="text-center">11. 원가 계산</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-11.png" alt="11. 원가 계산">
                            </div>
                            <p class="d-mt-20">이전 실시했던 원가 계산 내역을 스마트공장 시스템에서 실시</p>
                        </div>
                    </div>
                </div>
                <!-- 12. 협약 진행 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-12 d-min-h-260">
                        <div class="projects-image d-hs-260">
                            <!-- <h3 class="text-center">12. 협약 진행</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-12.png" alt="12. 협약 진행">
                            </div>
                            <p class="d-mt-20">스마트공장 사이트에서 공인인증서를 통해 전자 협약 진행</p>
                        </div>
                    </div>
                </div>
                <!-- 13. RCMS 진행 및 서류 제출 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-02 One-Step-13">
                        <div class="projects-image services-details-video">
                            <!-- <h3 class="text-center">13. RCMS 진행 및 서류 제출</h3> -->
                            <img src="../../assets/images/projects/p-one-step-13.png" alt="13. RCMS 진행 및 서류 제출">
                            <p class="d-mt-20">사업비 관리를 위한 RCMS 진행<br>(지역TP별 요청서류는 다를 수 있음)</p>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">자세히보기</a>
                                <a href="/apply-join" target="_self" class="w-100 dare-btn mt-1">사업신청 바로가기</a>
                            </div>
                            <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">13. RCMS 진행 및 서류 제출</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-scroll-y">
                                    <div class="faq-accordion">
                                        <ul class="accordion" role="tablist">
                                            <b-card no-body class="accordion-item">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button class="accordion-title" block v-b-toggle.accordion-1 variant="info"><i class='bx bx-chevron-down'></i><h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4><span></span></b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <ul>
                                                            <li><i class="mdi mdi-menu-right"></i> RCMS용 도입기업 명의 통장 사본</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 범용공인인증서 발급</li>
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>

                                            <b-card no-body class="accordion-item mt-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button class="accordion-title" block v-b-toggle.accordion-2 variant="info"><i class='bx bx-chevron-down'></i><h4 class="text-left"><i class='mdi mdi-file-outline'></i>  경북TP</h4><span></span></b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <ul>
                                                            <li><i class="mdi mdi-menu-right"></i> 협약 서식</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 협약 기관 서식</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 세목별 한도</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 참여 연구원</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 사업비 내역</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 도입기업 사업비(현금) 분할납부 신청 양식</li>
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>

                                            <b-card no-body class="accordion-item mt-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button class="accordion-title" block v-b-toggle.accordion-3 variant="info"><i class='bx bx-chevron-down'></i><h4 class="text-left"><i class='mdi mdi-file-outline'></i>  강원TP</h4><span></span></b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <ul>
                                                            <li><i class="mdi mdi-menu-right"></i> RCMS 참여인력 등록 정보 요청</li>
                                                            <li><i class="mdi mdi-menu-right"></i> RCMS 통장정보 등록 정보 요청</li>
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </ul>
                                    </div>
                                </div>
                                <div class="d-btn-group mt-1">
                                    <a href="/apply-join" target="_self" class="w-100 p-one-process-details dare-btn mt-1">사업신청 바로가기</a>
                                    <!-- <button target="_self" class="close w-100 p-one-process-details dare-btn mt-1">닫기</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 14. 도입기업 부담금 입금 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Seven One-Step-14">
                        <div class="projects-image services-details-video">
                            <!-- <h3 class="text-center">14. 도입기업 부담금 입금</h3> -->
                            <img src="../../assets/images/projects/p-one-step-14.png" alt="14. 도입기업 부담금 입금">
                            <p class="d-mt-20">원가계산기관에서 사업비 적절성에 대해 검토</p>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">자세히보기</a>
                            </div>
                            <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">14. 도입기업 부담금 입금</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-3"><i class="mdi mdi-menu-right"></i> 이체증명서<span class="pl-1">(이체확인서)</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 15. 착수계 제출 자료 승인 신청 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 One-Step-15">
                        <div class="projects-image services-details-video">
                            <!-- <h3 class="text-center">15. 착수계 제출 자료 승인 신청</h3> -->
                            <img src="../../assets/images/projects/p-one-step-15.png" alt="15. 착수계 제출 자료 승인 신청">
                            <p class="d-mt-20">원가계산기관에서 사업비 적절성에 대해 검토</p>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">자세히보기</a>
                            </div>
                            <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">15. 착수계 제출 자료 승인 신청</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-2">
                                    <i class="mdi mdi-menu-right"></i> 착수계<span class="pl-1">(도입기업 직인 필요)</span><br>
                                    <i class="mdi mdi-menu-right"></i> 사업비 입금계좌 신고 및 불법 부당행위 방지 서약서<br><span class="pl-4">(도입기업 직인 필요)</span><br>
                                    <i class="mdi mdi-menu-right"></i> 현물출자 확약서<br><span class="pl-4">(도입기업 인력 인건비 해당시에만 작성)</span><br>
                                    <i class="mdi mdi-menu-right"></i> 이행(지급)보증보험증권<br><span class="pl-4">(도입기업에서 기정원을 대상으로 발행)</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 16. 사업비 출금 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Seven">
                        <div class="projects-image services-details-video">
                            <!-- <h3 class="text-center">16. 사업비 출금</h3> -->
                            <img src="../../assets/images/projects/p-one-step-16.png" alt="16. 사업비 출금">
                            <p class="d-mt-20">RCMS 가상계좌에서 증빙 서류를 제출하여 여러 회차에 나눠 사업비 출금</p>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">자세히보기</a>
                            </div>
                            <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">16. 사업비 출금</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-3">
                                    <i class="mdi mdi-menu-right"></i> 세금계산서<br>
                                    <i class="mdi mdi-menu-right"></i> 거래명세서<span class="pl-1">(도입기업 직인 필요)</span><br>
                                    <i class="mdi mdi-menu-right"></i> 납품검수확인서<span class="pl-1">(도입기업 직인 필요)</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 17. 중간보고서 제출 자료 승인 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Seven One-Step-17">
                        <div class="projects-image services-details-video">
                            <!-- <h3 class="text-center">17. 중간보고서 제출 자료 승인</h3> -->
                            <img src="../../assets/images/projects/p-one-step-17.png" alt="17. 중간보고서 제출 자료 승인">
                            <p class="d-mt-20">중간보고서 및 산출물 등 작성 후 제출한 서류를 도입기업에서 공인인증서로 승인<br>도입기업 부담금 분할 납부 시 현 시점까지 입금 필요</p>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">자세히보기</a>
                            </div>
                            <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">17. 중간보고서 제출 자료 승인</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-3">
                                    <i class="mdi mdi-menu-right"></i> 중간보고서<span class="pl-1">(도입기업 직인 필요)</span><br>
                                    <i class="mdi mdi-menu-right"></i> 요구사항정의서<span class="pl-1">(도입기업 직인 필요)</span><br>
                                    <i class="mdi mdi-menu-right"></i> 사업비사용실적보고서<span class="pl-1">(도입기업 직인 필요)</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 18. 중간 점검 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-18">
                        <div class="projects-image">
                            <!-- <h3 class="text-center">18. 중간 점검</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-18.png" alt="18. 중간 점검">
                            </div>
                            <p class="d-mt-20">위원이 도입기업에 방문하여 점검 실시</p>
                        </div>
                    </div>
                </div>
                <!-- 19. 수시 점검 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-19">
                        <div class="projects-image d-hs-300">
                            <!-- <h3 class="text-center">19. 수시 점검</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-19.png" alt="19. 수시 점검">
                            </div>
                            <p class="d-mt-20">코디네이터 또는 위원이 점검이 필요하다고 생각되는 경우 수시 점검 실시</p>
                        </div>
                    </div>
                </div>
                <!-- 20. 클라우드 계약, 세금계산서 발행 및 입금 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 One-Step-20">
                        <div class="projects-image d-hs-300 services-details-video">
                            <!-- <h3 class="One-Step-20 text-center">20. 클라우드 계약, 세금계산서 발행 및 입금</h3> -->
                            <img src="../../assets/images/projects/p-one-step-20.png" alt="20. 클라우드 계약, 세금계산서 발행 및 입금">
                            <p class="d-mt-20">클라우드를 도입한 경우 3년간 다래와 클라우드 계약 실시</p>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">자세히보기</a>
                            </div>
                            <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">20. 클라우드 계약, 세금계산서 발행 및 입금</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-3">
                                    <i class="mdi mdi-menu-right"></i> 클라우드 계약서<span class="pl-1">(도입기업 직인 필요)</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 21. 임치 계약 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 One-Step-20">
                        <div class="projects-image d-hs-300 services-details-video">
                            <!-- <h3 class="text-center">21. 임치 계약</h3> -->
                            <img src="../../assets/images/projects/p-one-step-21.png" alt="21. 임치 계약">
                            <p class="d-mt-20">개발 메뉴 관련하여 임치 계약 실시</p>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">자세히보기</a>
                            </div>
                            <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">21. 임치 계약</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <h4 class="text-left"><i class='mdi mdi-account-details-outline'></i> 도입기업 실행 업무</h4>
                                <p class="pl-3">
                                    <i class="mdi mdi-menu-right"></i> 임치 사이트 회원가입 및 범용 공인인증서 등록<br>
                                    <i class="mdi mdi-menu-right"></i> 범용 공인인증서로 승인
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 22. 완료보고서 제출 자료 승인 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 One-Step-22">
                        <div class="projects-image d-hs-300 services-details-video">
                            <!-- <h3 class="text-center">22. 완료보고서 제출 자료 승인</h3> -->
                            <img src="../../assets/images/projects/p-one-step-22.png" alt="22. 완료보고서 제출 자료 승인">
                            <p class="d-mt-20">완료보고서 및 산출물 등 작성 후 제출한 서류를 도입기업에서 공인인증서로 승인</p>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">자세히보기</a>
                            </div>
                            <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">22. 완료보고서 제출 자료 승인</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-3">
                                    <i class="mdi mdi-menu-right"></i> 완료보고서<span class="pl-1">(도입기업 직인 필요)</span><br>
                                    <i class="mdi mdi-menu-right"></i> 사업비실적보고서<span class="pl-1">(도입기업 직인 필요)</span><br>
                                    <i class="mdi mdi-menu-right"></i> 성과측정결과서<br><span class="pl-4">(성과 측정에 대한 도입기업 자료 필요)</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 23. 최종 감리 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-23 d-min-h-300">
                        <div class="projects-image d-hs-300">
                            <!-- <h3 class="text-center">23. 최종 감리</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-23.png" alt="23. 최종 감리">
                            </div>
                            <p class="d-mt-20">완료보고서 및 산출물, 관련 서류를 바탕으로 감리 위원이 도입기업에 방문하여 감리 진행</p>
                        </div>
                    </div>
                </div>
                <!-- 24. 완료 점검 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-23 d-min-h-300">
                        <div class="projects-image d-hs-300">
                            <!-- <h3 class="text-center">24. 완료 점검</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-24.png" alt="24. 완료 점검">
                            </div>
                            <p class="d-mt-20">위원이 도입기업에 방문하여 점검 실시</p>
                        </div>
                    </div>
                </div>
                <!-- 25. 최종완료보고서 제출 자료 승인 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 One-Step-20 d-min-h-300">
                        <div class="projects-image d-hs-300 services-details-video">
                            <!-- <h3 class="text-center">25. 최종완료보고서 제출 자료 승인</h3> -->
                            <img src="../../assets/images/projects/p-one-step-25.png" alt="25. 최종완료보고서 제출 자료 승인">
                            <p class="d-mt-20">개발 메뉴 관련하여 임치 계약 실시</p>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">자세히보기</a>
                            </div>
                            <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">25. 최종완료보고서 제출 자료 승인</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <h4 class="text-left"><i class='mdi mdi-account-details-outline'></i> 도입기업 실행 업무</h4>
                                <p class="pl-3">
                                    <i class="mdi mdi-menu-right"></i> 임치 사이트 회원가입 및 범용 공인인증서 등록<br>
                                    <i class="mdi mdi-menu-right"></i> 범용 공인인증서로 승인
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 26. 최종 평가 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-23 d-min-h-300">
                        <div class="projects-image d-hs-300">
                            <!-- <h3 class="text-center">26. 최종 평가</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-26.png" alt="26. 최종 평가">
                            </div>
                            <p class="d-mt-20">집중 AS 기간이 종료된 후 위원과 테크노파크 연구원이 도입기업에 방문하여 사업 전체 평가</p>
                        </div>
                    </div>
                </div>
                <!-- 27. 지방비 신청 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-23 d-min-h-300">
                        <div class="projects-image d-hs-300">
                            <!-- <h3 class="text-center">27. 지방비 신청</h3> -->
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-one-step-27.png" alt="27. 지방비 신청">
                            </div>
                            <p class="d-mt-20">최종완료보고서 최종 승인 후 지역별 지방비 신청</p>
                        </div>
                    </div>
                </div>
                <!-- 28. 사업 변경 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 One-Step-22 d-min-h-300">
                        <div class="projects-image d-hs-300 services-details-video">
                            <!-- <h3 class="text-center">28. 사업 변경</h3> -->
                            <img src="../../assets/images/projects/p-one-step-28.png" alt="28. 사업 변경">
                            <p class="d-mt-20">H/W 구입, 사업 기간 등 사업과 관련된 변경 이슈가 있을 때 관련 자료를 제출하여 사업 변경</p>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">자세히보기</a>
                            </div>
                            <div class="details-video"><!-- 동영상 재생 아이콘(페이크) -->
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">28. 사업 변경</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction3.png" alt="공사중 동영상은 제작중으로 곧 업데이트 예정입니다"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-3">
                                    <i class="mdi mdi-menu-right"></i> 변경 관련 공문<span class="pl-1">(도입기업 직인 필요)</span><br>
                                    <i class="mdi mdi-menu-right"></i> 협약 변경 신청서<span class="pl-1">(도입기업 직인 필요)</span><br>
                                    <i class="mdi mdi-menu-right"></i> 회의록<span class="pl-1">(도입기업 직인 필요)</span><br>
                                    <i class="mdi mdi-menu-right"></i> 변경사업계획서<br>
                                    <i class="mdi mdi-menu-right"></i> 견적서<span class="pl-1">(하드웨어 변경 시)</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProjectsProcess',
        data() {
            return {
                upHere1 : false
            }
        }
    }
</script>
<template>
    <div class="projects-section ptb-100 d-video">
        <div class="container">
            <div class="section-title">
                <!-- <h2>교육 동영상</h2> -->
                <p class="text-left d-mb-10"><i class="flaticon-check font-blue font-w-bold d-mr-10"></i>다래아이엔씨는 고객님들께 각 모듈별 기초 교육 영상을 제공합니다.</p>
                <p class="text-left d-mb-10"><i class="flaticon-check font-blue font-w-bold d-mr-10"></i>인터넷만 연결된다면 어디서든 교육 영상을 재생할 수 있습니다.</p>
                <p class="text-left">
                    <i class="flaticon-check font-blue font-w-bold d-mr-10"></i>영상 시청 중 궁금한 사항이나 어려움이 있으실 경우 대표번호로 전화주시길 바랍니다. 각 모듈 담당자들이 친절하게 안내해드립니다.
                    <ul class="design-list d-mt-10">
                        <li>대표번호 : 02-6925-0995</li>
                    </ul>
                </p>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-protfolio">
                        <div class="image">
                            <img src="../../assets/images/video-1.png" alt="image">
                        </div>

                        <div class="content text-center">
                            <h4 class="d-mb-10">생산모듈 교육영상</h4>
                            <div>
                                <a href="https://www.youtube.com/watch?v=ABUWQAwSd8M&list=PLIRwkyYBuoi1SHmgUfLy63gmZdi4l1kwq" target="_blank" class="dare-btn d-mt-10"><b>연결하기</b></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-protfolio">
                        <div class="image">
                            <img src="../../assets/images/video-2.png" alt="image">
                        </div>

                        <div class="content text-center">
                            <h4 class="d-mb-10">영업/구매 교육영상</h4>
                            <div>
                                <a href="https://www.youtube.com/watch?v=tu-Y9ux9M5w&list=PLIRwkyYBuoi2ygxPEELpSMDq1i7c4x3BS" target="_blank" class="dare-btn d-mt-10"><b>연결하기</b></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-protfolio">
                        <div class="image">
                            <img src="../../assets/images/video-3.png" alt="image">
                        </div>

                        <div class="content text-center">
                            <h4 class="d-mb-10">인사모듈 교육영상</h4>
                            <div>
                                <a href="https://www.youtube.com/watch?v=7JMpmiQLES0&list=PLIRwkyYBuoi2dUzroSONR8HjhcJYKc9wU" target="_blank" class="dare-btn d-mt-10"><b>연결하기</b></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-protfolio">
                        <div class="image">
                            <img src="../../assets/images/video-4.png" alt="image">
                        </div>

                        <div class="content text-center">
                            <h4 class="d-mb-10">회계모듈 교육영상</h4>
                            <div>
                                <a href="https://www.youtube.com/watch?v=6kfx-vTMWgo&list=PLIRwkyYBuoi1BL9gZ7XTraiMFyBJL0Lvm" target="_blank" class="dare-btn d-mt-10"><b>연결하기</b></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-protfolio">
                        <div class="image">
                            <img src="../../assets/images/video-5.png" alt="image">
                        </div>

                        <div class="content text-center">
                            <h4 class="d-mb-10 d-w-b-keep-all d-l-s--1 font-size-20">스마트공방 기초정보등록 교육영상</h4>
                            <div>
                                <a href="https://www.youtube.com/watch?v=JIpnfhCGJnA&list=PLIRwkyYBuoi1us6xjIFy0M71nrC-bDsWu" target="_blank" class="dare-btn d-mt-10"><b>연결하기</b></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-protfolio">
                        <div class="image">
                            <img src="../../assets/images/video-6.png" alt="image">
                        </div>

                        <div class="content text-center">
                            <h4 class="d-mb-10 d-w-b-keep-all font-size-20">스마트공방 구매관리 교육영상</h4>
                            <div>
                                <a href="https://www.youtube.com/watch?v=Ia0K9OcKDzw&list=PLIRwkyYBuoi1F5_1yUkExTVs5BFSzWrI6" target="_blank" class="dare-btn d-mt-10"><b>연결하기</b></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-protfolio">
                        <div class="image">
                            <img src="../../assets/images/video-7.png" alt="image">
                        </div>

                        <div class="content text-center">
                            <h4 class="d-mb-10 d-w-b-keep-all font-size-20">스마트공방 생산관리 교육영상</h4>
                            <div>
                                <a href="https://www.youtube.com/watch?v=rgzYHfKdN1c&list=PLIRwkyYBuoi2fs-GevsEKapiYthINY7xO" target="_blank" class="dare-btn d-mt-10"><b>연결하기</b></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-protfolio">
                        <div class="image">
                            <img src="../../assets/images/video-8.png" alt="image">
                        </div>

                        <div class="content text-center">
                            <h4 class="d-mb-10 d-w-b-keep-all font-size-20">스마트공방 영업관리 교육영상</h4>
                            <div>
                                <a href="https://www.youtube.com/watch?v=GWLopwwKIhE&list=PLIRwkyYBuoi0fBecyWOly5BDD3jsBHK7a" target="_blank" class="dare-btn d-mt-10"><b>연결하기</b></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-protfolio">
                        <div class="image">
                            <img src="../../assets/images/video-9.png" alt="image">
                        </div>

                        <div class="content text-center">
                            <h4 class="d-mb-10 d-w-b-keep-all font-size-20">스마트공방 재고관리 교육영상</h4>
                            <div>
                                <a href="https://www.youtube.com/watch?v=FBLp_rslAYk&list=PLIRwkyYBuoi1-GJZUvn7zbUIFSdJogGdc" target="_blank" class="dare-btn d-mt-10"><b>연결하기</b></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Video'
    }
</script>
<template>
    <div class="ptb-100 d-product">
        <div class="container">
            <div class="section-title">
                <h2>제품소개</h2>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-protfolio">
                        <div class="image bg-color-white">
                            <router-link to="/services-one">
                                <img src="../../assets/images/projects/product-packaging-erp.png" alt="image">
                            </router-link>
                        </div>

                        <div class="content text-center">
                            <router-link to="/services-one">
                                <h4 class="d-mb-10">ERP</h4>
                            </router-link>
                            <router-link to="/services-one">
                                <span class="dare-btn">자세히보기</span>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-protfolio">
                        <div class="image bg-color-white">
                            <router-link to="/services-two">
                                <img src="../../assets/images/projects/product-packaging-mes.png" alt="image">
                            </router-link>
                        </div>

                        <div class="content text-center">
                            <router-link to="/services-two">
                                <h4 class="d-mb-10">MES</h4>
                            </router-link>
                            <router-link to="/services-two">
                                <span class="dare-btn">자세히보기</span>
                            </router-link>
                        </div>
                    </div>
                </div>

                <!-- SCM 숨김처리함. 20220805_이태성 -->
                <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-protfolio">
                        <div class="image">
                            <router-link to="/services-three">
                                <img src="../../assets/images/projects/product-packaging-scm.png" alt="image">
                            </router-link>
                        </div>

                        <div class="content text-center">
                            <router-link to="/services-three">
                                <h4 class="d-mb-10">SCM</h4>
                            </router-link>
                            <router-link to="/services-three">
                                <span class="dare-btn">자세히보기</span>
                            </router-link>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-protfolio">
                        <div class="image">
                            <router-link to="/services-four">
                                <img src="../../assets/images/portfolio/portfolio-4.png" alt="image">
                            </router-link>
                        </div>

                        <div class="content text-center">
                            <router-link to="/services-four">
                                <h4 class="d-mb-10">BAR CODE</h4>
                            </router-link>
                            <router-link to="/services-four">
                                <span class="dare-btn">자세히보기</span>
                            </router-link>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Product'
    }
</script>
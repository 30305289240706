<template>
    <div class="signup-section ptb-100 d-join">
        <div class="container">
            <div class="login-form">
                <div class="login-title">
                    <h3>계정 정보 변경</h3>
                    <p>사용자 정보를 입력하세요</p>
                </div>

                <form>
                    <div class="row">
                        <div class="col-lg-12">
                            <h6>사업자등록번호</h6>
                            <div class="form-group">
                                <input v-model="BIZ_CD" type="text" id="br-number" class="form-control" 
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" placeholder="숫자만 입력">                                
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <h6>변경할 비밀번호</h6>
                            <div class="form-group">
                                <input v-model="PWD" type="password" id="apply-password" class="form-control" maxlength="20" 
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" placeholder="Password(숫자만 입력)">
                            </div>
                        </div>

                        <div v-if="this.$user.ID_USER == ''" class="col-lg-12">
                            <h6>이메일</h6>
                            <div class="form-group">
                                <input v-model="EMAIL"  type="text" class="form-control" placeholder="Email">
                            </div>
                        </div>
                      
                        <div class="col-lg-12">                            
                            <div class="send-btn text-center">
                                <v-btn @click="userCheck" class="dare-btn mr-1 d-btn-admin">
                                    <i class="mdi mdi-account-cog-outline"></i> <b>비밀번호 변경하기</b>
                                </v-btn>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    import cryto from '@/utils/utils'

    export default {
        name: 'AccountResetting',
        components: {

        },
        data() {
            return {
                BIZ_CD: '',
                PWD: '',
                EMAIL: '',
            };
        },
        mounted() {
           
        },
        methods: {
            async userCheck() {

                if((this.BIZ_CD || '') === '' && (this.PWD || '') === '') {
                    this.$msgBox.show('사업자등록번호와 비밀번호를 입력하세요.');
                    return;
                }

                if((this.BIZ_CD || '') === (this.PWD || '')) {
                    this.$msgBox.show('사업자등록번호와 동일한 비밀번호는 불가능 합니다.');
                    return;
                }

                if(this.$user.ID_USER == '' && (this.EMAIL || '') === '') {
                    this.$msgBox.show('이메일을 입력하세요.');
                    return;
                }

                if(this.BIZ_CD === '0000000000' || this.checkCorporateRegiNumber(this.BIZ_CD) === false) {
                    this.$msgBox.show('유효하지 않는 사업자등록번호 입니다.');
                    return;
                }

                // //암호화된 데이터 여부 체크
                // if(this.PWD.includes('==') === false)
                // {
                //     this.PWD = cryto.SHAencode(this.PWD);                    
                // }

                await this.$http.post('/board/bizdoc-user-update', {                   
                    BIZ_CD: this.BIZ_CD,                                        //사업자등록번호 [필수]
                    PWD: cryto.SHAencode(this.PWD),                             //패스워드 [필수]
                    EMAIL: this.EMAIL,                                          //이메일
                    ADMIN_YN: (this.$user.ID_USER == 'dareadmin' ? 'Y' : 'N'),  //Y:관리자, N:일반 사업신청자		
                }).then(res => {
                    if (res.result) {
                        //res.result >> 처리 결과 RETURN_GUBUN / 0.이메일정보 불일치로 변경처리 실패, 1.관리자권한으로 변경처리 완료, 2.사업신청 신청 사업자 변경처리 완료
                        // console.log('AccountResetting.vue >>> res.result : ', res.result[0][0].RETURN_GUBUN);                     
                        if(res.result[0][0].RETURN_GUBUN === '0') {
                            this.$msgBox.show('이메일정보 불일치로 변경처리 실패 하였습니다.');
                        }
                        else if(res.result[0][0].RETURN_GUBUN === '1') {
                            this.$msgBox.show('관리자권한으로 변경처리 완료 하였습니다.');
                        }
                        else if(res.result[0][0].RETURN_GUBUN === '2') {
                            this.$msgBox.show('정상적으로 변경처리 되었습니다.');
                        }                                                
                    }
                }).catch(err => {
                    alert(err)
                })		

            },
            //사업자등록번호 유효성체크
            checkCorporateRegiNumber(number){
                var numberMap = number.replace(/-/gi, '').split('').map(function (d){
                    return parseInt(d, 10);
                });

                if(numberMap.length == 10){
                    var keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
                    var chk = 0;

                    keyArr.forEach(function(d, i){
                        chk += d * numberMap[i];
                    });

                    chk += parseInt((keyArr[8] * numberMap[8])/ 10, 10);
                    // console.log(chk);
                    return Math.floor(numberMap[9]) === ( (10 - (chk % 10) ) % 10);
                }

                return false;
            },
        },
    }
</script>
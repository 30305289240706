<template>
	<v-container class="text-left">
		<v-card elevation="10" outlined width="100%" class="pa-2 mx-auto">

			<v-card-title class="px-4 py-2 ma-0 border-bottom">
				<span class="mr-2 primary--text">작성</span>
			</v-card-title>

			<v-card-text class="px-4 pt-3">
				<v-select :items="items" :rules="rulesNull" v-model="modCd" item-value="value" item-text="text"
					label="분류를 선택하세요.">
				</v-select>
				<v-text-field label="제목" :rules="rules" :counter="100" v-model="title"></v-text-field>
				<v-text-field
					label="첨부파일경로를 입력하세요."
					:counter="500"
					v-model="filepath"
				></v-text-field>
				<Editor ref="editor" style="margin-top: 10px;" />
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn @click="save" color="primary">
					저장
				</v-btn>
				<v-btn v-if="this.boardTp === '1'" @click="movePage('/notice')" color="secondary">
					뒤로
				</v-btn>
				<v-btn v-if="this.boardTp === '2'" @click="movePage('/manual')" color="secondary">
					뒤로
				</v-btn>

				<!-- <b-button @click="save" variant="outline-success">저장</b-button>
				<b-button @click="movePage('/notice')" variant="outline-danger">뒤로</b-button> -->
				<!-- <Button
					@click="save"
					color="success"
					rounded
					small
					iconName="mdi-content-save-all"
					btnName="저장"
				></Button>
				<Button
					@click="movePage('/boardlist')"
					color="grey darken-1"
					rounded
					small
					iconName="mdi-arrow-left"
					btnName="뒤로"
				></Button> -->


			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
	import Editor from '@/components/Controls/Editor'

	export default {
		components: {
			Editor,
		},
		data: () => ({
			boardTp: '',
			modCd: '',
			filepath: '',
			items: [{
					value: 'IP',
					text: '중요'
				},
				{
					value: 'NM',
					text: '일반'
				},
				{
					value: 'SY',
					text: '시스템'
				},
				{
					value: 'AC',
					text: '회계'
				},
				{
					value: 'HR',
					text: '인사'
				},
				{
					value: 'MM',
					text: '물류'
				},
				{
					value: 'GW',
					text: '그룹웨어'
				},
			],
			rules: [
				value => !!value || 'Required.',
				value => (value && value.length >= 3) || 'Min 3 characters',
			],
			rulesNull: [
				value => !!value || 'Required.',
			],
			title: '',
			files: []
		}),

		mounted() {
			this.boardTp = this.$route.query.boardTp;

			console.log(this.boardTp);
		},

		methods: {
			async save() {
				var content = this.$refs.editor.getContent()
				var modCd = this.modCd
				var title = this.title
				var filepath = this.filepath

				if (modCd == null || modCd == '') {
					this.$msgBox.show('분류를 선택하세요.');
					return;
				}

				if (title == null || title == '' || title.length < 3) {
					this.$msgBox.show('제목을 입력해주세요.');
					return;
				}

				if (content == null || content == '') {
					this.$msgBox.show('게시판 내용이 없습니다. 내용을 작성하세요.');
					return;
				}

				let res = await this.$confirm('Do you want to save it?', {
					title: 'Confirm Save'
				})
				console.log(this.boardTp);

				if (res) {
					this.$http.post('/board/board-insert', {
						MOD_CD: modCd,
						TITLE: title,
						CONTENT: content,
						WRITER: this.$user.ID_USER,
						FILE_NM: filepath,
						BOARD_TP: this.boardTp
					}).then(res => {

						if (res.result) {
							this.$msgBox.show('Insert Complete.');
							this.movePage('/boardDetail?docNo=' + res.result + '&boardTp=' + this.boardTp);
						}
					}).catch(err => {
						alert(err)
					})
				}


			},
	

		},
	}
</script>

<style></style>

<template>
    <div class="ptb-100 d-modules">
        <div class="container">
            <div class="section-title">
                <h2>모듈별 특장점</h2>
                <div class="bar"></div>
            </div>
            <div class="row align-items-top">
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>회계</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">사용자별 접근 권한 및 승인 통제 작성자와 승인자의 이력 관리 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">재무 사업장, 세무 사업장의 개별 또는 통합 관리 기능지원</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">홈택스/여신금융협회/은행/카드사 등 대사기능 및 자동 전표 처리를 통한 업무의 신속 정확성을 높임</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>인사</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">연월차 신청 내역을 근태 기록에 반영하여 연차수당 계산 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">급여 계산식을 설정하여 사원별 근태 현황에 따른 연장근로수당과 변동 급여 계산 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">원천세, 연말정산, 간이 지급명세서 등 각종 세무 신고 지원</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>영업</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">견적서, 수주서, 거래명세서 EMAIL 전송 견적 차수별 변경 이력 관리가 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">납기 일자 별 거래처에 대한 납품 확인 및 미납 수량을 조회하고 관리</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">SET 품 관리, Serial 번호 관리, 매출 자료분석, 이익률 분석, 원가 분석 등이 용이</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>구매/자재</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">단계별 절차를 통한 물류 통제</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">재고 데이터 추적 및 에이징(부진,부동,불용) 구현</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">물리 및 가상 창고를 활용하여 재고 상태의 실시간 파악</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>생산</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">회사업종, 특성에 따라 BOM, 공정BOM, LAB-BOM 지원</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">소요량산출(MRP) 시뮬레이션 기능 제공 원부자재 재고량 파악 구매/외주요청 및 작업지시 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">간편생산관리부터 복잡한 생산관리까지 융통성있게 운영</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>무역</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">원가 배부기준 설정과 미착 정산을 통한 손쉬운 매입원가 산출</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">체계적인 무역 Document 생성</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">EPE기업, 소유권 불이전 수출 등 다양한 무역 형태에 대한 커스터마이징</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>경영</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">전년/전월/5개년 대비 재무/손익 분석 고객별 매출액/매출채권 심층 분석 지원</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">매출 및 수주 트랜드 분석, 물류/생산 요약(집중)분석  보고서 제공</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">인력통계 및 사원별 임금이력 분석, 급여/상여 시뮬레이션을 통한 상승분 미래 예측 가능</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>품질</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">ISO9001 및 QMS 요구품질 대응</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">IQC,PQC,OQC의 통계적 지표 관리</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">검사규격, 검사방법, 검사장비 ,검사분류 등의 자료를 파일로 업로드</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>외주</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">무상사급/유상사급 중 사급구분에 따른 관리 기능 지원</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">외주자재(유상사급)에 매출마감관리 매출전표처리로 회계에 연동</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">BOM등록 기준으로  외주생산에 투입되는 자재, 수량 자동 계산해 투입 처리 지원</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>설비/금형/공구</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">IOT 장비 연동을 통한 실시간 모니터링</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">유형자산 현황 및 이력 관리</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">변경점 추적 및 관리</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>원가</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">투입된 원재료,부재료 등 부대비용이 포함하여 원가계산 지원</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">품목공정 기준으로 재고평가, 원가기간, 원가요소등록, 외주가공비집계 관리</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">업종별 이익현황 등 원가 관련 보고서를 제공</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>EIS</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">별도 입력 없이 담당자들의 정보를 취합하여 정보 제공</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">직관적인 정보제공하여 쉽게 정보를 해석 할 수 있도록 제공</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">현재 경영정보 분석 및 향후 경영참고지표를 제공</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>성과</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">조직의 목표와 개인의 목표를 연동하여 명확한 목표를 통해 조직에서 동기부여를 이끌어내는 MBO 적용한 평가 지원</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">4가지 성과지표인 재무, 고객, 내부프로세스, 학습과 성장 등의 요소로 구분해 기업 성과를 종합적으로 평가하는 BSC 적용한 평가 지원</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">부서별평가조회, 개인별평가조회, 종합평가현황 3가지 성과측정보고서 제공</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3>프랜차이즈</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">가맹점 계약 및 매점현황, 매점별 재고 현황 실시간 확인 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">매장별 입출고 내역을 직접 입력하고, 매장별로 데이터를 분석할 수 있음</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">매장 내 직원들을 위한 교육 및 우수인력 관리 등록을 지원하여 내부고객의 보상 관리를 위한 필요한 자료 도출</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3>그룹웨어</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">경비제출 시 회계, 세법에 대한 지식이 없어도 관리팀의 설정만으로 비용관련 세무관리 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">별도 문서 작성 없이 ERP상에 등록된 정보를 그룹웨어 전자결재 화면으로 연동하여 업무보고 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">그룹웨어 상 작성된 지출 품의서 등을 제공하고 관리자 화면에서 편리한 각종 조회 현황 메뉴를 제공</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ModulesValue'
    }
</script>
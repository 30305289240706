<template>
    <div class="overview-section pt-100">
        <div class="container">
            <div class="section-title">
                <h2><span>모듈별 기능</span> 상세 구성도</h2>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="audience-image">
                        <img src="../../assets/images/services/services-one-3.png" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Modules'
    }
</script>
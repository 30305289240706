<template>
    <div class="development-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h5>다래아이엔씨는 임직원 모두가 행복하고 건강한 회사를 지향합니다</h5>
                <div class="bar"></div>
            </div>

            <div class="row align-items-top ">

                <div class="col-lg-3 col-md-3">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-deb0fe">
                            <i class="mdi mdi-airplane-takeoff"></i>
                        </div>
                        <h5 class="d-mt-10">워라밸 지향</h5>
                        <p>정시 퇴근 독려<br>파견 근무 없음<br>자유로운 연차 사용</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-79e8e2">
                            <i class="mdi mdi-account-multiple-check-outline"></i>
                        </div>
                        <h5 class="d-mt-10">유연한 노사문화</h5>
                        <p>수평적이고 밝은 분위기<br>편안한 근무환경을 위한<br>자율 복장제도 실시</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-deb0fe">
                            <i class="mdi mdi-library-outline"></i>
                        </div>
                        <h5 class="d-mt-10">자기계발 지원</h5>
                        <p>사내 교육 지원<br>직무 관련 사외 교육비 지원<br>직무 관련 도서구입비 지원</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-79e8e2">
                            <i class="mdi mdi-calendar-filter-outline"></i>
                        </div>
                        <h5 class="d-mt-10">공휴일, 기념일 준수</h5>
                        <p>명절대체공휴일<br>근로자의 날 등</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-deb0fe">
                            <i class="mdi mdi-cake-variant-outline"></i>
                        </div>
                        <h5 class="d-mt-10">생일자 이벤트</h5>
                        <p>상품권 지급</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-79e8e2">
                            <i class="mdi mdi-trophy-outline"></i>
                        </div>
                        <h5 class="d-mt-10">창립기념일 포상</h5>
                        <p>우수사원 상품권 지급</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-deb0fe">
                            <i class="mdi mdi-account-arrow-up-outline"></i>
                        </div>
                        <h5 class="d-mt-10">내일채움공제</h5>
                        <p>청년내일채움공제 지원</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-79e8e2">
                            <i class="mdi mdi-account-cash-outline"></i>
                        </div>
                        <h5 class="d-mt-10">경조사비 지원</h5>
                        <p>경조금 및 경조화환 지급</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-deb0fe">
                            <i class="mdi mdi-shield-account-outline"></i>
                        </div>
                        <h5 class="d-mt-10">4대보험 지원</h5>
                        <p>국민연금, 건강보험<br>장기요양, 고용보험</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-79e8e2">
                            <i class="mdi mdi-hiking"></i>
                        </div>
                        <h5 class="d-mt-10">사내동호회</h5>
                        <p>사내 동호회 지원</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-deb0fe">
                            <i class="mdi mdi-home-city-outline"></i>
                        </div>
                        <h5 class="d-mt-10">가정생활 지원</h5>
                        <p>출산휴가, 임신기 단축근무<br>육아휴직, 육아기 단축근무</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-79e8e2">
                            <i class="mdi mdi-coffee-maker-outline"></i>
                        </div>
                        <h5 class="d-mt-10">휴식공간</h5>
                        <p>커피, 차, 간식 제공<br>안마의자 구비</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Benefits'
    }
</script>
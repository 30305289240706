<template>
    <div>
        <v-stepper-step
        :complete="bSaved"
        :step="stepnum"
        @click="clicked"
        >
            <!-- 판매제품 사진(첨부파일) -->
            {{TITLE}}
            <small v-if="bSaved==true">첨부완료</small>
            <small v-if="bSaved==false"><span>미완료</span></small>
        </v-stepper-step>

        <!-- <v-stepper-content step="16"> -->
        <v-stepper-content :step="stepnum">
            <v-card
                :loading="loading"
                class="mb-12"
                max-width="560"
            >
                <!-- <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/7JMpmiQLES0"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                ></iframe> -->
                <h5 class="text-left font-blue font-w-normal mt-4 d-apply-upload-title"><i class="mdi mdi-file-upload-outline"></i> 판매제품 업로드하기<span class="font-size-15 font-purple">(2개 이상일 경우 zip 파일올리기)</span></h5>
                <S3UploadFile stepNo="docnm21" :uploadBtn="false" :docInfo="docInfo" ref='docnm21' v-model="bSaved"/>
            </v-card>

            <div class="text-center my-6">
                <v-btn
                    class="d-inline-block mr-1"
                    color="primary"
                    v-if="CLOSE_YN === 'N'"
                    @click="saveClicked"
                >
                    저장
                </v-btn>
                <v-btn
                    class="d-inline-block mr-1"
                    color="primary"
                    @click="nextClicked"
                >
                    계속
                </v-btn>
                <v-btn
                    class="d-inline-block bg-color-gray"
                    text
                    @click="cancelClicked"
                >
                    닫기
                </v-btn>
            </div>
        </v-stepper-content>
    </div>
</template>

<script>
    import S3UploadFile from '../../Controls/S3UploadFile.vue';
    export default {
        components: {
			S3UploadFile
		},
        name: 'DOC_NM21',
        props: {
            psteptitle: {type:String, default:'완제품 사진(첨부파일)'},
            psteptp: {type:String, default:'1'},
            pstepnum: {type:Number , default:1},
            pdocno: {type:Number, default:1},
            pmodcd: {type:String, default:''},
            pbizcd: {type:String, default:''},
            pwritedt: {type:String, default:''},
            pcloseyn: {type:String, default:'N'},
        },
        data() {
            return {
                TITLE: '',
                bSaved: false, //작성완료여부
                CLOSE_YN: 'N',  //신청서가 진행중이면 'N', 마감상태면 'Y' 로 관리함
                STEP_TP: '1',
                stepnum: 1,
                DOC_NO: 1,
                MOD_CD: '',
                BIZ_CD: '',
                WRITE_DT: '',
                docInfo : {},
            };
        },
        created() {
            this.TITLE = this.psteptitle;
            this.STEP_TP = this.psteptp;
            this.stepnum = this.pstepnum;
            this.DOC_NO = this.$route.query.pdocno;
            this.MOD_CD = this.$route.query.pmodcd;
            this.BIZ_CD = this.$route.query.pbizcd;
            this.WRITE_DT = this.$route.query.pwritedt;
            this.CLOSE_YN = this.$route.query.pcloseyn;
            this.docInfo = {
                DOC_NO: this.DOC_NO,
                BIZ_CD: this.BIZ_CD,
                MOD_CD: this.MOD_CD,
                STEP_NUM: this.stepnum,
                WRITE_DT: this.WRITE_DT,
            }
        },
        mounted() {
            // console.log('DOC_NM21.vue >>>> mounted >>>> this.DOC_NO : ', this.DOC_NO
            // , ', this.BIZ_CD : ', this.BIZ_CD
            // , ', this.MOD_CD : ', this.MOD_CD
            // , ', this.STEP_NUM : ', this.stepnum
            // , ', this.WRITE_DT : ', this.WRITE_DT
            // );
        },
        methods:{
            clicked() {
                this.$emit('click');
            },
            async saveClicked() {
                let res = await this.$confirm('작성하신 내용을 저장하시겠습니까?', {
					title: '확인'
				})

                if (res) {
                    //파일 업로드
                    this.bSaved = await this.$refs.docnm21.uploadFiles();

                    if(this.bSaved === true)
                        this.$msgBox.show('저장되었습니다.');

                    this.$emit('saveclick');
				}
            },
            nextClicked() {
                this.$emit('nextclick');
            },
            cancelClicked() {
                this.$emit('cancelClick');
            },
        }
    }
</script>
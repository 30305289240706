<template>
    <div>
        <Navbar />
        <PageTitle />
        <Overview />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import PageTitle from '../ServicesThree/PageTitle'
    import Overview from '../ServicesThree/Overview'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'ServicesThreePage',
        components: {
            Navbar,
            PageTitle,
            Overview,
            Footer,
            CopyRight,
        }
    }
</script>
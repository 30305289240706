<template>
    <div>
        <Navbar />
        <JoinPageTitle />
        <UserJoin />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import JoinPageTitle from '../LogIn/JoinPageTitle'
    import UserJoin from '../LogIn/UserJoin'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'UserJoinPage',
        components: {
            Navbar,
            JoinPageTitle,
            UserJoin,
            Footer,
            CopyRight,
        }
    }
</script>
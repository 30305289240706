<template>
    <div>
        <Navbar />
        <Banner />
        <Services />
        <OurProduct />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>

import Navbar from '../Layout/Navbar'
import Banner from '../HomeTwo/Banner'
import Services from '../HomeTwo/Services'
import OurProduct from '../HomeTwo/OurProduct'
import Footer from '../Layout/Footer'
import CopyRight from '../Layout/CopyRight'

export default {
    name: 'HomePageTwo',
    components: {
        Navbar,
        Banner,
        Services,
        OurProduct,
        Footer,
        CopyRight,
    }
}
</script>

<template>
    <div class="signup-section ptb-100 d-join">
        <div class="container">
            <div class="signup-form">
                <h3>사업자등록번호 로그인</h3>
                <form>
                    <div class="row d-mt-50">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="br-number"><h5 class="font-w-norma">사업자등록번호 <i class="mdi mdi-asterisk font-red"></i></h5></label>
                                <input v-model="BIZ_CD" type="text" id="br-number" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" placeholder="숫자만 입력">
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="apply-password"><h5 class="font-w-normal">비밀번호 <i class="mdi mdi-asterisk font-red"></i></h5>
                                <p class="font-blue">최초 입력한 비밀번호로 임시저장한 신청서를 불러올 수 있습니다.</p></label>
                                <input v-model="PWD" type="password" id="apply-password" class="form-control" maxlength="20" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" placeholder="Password(숫자만 입력)">
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-check">
                                <input v-model="isLoginSave" type="checkbox" class="form-check-input" id="isLoginSave" @click="autologinChange">
                                <label class="form-check-label" for="checkme">로그인 정보 저장하기</label>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="send-btn text-center">
                                <v-btn @click="userCheck('list')" class="dare-btn d-btn-write mr-1">
                                    <i class="mdi mdi-archive-edit-outline"></i> <b>신청서 작성하기</b>
                                </v-btn>
                                <v-btn @click="userCheck('result')" class="dare-btn d-btn-result ml-1">
                                    <i class="mdi mdi-checkbox-marked-circle-outline"></i> <b>신청결과 확인하기</b>
                                </v-btn>
                            </div>
                            <div class="send-btn text-center" v-if="this.$user.ID_USER == 'dareadmin'">
                                <v-btn @click="userCheck('manager')" class="dare-btn mr-1 d-btn-admin">
                                    <i class="mdi mdi-account-cog-outline"></i> <b>관리자 전용</b>
                                </v-btn>
                                <!-- <v-btn @click="userCheck('accreset')" class="dare-btn mr-1 d-btn-admin">
                                    <i class="mdi mdi-account-cog-outline"></i> <b>비밀번호 변경</b>
                                </v-btn> -->
                                <!-- <v-btn @click="userCheck('report')" class="dare-btn ml-1 d-btn-admin">
                                    <i class="mdi mdi-chart-bell-curve"></i> <b>관리자 리포트</b>
                                </v-btn> -->
                            </div>
                        </div>

                    </div>
                </form>
            </div>

        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    import cryto from '@/utils/utils'
    import axios from 'axios';

    export default {        
        name: 'ApplyJoin',
        data() {
            return {
                BIZ_CD: this.$applyuser.BIZ_CD,
                PWD: '',
                isLoginSave: this.$applyuser.IS_LOGIN_SVAE == 'y',
                isAutoLogin: false, //자동로그인 여부
                PAGE_TP: '', //페이지를 구분을 나누어 시기마다 보여지는 스탭을 다르게 하기 위함.   
            };
        },
        mounted() {
            this.getIpClient();
            // console.log('ApplyJoin.vue > this.$applyuser.CLIENT_IP : ', this.$applyuser.CLIENT_IP);

            this.BIZ_CD = this.$applyuser.BIZ_CD;
            this.$applyuser.PWD = this.$applyuser.IS_LOGIN_SVAE == 'y' ? this.$applyuser.PWD : '';
            this.PWD = this.$applyuser.IS_LOGIN_SVAE == 'y' ? this.$applyuser.PWD : '';
            this.isAutoLogin = this.$route.query.autologin == 'y' ? true : false;
            this.PAGE_TP = (this.$route.query.ppagetp || '');
            
            //다른 페이지에서 이동해온 경우 자동로그인을 해야할 때
            if(this.isAutoLogin === true)
            {                
                this.userCheck('list');
            }
        },
        methods:{
            autologinChange() {
                localStorage.setItem('isLoginSave', this.isLoginSave ? 'y' : 'n');
            },
            async getIpClient() {
                try {
                    const response = await axios.get('https://ipinfo.io/json');                
                    localStorage.setItem('CLIENTIP', response.data.ip);                    
                } catch (error) {
                    console.error(error);
                }
            },
            async userCheck(gubun) {

                //관리자 전용 페이지 이동
                if(gubun === 'manager') {
                    this.movePage('/apply-manager');
                    return;
                }

                //관리자 리포트 페이지 이동
                if(gubun === 'report') {
                    this.movePage('/apply-report');
                    return;
                }

                //비밀번호 변경
                if(gubun === 'accreset') {
                    this.movePage('/accreset');
                    return;
                }

                if((this.BIZ_CD || '') === '' && (this.PWD || '') === '') {
                    this.$msgBox.show('사업자등록번호와 비밀번호를 입력하세요.');
                    return;
                }

                if((this.BIZ_CD || '') === (this.PWD || '')) {
                    this.$msgBox.show('사업자등록번호와 동일한 비밀번호는 불가능 합니다.');
                    return;
                }

                if(this.BIZ_CD === '0000000000' || this.checkCorporateRegiNumber(this.BIZ_CD) === false) {
                    this.$msgBox.show('유효하지 않는 사업자등록번호 입니다.');
                    return;
                }

                //암호화된 데이터 여부 체크
                if(this.PWD.includes('==') === false)
                {
                    this.PWD = cryto.SHAencode(this.PWD);
                    this.$applyuser.PWD = this.PWD;
                }

                await this.$http.get('/board/apply-user-select', {BIZ_CD: this.BIZ_CD, PWD: this.PWD}).then(res => {

                    if(res.errorcode=="") {
                        if(res.result[0] == null || res.result[0].length == 0 )
                        {
                            // this.movePage('/apply-list?pbizcd=' + this.BIZ_CD + '&ppwd=');
                        }
                        else
                        {
                            //로그인 정보 저장하기 체크값
                            this.$applyuser.IS_LOGIN_SVAE = this.isLoginSave ? 'y' : 'n';
                            this.$applyuser.BIZ_CD = this.$applyuser.IS_LOGIN_SVAE == 'y' ? this.BIZ_CD : '';
                            // this.$applyuser.PWD = this.$applyuser.IS_LOGIN_SVAE == 'y' ? this.PWD : '';

                            //RETURN_GUBUN 값
                            //0.신규, 1.사업자등록번호와 패스워드 일치, 2. 패스워드 불일치
                            if(gubun === 'list') {
                                if(res.result[0][0].RETURN_GUBUN === '0')
                                {
                                    this.$msgBox.show('처음오신걸 환영입니다. 제품 선택 후 구매방법을 선택해 주시면 신청서 작성이 가능합니다.');
                                    this.movePage('/apply-list?pbizcd=' + this.BIZ_CD + '&ppagetp=' + this.PAGE_TP);
                                }
                                else if(res.result[0][0].RETURN_GUBUN === '1')
                                {
                                    this.$msgBox.show('기존 작성한 신청서가 존재합니다. 작성내역에서 확인 가능합니다.');
                                    this.movePage('/apply-list?pbizcd=' + this.BIZ_CD + '&ppagetp=' + this.PAGE_TP);
                                }
                                else if(res.result[0][0].RETURN_GUBUN === '2')
                                {
                                    this.$msgBox.show('비밀번호가 일치하지 않습니다. 다시 입력해주세요.');
                                }
                            }
                            else if(gubun === 'result') {
                                if(res.result[0][0].RETURN_GUBUN === '2')
                                {
                                    this.$msgBox.show('비밀번호가 일치하지 않습니다. 다시 입력해주세요.');
                                }
                                else
                                {
                                    this.movePage('/apply-result?pbizcd=' + this.BIZ_CD + '&ppagetp=' + this.PAGE_TP);
                                }
                            }
                        }
                    }
                    else
                    {
                        alert(res.errorMsg);
                    }
                }).catch(err => {
                    alert(err)
                })
            },
            //사업자등록번호 유효성체크
            checkCorporateRegiNumber(number){
                var numberMap = number.replace(/-/gi, '').split('').map(function (d){
                    return parseInt(d, 10);
                });

                if(numberMap.length == 10){
                    var keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
                    var chk = 0;

                    keyArr.forEach(function(d, i){
                        chk += d * numberMap[i];
                    });

                    chk += parseInt((keyArr[8] * numberMap[8])/ 10, 10);
                    // console.log(chk);
                    return Math.floor(numberMap[9]) === ( (10 - (chk % 10) ) % 10);
                }

                return false;
            },
        }
    }
</script>
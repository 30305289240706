<template>
    <div class="clients-section pt-100">
        <div class="container">
            <div class="section-title">
                <h2>사업소개</h2>
                <p>2016년 세법상 중소기업 기준 대폭 완화! 중소기업 세제혜택 대상 기업도 대폭 확대!<br>기업들이 놓치고 있는 세제지원!<br>우리회사가 대상이 되는지, 세제혜택을 받고 있는지 확인!</p>
                <div class="bar"></div>
            </div>

            <div class="details-image mt-12">
                <!-- <h4>나만 세금 많이 내고 있는 이유</h4> -->
                <img src="../../assets/images/projects/projects-three.jpg" alt="image">
            </div>
            <div class="details-image mt-0">
                <!-- <h4>나만 세금 많이 내고 있는 이유</h4> -->
                <img src="../../assets/images/projects/projects-three-1.jpg" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProjectsAbout'
    }
</script>
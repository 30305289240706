<template>
    <div class="overview-section ptb-100 d-projectsone-details">
        <div class="container">
            <div class="section-title">
                <h2>지원내용</h2>
                <div class="bar"></div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="data-image">
                        <img src="../../assets/images/data.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="app-inner-text">
                        <div class="icon">
                            <i class="flaticon-it"></i>
                        </div>
                        <h3>기초 / 고도화1</h3>
                        <p><b><i class="flaticon-tick font-blue"></i></b> 제품설계‧생산공정 개선 등을 위해 IoT, 5G, 빅데이터, AR·VR, 클라우드 등 첨단기술을 적용한 스마트공장 솔루션 구축 및 구축에 필요한(솔루션 연동) 자동화장비‧제어기‧센서 등 지원</p>
                    </div>

                    <div class="app-inner-text">
                        <div class="icon">
                            <i class="flaticon-software"></i>
                        </div>
                        <h3>고도화2</h3>
                        <p><b><i class="flaticon-tick font-blue"></i></b> 인공지능 솔루션을 적용하여 스마트공장의 활용도, 수준 향상을 위한 구축 지원</p>
                        <div class="row d-mb-10 d-advance">
                            <div>
                                <b-table-simple hover small caption-top responsive class="text-center">
                                    <caption class="text-center">수준 및 지원 유형 참고</caption>
                                    <colgroup><col><col><col><col><col><col><col><col></colgroup>
                                    <b-thead head-variant="light">
                                        <b-tr>
                                            <b-th >수준</b-th>
                                            <b-th>지원유형</b-th>
                                            <b-th>표준</b-th>
                                            <b-th>IOT대상</b-th>
                                            <b-th>특성</b-th>
                                            <b-th>조건(구축 수준)</b-th>
                                            <b-th>주요도구</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr>
                                            <b-th>고도</b-th>
                                            <b-td rowspan="2">고도화2<br>(중간2 이상)</b-td>
                                            <b-td>자율운영</b-td>
                                            <b-td>작업자, 설비, 자재,<br>운전조건 + 환경</b-td>
                                            <b-td>맞춤 및 자율<br>(Customized)</b-td>
                                            <b-td>모니터링부터 제어,<br>최적화까지 자율로 진행</b-td>
                                            <b-td>인공지능, AR/VR,<br>CPS 등</b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th>중간2</b-th>
                                            <b-td>최적화</b-td>
                                            <b-td>작업자, 설비, <br>자재, 운전조건</b-td>
                                            <b-td>최적화<br>(Optimized)</b-td>
                                            <b-td>공정운영 시뮬레이션을<br>통해 사전 대응 가능</b-td>
                                            <b-td>센서 제어기<br>최적화 도구</b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th>중간1</b-th>
                                            <b-td>고도화1</b-td>
                                            <b-td>제어</b-td>
                                            <b-td>작업자, 설비, 자재</b-td>
                                            <b-td>분석<br>(Analyzed)</b-td>
                                            <b-td>수집된 정보를 분석하여<br>제어 가능 수준 및<br>지원 유형 참고</b-td>
                                            <b-td>센서 + 분석도구</b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th>기초</b-th>
                                            <b-td rowspan="2">기초</b-td>
                                            <b-td>모니터링</b-td>
                                            <b-td>작업자, 설비, 자재</b-td>
                                            <b-td>측정<br>(Measured)</b-td>
                                            <b-td>생산정보의 모니터링이<br>실시간 가능</b-td>
                                            <b-td>센서</b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th>중간2</b-th>
                                            <b-td>점검</b-td>
                                            <b-td>자재</b-td>
                                            <b-td>식별<br>(Identifed)</b-td>
                                            <b-td>부분적 표준화 및<br>데이터 관리</b-td>
                                            <b-td>바코드 PRO</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div>
                        <p>
                            <b><span class="font-blue">※</span></b> 고도화2 사업은 인공지능 솔루션을 적용한 사업으로 총 15개 기업 지원<br>
                            <b><span class="font-blue">※</span></b> 동일 수준 사업은 기업당 수준별 1회만 신청 가능
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProjectsDetails'
    }
</script>
<template>
    <div class="about-section ptb-100 d-list-overview">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="audience-image">
                        <img src="../../assets/images/about/about.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="about-tab pt-0 d-mt-20">
                        <h2>주식회사 다래아이엔씨</h2>
                        <div class="bar"></div>

                        <div class="about-list-tab pb-70">
                            <b-tabs content-class="mt-3">
                                <b-tab title="회사소개">
                                    <div class="tabs_item">
                                        <div class="text">
                                            <h3>주식회사 다래아이엔씨는 2012년 8월에 법인을 설립하여 중소, 중견기업용 경영관리 통합솔루션(ERP)를 개발, 판매, 유지보수를 목적으로 하는 IT회사입니다.</h3>
                                        </div>
                                        <p class="d-mt-30">본사는 서울 문래동에 위치하고 있으며, 수원을 포함한 전국 5개 지사와 베트남 하노이에 해외 지사를 운영하고 있습니다.</p>
                                        <p class="d-mt-30 d-mb-30">평균 경력 15년 이상의 전문가들을 필두로 약 50명의 임직원들이 회사를 이끌어 가고있습니다.</p>
                                        <!-- <ul class="list">
                                            <li>
                                                <p>주식회사 다래아이엔씨는 2012년 8월에 법인을 설립하여 중소, 중견기업용 경영관리 통합솔루션(ERP)를 개발, 판매, 유지보수를 목적으로 하는 IT회사입니다.</p>
                                            </li>
                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p>서울 본사(양평동 소재) : 00명</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p>수원 지사(00동 소재)포함 전국 00개의 지사 : 00명</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p>베트남 하노이 : 00명</p>
                                            </li>
                                        </ul> -->

                                        <p><a href="http://www.dareinc.co.kr/" class="font-blue" target="_blank">www.dareinc.co.kr</a></p>
                                    </div>
                                </b-tab>
                                <b-tab title="주요사업성과">
                                    <div class="tabs_item">
                                        <div class="text">
                                            <h3>다래는 글로벌 K-기업과 함께합니다.</h3>
                                        </div>

                                        <ul class="list">
                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p>중소벤처기업부 K-바우처(Voucher) 사업자선정 및 솔루션 공급</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p>산업자원부 스마트공장 지원기업 POOL등록</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p>자동차부품산업진흥재단(KAP) POOL등록</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p>중소기업기술정보진흥원과 경영혁신플랫폼 사업자선정 및 솔루션 공급</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p>스마트빌과 MOU를 통한 전자세금계산서 협업솔루션 개발 및 공급</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p>㈜한국사이버결제(KCP)와 MOU를 통한 프랜차이즈 ERP솔루션 개발 및 공급</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p>한국식품안전관리원과 Smart HACCP 보급확산을 위한 협의체 구성</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p>한국남동발전 협력사 스마트제조솔루션 공급 및 구축</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p>중소기업기술정보진흥원 생산현장디지털화 고도화 사업 진행</p>
                                            </li>
                                        </ul>

                                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p> -->

                                        <!-- <a class="default-btn" href="#">
                                            Discover More
                                        </a> -->
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'About'
    }
</script>
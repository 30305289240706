<template>
    <div class="overview-section ptb-100 d-features">
        <div class="container">
            <div class="section-title">
                <h2>다래ERP 특장점</h2>
                <div class="bar"></div>
            </div>
            <div class="schedule-list-tab"><!-- PC -->
                <b-tabs content-class="mt-3">
                    <b-tab title="1. 시스템의 유연성">
                        <div class="tabs_item">
                            <div class="row align-items-top">
                                <div class="col-lg-1">
                                    <div class="tab-image">
                                    </div>
                                </div>

                                <div class="col-lg-11">
                                    <div class="tab-content">
                                        <h3>1. 시스템의 유연성</h3>
                                        <p class="d-pl-35 d-w-b-keep-all">다년간의 구축 Know-How를 반영한 표준 프로세스는 단기간에 실무 적용이 가능하며, Customizing사항을 유연하게 반영할 수 있는 장점이 있습니다.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <b-tab title="2. 솔루션 연동, 확장성">
                        <div class="tabs_item">
                            <div class="row align-items-top">
                                <div class="col-lg-1">
                                    <div class="tab-image">
                                    </div>
                                </div>

                                <div class="col-lg-11">
                                    <div class="tab-content">
                                        <h3>2. 솔루션 연동, 확장성</h3>
                                        <p class="d-pl-35 d-w-b-keep-all">ERP솔루션과 더불어  Add-On 가능한 그룹웨어, SCM, WEB주문 솔루션과의 연동이 가능한 장점이 있습니다.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <b-tab title="3. 안정화된 시스템">
                        <div class="tabs_item">
                            <div class="row align-items-top">
                                <div class="col-lg-1">
                                    <div class="tab-image">
                                    </div>
                                </div>

                                <div class="col-lg-11">
                                    <div class="tab-content">
                                        <h3>3. 안정화된 시스템</h3>
                                        <p class="d-pl-35 d-w-b-keep-all">S/W Architecture , H/W , Cloud Server 구성은 보다 강화된 보안과 상시모니터링을 통하여  안전하게 운영되고 있습니다.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <b-tab title="4. 계층적 정보제공">
                        <div class="tabs_item">
                            <div class="row align-items-top">
                                <div class="col-lg-1">
                                    <div class="tab-image">
                                    </div>
                                </div>

                                <div class="col-lg-11">
                                    <div class="tab-content">
                                        <h3>4. 계층적 정보제공</h3>
                                        <p class="d-pl-35 d-w-b-keep-all">정보시스템의 이용자의 구성에 따라 사용자, 관리자, 경영자(대표자)가  보는 계층적 시스템 구조를 제공합니다.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <b-tab title="5. 다국어 솔루션 기능 제공">
                        <div class="tabs_item">
                            <div class="row align-items-top">
                                <div class="col-lg-1">
                                    <div class="tab-image">
                                    </div>
                                </div>

                                <div class="col-lg-11">
                                    <div class="tab-content">
                                        <h3>5. 다국어 솔루션 기능 제공</h3>
                                        <p class="d-pl-35 d-w-b-keep-all">해외 가공기지(공장)가 있는 회사들은 다국어 버전(영어,베트남어)을 통하여 국내외 자산 및 재고, 생산규모를 파악하고 관리가 가능합니다.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="faq-accordion"><!-- 모바일 -->
                <ul class="accordion" role="tablist">
                    <b-card no-body class="accordion-item">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button class="accordion-title" block v-b-toggle.accordion-1 variant="info"><i class='bx bx-chevron-down'></i>1. 시스템의 유연성<span></span></b-button>
                        </b-card-header>
                        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text>
                                    <p>다년간의 구축 Know-How를 반영한 표준 프로세스는 단기간에 실무 적용이 가능하며, Customizing사항을 유연하게 반영할 수 있는 장점이 있습니다.</p>
                                </b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body class="accordion-item">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button class="accordion-title" block v-b-toggle.accordion-2 variant="info"><i class='bx bx-chevron-down'></i>2. 솔루션 연동, 확장성<span></span></b-button>
                        </b-card-header>
                        <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text>
                                    <p>ERP솔루션과 더불어  Add-On 가능한 그룹웨어, SCM, WEB주문 솔루션과의 연동이 가능한 장점이 있습니다.</p>
                                </b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body class="accordion-item">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button class="accordion-title" block v-b-toggle.accordion-3 variant="info"><i class='bx bx-chevron-down'></i>3. 안정화된 시스템<span></span></b-button>
                        </b-card-header>
                        <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text>
                                    <p>S/W Architecture , H/W , Cloud Server 구성은 보다 강화된 보안과 상시모니터링을 통하여  안전하게 운영되고 있습니다.</p>
                                </b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body class="accordion-item">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button class="accordion-title" block v-b-toggle.accordion-4 variant="info"><i class='bx bx-chevron-down'></i>4. 계층적 정보제공<span></span></b-button>
                        </b-card-header>
                        <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text>
                                    <p>정보시스템의 이용자의 구성에 따라 사용자, 관리자, 경영자(대표자)가  보는 계층적 시스템 구조를 제공합니다.</p>
                                </b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body class="accordion-item">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button class="accordion-title" block v-b-toggle.accordion-5 variant="info"><i class='bx bx-chevron-down'></i>5. 다국어 솔루션 기능 제공<span></span></b-button>
                        </b-card-header>
                        <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text>
                                    <p>해외 가공기지(공장)가 있는 회사들은 다국어 버전(영어,베트남어)을 통하여 국내외 자산 및 재고, 생산규모를 파악하고 관리가 가능합니다.</p>
                                </b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </ul>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Overview'
    }
</script>
<template>
    <div class="login-section ptb-100 d-user-login">
        <div class="container">
            <div class="login-form">
                <div class="login-title">
                    <h3>파트너스 회원가입</h3>
                    <p>필수 정보를 입력하세요</p>
                </div>

                <form>
                    <div class="row">

                        <div class="col-lg-12">
                            <h6>이메일</h6>
                            <div class="form-group">
                                <input type="email" class="form-control" placeholder="Email">
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <h6>비밀번호</h6>
                            <div class="form-group">
                                <input type="password" class="form-control" placeholder="Password">
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <h6>이름</h6>
                            <div class="form-group">
                                <input type="name" class="form-control" placeholder="Name">
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <h6>전화번호</h6>
                            <div class="form-group">
                                <input type="phone" class="form-control" placeholder="Phone">
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="send-btn text-center">
                                <button type="submit" class="w-100 default-btn">
                                    회원가입
                                </button>
                            </div>
                            <div class="text-center mt-4">
                                <a href="#"><img src="../../assets/images/kakao-join.png" alt="카카오계정으로 회원가입" /></a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UserLogin'
    }
</script>
<template>
    <div class="clients-section ptb-100 d-list-overview d-three-document">
        <div class="container">
            <div class="section-title">
                <h2>준비 서류</h2>
                <div class="bar"></div>
            </div>
            <div class="row">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <div class="audience-content contact-form">
                        <!-- <h5>다래ERP</h5> -->
                        <!-- <div class="bar"></div> -->
                        <ul class="list">
                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>법인세세무조정계산서(소득세신고서) 2017년 ~ 2021년 pdf</p>
                            </li>

                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>사원명부 2016년 ~ 2022년 엑셀</p>
                            </li>

                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>급상여수당지급현황 2016년 ~ 2022년 엑셀</p>
                            </li>

                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>유형자산 계정별원장 2017년 ~ 2022년 <span class="font-blue">(최근 5년이내 설비투자가 있는 경우)</span></p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProjectsAbout'
    }
</script>
<template>
    <div>
        <Navbar />
        <PageTitle />
        <JobPosting />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import PageTitle from '../JobPosting/PageTitle'
    import JobPosting from '../JobPosting/JobPosting'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'JobPostingPage',
        components: {
            Navbar,
            PageTitle,
            JobPosting,
            Footer,
            CopyRight,
        }
    }
</script>
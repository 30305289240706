<template>
    <div class="main-banner-area-two ptb-100 d-our-product">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="audience-image">
                                <a href="/services-one"><img src="../../assets/images/home-two/home-two-6.png" alt="image"></a>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="audience-content d-pl-35">
                                <h3>다래아이엔씨 제품소개</h3>
                                <div class="bar"></div>
                                <p class="d-w-b-keep-all">다래의 솔루션은 고객에게 최고의 업무 효율을 제공하기 위하여 평균 10년 이상의 경력을 갖춘 개발자들이 힘을 합쳐 각 프로그램 내에 세분화된 다양한 메뉴들을 구성해냈습니다.</p>

                                <div>
                                    <a href="/services-one" class="d-l-s--1 font-w-bold">더보기 <i class="mdi mdi-arrow-right-thick"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'OurProduct'
    }
</script>
<template>
    <div class="page-title-area item-bg-1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-title-content">
                        <h2>비밀번호 변경</h2>
                        <ul>
                            <li><router-link to="/">Home</router-link></li>
                            <li>사업신청 신청자 비밀번호 변경</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AccPageTitle',
    }
    
</script>
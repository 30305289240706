<template>
    <div>
        <CoolLightBox 
            :items="itmvideos" 
            :fullScreen="true"
            :index="index"
            @close="index = null">
        </CoolLightBox>

        <div class="services-details-area ptb-100 d-link">
            <div class="container">
                <div class="section-title">
                    <h2>LINK(링크)</h2>
                    <p>실시간 자재관리부터 비대면 작업지시까지 비대면 IOT 디지털 전환 가속화 시대의 파트너 LINK(링크)</p>
                    <div class="bar"></div>
                </div>

                <div class="services-details-video">
                    <div class="details-image text-center">
                        <img src="../../assets/images/projects/product-packaging-link-lg.png" alt="image">
                    </div>

                    <div class="details-video">
                        <div
                            class="video-btn popup-youtube"
                            v-for="(image, imageIndex) in itmvideos"
                            :key="imageIndex"
                            @click="index = imageIndex"
                            style="cursor: pointer"
                        >
                            <i class="bx bx-play"></i>
                        </div>
                    </div>

                    <div>
                        <div class="banner-btn text-center">
                            <a href="/apply-join" class="dare-btn font-white d-mt-20 d-mr-10 d-pa-10 d-ptb-10"><i class="mdi mdi-application-edit-outline"></i> <b>링크 신청하기</b></a>
                            <a href="#" class="dare-btn bg-color-white border font-blue d-mt-20 d-ptb-10"><i class="mdi mdi-check"></i> <b>신청 결과 확인</b></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CoolLightBox from 'vue-cool-lightbox'
    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

    export default {
        name: 'ApplyLink',
        components: { 
            CoolLightBox
            },
        data: function () {
            return {
                itmvideos: [
                    {
                        src: 'https://www.youtube.com/watch?v=tu-Y9ux9M5w&list=PLIRwkyYBuoi2ygxPEELpSMDq1i7c4x3BS',
                    }
                ],
                index: null
            };
        },
    }
</script>
<template>
    <div class="contact-section ptb-50 d-qna d-apply">
        <div class="container">
            <div class="section-title">
                <h2>스마트공장 사업 신청</h2>
                <div class="bar"></div>
            </div>          
            <div class="row align-items-center">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <div class="contact-form">                        
                        <v-card
                        :loading="loading"
                        class="mb-12"
                        >
                            <iframe                                     
                                src="https://www.youtube.com/embed/7JMpmiQLES0" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen
                            ></iframe>
                        </v-card>                              
                        <div>                                
                            <v-stepper
                                v-model="stepnum"
                                vertical
                            >   
                                <Step1 :pstepnum="1" :psteptp="1" @click="stepnum = 1" @saveclick="stepnum = 2" @nextclick="stepnum = 2" @cancelClick="stepnum = 0"/> 
                                <Step2 :pstepnum="2" :psteptp="2" @click="stepnum = 2" @saveclick="stepnum = 3" @nextclick="stepnum = 3" @cancelClick="stepnum = 0"/>
                                <Step3 :pstepnum="3" :psteptp="3" @click="stepnum = 3" @saveclick="stepnum = 4" @nextclick="stepnum = 4" @cancelClick="stepnum = 0"/>
                                <Step4 :pstepnum="4" :psteptp="4" @click="stepnum = 4" @saveclick="stepnum = 5" @nextclick="stepnum = 5" @cancelClick="stepnum = 0"/>
                                <Step5 :pstepnum="5" :psteptp="5" @click="stepnum = 5" @saveclick="stepnum = 6" @nextclick="stepnum = 6" @cancelClick="stepnum = 0"/>
                                <Step6 :pstepnum="6" :psteptp="11" @click="stepnum = 6" @saveclick="stepnum = 7" @nextclick="stepnum = 7" @cancelClick="stepnum = 0" />
                                <Step7 :pstepnum="7" :psteptp="12" @click="stepnum = 7" @saveclick="stepnum = 8" @nextclick="stepnum = 8" @cancelClick="stepnum = 0" />
                                <Step8 :pstepnum="8" :psteptp="13" @click="stepnum = 8" @saveclick="stepnum = 9" @nextclick="stepnum = 9" @cancelClick="stepnum = 0" />
                                <Step9 :pstepnum="9" :psteptp="14" @click="stepnum = 9" @saveclick="stepnum = 10" @nextclick="stepnum = 10" @cancelClick="stepnum = 0" />
                                <Step10 :pstepnum="10" :psteptp="15" @click="stepnum = 10" @saveclick="stepnum = 11" @nextclick="stepnum = 11" @cancelClick="stepnum = 0" />
                                <Step11 :pstepnum="11" :psteptp="16" @click="stepnum = 11" @saveclick="stepnum = 12" @nextclick="stepnum = 12" @cancelClick="stepnum = 0" />
                                <Step12 :pstepnum="12" :psteptp="17" @click="stepnum = 12" @saveclick="stepnum = 13" @nextclick="stepnum = 13" @cancelClick="stepnum = 0" />
                                <Step13 :pstepnum="13" :psteptp="18" @click="stepnum = 13" @saveclick="stepnum = 14" @nextclick="stepnum = 14" @cancelClick="stepnum = 0" />
                                <Step14 :pstepnum="14" :psteptp="19" @click="stepnum = 14" @saveclick="stepnum = 15" @nextclick="stepnum = 15" @cancelClick="stepnum = 0" />
                                <Step15 :pstepnum="15" :psteptp="20" @click="stepnum = 15" @saveclick="stepnum = 16" @nextclick="stepnum = 16" @cancelClick="stepnum = 0" />
                                <Step16 :pstepnum="16" :psteptp="26" @click="stepnum = 16" @saveclick="stepnum = 17" @nextclick="stepnum = 17" @cancelClick="stepnum = 0"/> 
                                <Step17 :pstepnum="17" :psteptp="27" @click="stepnum = 17" @saveclick="stepnum = 18" @nextclick="stepnum = 18" @cancelClick="stepnum = 0"/> 
                                <Step18 :pstepnum="18" :psteptp="28" @click="stepnum = 18" @saveclick="stepnum = 0" @nextclick="stepnum = 0" @cancelClick="stepnum = 0"/> 

                            </v-stepper>
                        </div>                           
                        <div class="send-btn">
                            <button v-if="CLOSE_YN === 'N'" class="default-btn font-w-bold" @click="sendApplymod">                                
                                신청서 보내기
                            </button>
                        </div>                        
                    </div>
                </div>
                <div class="col-lg-2"></div>
            </div>
        </div>
    </div>
    
</template>

<script>    
    import Step1 from './StepPage/DOC_COMMON.vue';
    import Step2 from './StepPage/DOC_NM1.vue';    
    import Step3 from './StepPage/DOC_NM2.vue';
    import Step4 from './StepPage/DOC_MAJOR.vue';
    import Step5 from './StepPage/DOC_NM3.vue';
    import Step6 from './StepPage/DOC_NM16.vue';
    import Step7 from './StepPage/DOC_NM17.vue';
    import Step8 from './StepPage/DOC_NM18.vue';
    import Step9 from './StepPage/DOC_NM19.vue';
    import Step10 from './StepPage/DOC_NM20.vue';
    import Step11 from './StepPage/DOC_NM21.vue';
    import Step12 from './StepPage/DOC_NM22.vue';
    import Step13 from './StepPage/DOC_NM23.vue';
    import Step14 from './StepPage/DOC_NM24.vue';
    import Step15 from './StepPage/DOC_NM25.vue';
    import Step16 from './StepPage/DOC_NM26.vue';
    import Step17 from './StepPage/DOC_NM27.vue';
    import Step18 from './StepPage/DOC_NM28.vue';

    export default {
        components: {
            Step1,
            Step2,
            Step3,
            Step4,
            Step5,
            Step6,
            Step7,
            Step8,
            Step9,
            Step10,
            Step11,
            Step12,
            Step13,
            Step14,
            Step15,
            Step16,
            Step17,
            Step18,
        },
        name: 'ApplyMod02',
        props: {
            // pbizcd: {type:String, default:''},
            // ppwd: {type:String, default:''},
            // pcloseyn: {type:String, default:'N'},
            // pstepnum: {type:Number , default:1},
            // pdocno: {type:Number, default:1},
            // pwritedt: {type:String, default:''},            
        },     
        data() {
            return {
                // BIZ_CD: '',
                PWD: '',
                CLOSE_YN: 'N',
                stepnum: 1,
                // DOC_NO: 1,
                WRITE_DT: '',           
            };
        },
        created() {
            this.BIZ_CD = this.$route.query.pbizcd;
            this.PWD = this.$applyuser.PWD;
            this.CLOSE_YN = this.$route.query.pcloseyn;
            this.MOD_CD = this.$route.query.pmodcd;
            this.stepnum = this.$route.query.pstepnum;
            this.DOC_NO = this.$route.query.pdocno;
            this.WRITE_DT = this.$route.query.pwritedt;  
        },
        mounted() {      
            // console.log('ApplyMod02.vue >>>> this.DOC_NO : ', this.DOC_NO
            //             , ', this.BIZ_CD : ', this.BIZ_CD
            //             , ', this.MOD_CD : ', this.MOD_CD
            //             , ', this.STEP_NUM : ', this.stepnum
            //             , ', this.WRITE_DT : ', this.WRITE_DT);            
        },
        methods:{
            async sendApplymod() {
                await this.$http.get('/board/apply-send-select', {DOC_NO: this.DOC_NO, MOD_CD: this.MOD_CD}).then(async res => {

                    if(res.errorcode=="") {
                        if(res.result[0] == null || res.result[0].length == 0 )
                        {
                            // this.movePage('/apply-list?pbizcd=' + this.BIZ_CD + '&ppwd=');
                        }
                        else
                        {
                            //CONF_SEND_YN 값
                            //N.미작성내용 존재, Y.정상적으로 모두 작성함                            
                            if(res.result[0][0].CONF_SEND_YN === 'Y')
                            {
                                this.movePage('/apply-list?pbizcd=' + this.BIZ_CD);
                            }
                            else 
                            {
                                let cmf = await this.$confirm('아직 작성하지 않은 내용이 존재합니다. 확인 후 작성해주시기 바랍니다.', {
                                    title: '확인'
                                })

                                if(cmf) {
                                    this.movePage('/apply-list?pbizcd=' + this.BIZ_CD);
                                }                                    
                            }                                                      
                        }
                    }
                    else
                    {
                        alert(res.errorMsg);
                    }
                }).catch(err => {
                    alert(err)
                })
            },                         
        }
    }
</script>
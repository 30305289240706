//사용자정보
class UserInfo {
    //id
    static get ID_USER() {
        return localStorage.getItem('IU') || '';
    }

    static set ID_USER(value) {
        localStorage.setItem('IU', value);
    }

    //로그인 여부
    static get IS_LOGIN() {
        return localStorage.getItem('isLogin') || 'n';
    }

    static set IS_LOGIN(value) {
        localStorage.setItem('isLogin', value);
    }

    //자동로그인 체크 여부
    static get IS_AUTO_LOGIN() {
        return localStorage.getItem('isAutoLogin') || 'n';
    }

    static set IS_AUTO_LOGIN(value) {
        localStorage.setItem('isAutoLogin', value);
    }

    //토큰
    static get TOKEN() {
        return localStorage.getItem('token') || '';
    }

    static set TOKEN(value) {
        localStorage.setItem('token', value);
    }

    //초기화
    static Clear() {
        localStorage.setItem('isLogin', 'n');
        localStorage.setItem('token', '');
    }

}

//사업신청 사용자정보
class ApplyUserInfo {
    //id
    static get BIZ_CD() {
        return localStorage.getItem('BIZCD') || '';
    }

    static set BIZ_CD(value) {
        localStorage.setItem('BIZCD', value);
    }

    //PWD
    static get PWD() {
        return localStorage.getItem('PWD') || '';
    }

    static set PWD(value) {
        localStorage.setItem('PWD', value);
    }

    //로그인 정보 저장하기 체크 여부
    static get IS_LOGIN_SVAE() {
        return localStorage.getItem('isLoginSave') || 'n';
    }

    static set IS_LOGIN_SVAE(value) {
        localStorage.setItem('isLoginSave', value);
    }

    static get CLIENT_IP() {
        return localStorage.getItem('CLIENTIP') || '';
    }

    static set CLIENT_IP(value) {
        localStorage.setItem('CLIENTIP', value);
    }

    //초기화
    static Clear() {
        localStorage.setItem('BIZCD', '');
        localStorage.setItem('PWD', '');
    }

}

export default { UserInfo,  ApplyUserInfo}
<template>
    <div class="overview-section ptb-100 d-job-posting">
        <div class="container">
            <div class="section-title">
                <!-- <h2>제품소개</h2> -->
                <h5>다래에서 능력있는 인재를 모십니다.</h5>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div>
                        <div class="image process-content">
                            <div class="number">01</div>
                            <h3><span class="font-gray">채용마감</span><!-- <span class="font-blue">채용중</span> --><br>신입·경력</h3>
                            <router-link to="#">
                                <img src="../../assets/images/process-1.png" alt="image">
                            </router-link>

                            <div class="single-services content text-center">
                                <router-link to="#">
                                    <h4 class="d-mt-10">컨설팅 직원 모집</h4>
                                    <p class="d-mb-10">2022.04.28 ~ 채용시 마감</p>
                                </router-link>
                                <router-link to="#">
                                    <span class="dare-btn">자세히보기</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div>
                        <div class="image process-content">
                            <div class="number">02</div>
                            <h3><span class="font-gray">채용마감</span><!-- <span class="font-blue">채용중</span> --><br>신입·경력</h3>
                            <router-link to="#">
                                <img src="../../assets/images/process-2.png" alt="image">
                            </router-link>

                            <div class="single-services content text-center">
                                <router-link to="#">
                                    <h4 class="d-mt-10">개발자 모집</h4>
                                    <p class="d-mb-10">2022.02.28 ~ 채용시 마감</p>
                                </router-link>
                                <router-link to="#">
                                    <span class="dare-btn">자세히보기</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div>
                        <div class="image process-content">
                            <div class="number">03</div>
                            <h3><span class="font-gray">채용마감</span><!-- <span class="font-blue">채용중</span> --><br>신입·경력</h3>
                            <router-link to="#">
                                <img src="../../assets/images/process-3.png" alt="image">
                            </router-link>

                            <div class="single-services content text-center">
                                <router-link to="#">
                                    <h4 class="d-mt-10">컨설팅 직원 모집</h4>
                                    <p class="d-mb-10">2021.09.28 ~ 채용시 마감</p>
                                </router-link>
                                <router-link to="#">
                                    <span class="dare-btn">자세히보기</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-title pt-100">
                <h2>채용절차</h2>
                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p> -->
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="single-services">
                        <div class="icon bg-deb0fe">
                            <i class="mdi mdi-text-box-check-outline"></i>
                        </div>
                        <h3>1.서류전형</h3>
                        <p>이메일접수</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4">
                    <div class="single-services">
                        <div class="icon bg-79e8e2">
                            <i class="mdi mdi-account-tie-voice-outline"></i>
                        </div>
                        <h3>2.면접</h3>
                        <p>실무진/임원</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4">
                    <div class="single-services">
                        <div class="icon bg-fcc774">
                            <i class="mdi mdi-cellphone-message"></i>
                        </div>
                        <h3>3.합격자 통보</h3>
                        <p>개별통보</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'JobPosting'
    }
</script>
<template>

	
    <div class="about-section ptb-100">
        <div class="container">
            <div class="row">
                <div id="dboard-default-list">
                    <!-- 게시판 정보 시작 -->
                    <div class="dboard-list-header border-bottom">
                        <div class="dboard-total-count">전체 : {{totalCount}}건</div>

                        <!-- <div class="dboard-sort">
                            <form id="dboard-sort-form-5" method="get" action="/notice/">
                                <input type="hidden" name="mod" value="list"><input type="hidden" name="pageid" value="1"><input type="hidden" name="dboard_list_sort_remember" value="5">				
                                <select name="dboard_list_sort" onchange="jQuery('#dboard-sort-form-5').submit();">
                                    <option value="newest" selected="">최신순</option>
                                    <option value="best">추천순</option>
                                    <option value="viewed">조회순</option>
                                    <option value="updated">업데이트순</option>
                                </select>
                            </form>
                        </div> -->

                        <!-- 검색폼 시작 -->
												<div v-if = "this.userlogin === 'y'" class="dboard-sort dboard-search">
														<Button
															@click="movePage('/boardWrite?boardTp=' + '1')"
															color="blue-grey darken-1"
															fab
															right
															middle
															fixed
															iconName="mdi-pencil"
														>공지사항 글쓰기</Button>
														<!-- <button type="buttom" class="dboard-default-button-small" @click="movePage('/boardWrite')">공지사항 글쓰기</button> -->
												</div>

                        <div class="dboard-sort dboard-search">
                            <form id="dboard-search-form-5" method="get" action="/notice/?pageid=1">

                                <input type="hidden" name="mod" value="list"><input type="hidden" name="pageid" value="1">			
                                <select name="schType" v-model="schType">
                                    <option value="">전체</option>
																		<option value="title">제목</option>
																		<option value="modNm">분류</option>
																		<!-- <option value="docNo">글번호</option> -->
                                    <option value="writer">작성자</option>
                                </select>
                                <input type="text" name="schVal" v-model="schVal" >
																<!-- <Button
																	@click.self.prevent="getBoardList"
																	color="blue-grey darken-1"
																	rounded
																	small
																	block
																	iconName="mdi-magnify"
																>검색</Button> -->
																<!-- <a href="javascript:;" @click="getBoardList()" class="last">검색</a> -->
																<button type="submit" class="dboard-default-button-small" @click.self.prevent="getBoardList">검색</button>
                            </form>
                        </div>
                        <!-- 검색폼 끝 -->
                    </div>
                    <!-- 게시판 정보 끝 -->

                    <!-- 리스트 시작 -->
                    <div class="dboard-list">
                        <table>
                            <thead>
                                <tr>
                                    <td v-show="false" class="dboard-list-uid">No.</td>
									<td class="dboard-list-uid">분류</td>
                                    <td class="dboard-list-title">제목</td>
                                    <td class="dboard-list-user">작성자</td>
                                    <td class="dboard-list-date">작성일</td>
                                    <td class="dboard-list-view">조회</td>
									<td v-show="false" class="dboard-list-view">문서번호</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, idx) in document" :key="idx">
																	<!-- <td>{{idx}}</td> -->
																	<td v-show="false">{{row.ROWNUM}}</td>
																	<td>{{row.MOD_NM}}</td>
																	<td class="d-txt-left"><a href="javascript:;" @click="onClickRow(row.DOC_NO)">{{row.TITLE}}</a></td>
																	<td>{{row.WRITER}}</td>
																	<td>{{row.WRITE_DT.substring(0,10)}}</td>
																	<td>{{row.SEARCH_CNT}}</td>
																	<td v-show="false">{{row.DOC_NO}}</td>
																</tr>
																<tr v-if="list.length == 0">
																	<td colspan="6">데이터가 없습니다.</td>
																</tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- 리스트 끝 -->

                    <!-- 페이징 시작 -->
                    <!-- <div class="dboard-pagination" v-if="totalCount > 0">
                        <ul class="dboard-pagination-pages">
                            <li class="active"><a href="#" onclick="return false">1</a></li><li><a href="#">2</a></li><li><a href="#">3</a></li><li><a href="#">4</a></li><li><a href="#">5</a></li><li><a href="#">6</a></li><li><a href="#">7</a></li><li class="next-page"><a href="#">»</a></li><li class="last-page"><a href="#">마지막</a></li>
                        </ul>
                    </div> -->

										<div class="dboard-pagination" v-if="totalCount > 0">
											<ul class="dboard-pagination-pages">
												<a href="javascript:;" @click="fnPage(1)" class="first"><i class="mdi mdi-page-first"></i></a>
												<a href="javascript:;" v-if="this.start_page > 10" @click="fnPage(`${this.start_page-1}`)"  class="prev"><i class="mdi mdi-chevron-left"></i></a>
												<template v-for=" (n,index) in paginavigation()">
													<template v-if="paging.page==n">
														<strong :key="index">{{n}}</strong>2
													</template>
													<template v-else>
														<a href="javascript:;" @click="fnPage(`${n}`)" :key="index">&nbsp;{{n}}&nbsp;</a>
													</template>
												</template>
												<a href="javascript:;" v-if="this.total_page > this.end_page" @click="fnPage(`${this.end_page+1}`)"  class="next"><i class="mdi mdi-chevron-right"></i></a>
												<a href="javascript:;" @click="fnPage(`${this.total_page}`)" class="last"><i class="mdi mdi-page-last"></i></a>
											</ul>
										</div>

                    <!-- 페이징 끝 -->

                </div>
            </div>
        </div>

    </div>
	
	
</template>

<script>

import globalinfo from "@/utils/globalinfo";

export default {
  name: 'Board',
	components:{    
			// ContentHeader:()=>import('@/components/Control/ContentsHeader'),
	},

    mounted() {
		this.boardTp = this.$route.query.boardTp;
		this.getBoardList()
		// console.log("페이지 호출 성공")
		
        this.userlogin = globalinfo.UserInfo.IS_LOGIN; 
		// console.log(this.userlogin);
    },

	data() {
		return {
			boardTp : '',
			userlogin : '',
			headers: [
				{ text: '분류', align: 'center', value: 'DOC_NO' },
				{ text: '제목', align: 'start', value: 'TITLE' },
				{ text: '작성자', align: 'center', value: 'WRITER' },
				{ text: '작성일시', align: 'center', value: 'WRITE_DT' },
				{ text: '조회수', align: 'center', value: 'SEARCH_CNT' },
			],
			document: [],
			options: {
				multiSort: true,
				sortBy: [],
				sortDesc: [],
				page: 1,
				itemsPerPage: 5,
			},
			footerOptions: {
				'items-per-page-options': [5, 10, 25, 50, 100],
			},
			totalCount: 0,
			loading: false,
			
			conditions: [
				{ text: '글 번호', value: 'docNo' },
				{ text: '제목', value: 'title' },
				{ text: '작성자', value: 'writer' },
			],
			schType: '',
			schVal: '',

			// page:this.$route.query.page ? this.$route.query.page:1,
			ipp:10,
			block:10,
			total_page:0,
			no:'', //게시판 숫자처리
			page:1,
			paging:'', //페이징 데이터
			start_page:'', //시작페이지
			end_page:this.block, //시작페이지
			showDlg: false,
			paginavigation:function() { //페이징 처리 for문 커스텀
				var pageNumber = [];
				var start_page = this.start_page;
				var end_page = this.end_page;
				for (var i = start_page; i <= end_page; i++) pageNumber.push(i);
				return pageNumber;
			},
		}
	},

	// watch: {
	// 	options: {
	// 		handler() {
	// 			this.getBoardList()
	// 		},
	// 		deep: true,
	// 	},
	// },
	methods: {

		getBoardList() {
			const vm = this
			this.loading = true

			console.log(this.schType);
			console.log(this.schVal);

			this.$http.get('/board/board-list-select', {SCH_TYPE: this.schType, SCH_VAL: this.schVal, START_NUM: ((this.page-1) * this.block) + 1, END_NUM: this.page * this.block, BOARD_TP:'1'}).then(res => {
				if(res.errorcode=="") {
					if(res.result[0] == null || res.result[0].length == 0 )
					{         
						this.loading = false;                   
						this.$msgBox.show('조회조건을 만족하는 데이터가 없습니다.');
					}
					else
					{
						this.totalCount = res.result[1][0].TOTAL_CNT;

						setTimeout(() => {
							vm.loading = false
							this.list = res.result[0];
							this.document = res.result[0];
						}, 1000)

						this.total_page = Math.ceil(this.totalCount/this.ipp);

						// start = (page - 1) * 10;
						this.start_page = Math.ceil(this.page / this.block);
						this.end_page = this.start_page * this.block;

						if(this.total_page < this.end_page) this.end_page = this.total_page;

						this.no = this.totalCount - ((this.page-1) * this.ipp);
					}
				}
				else
				{      
					this.loading = false;                  
					alert(res.errorMsg);  
				}   
			}).catch(err => {
				this.loading = false;
				alert(err)
			})
		},
		// onClickRow(event, data) {
		// 	//조회수 업데이트
		// 	this.$http.post('/board/board-view-cnt-update', {DOC_NO: data.item.DOC_NO}).then().catch(err => {
		// 		alert(err)
		// 	})

		// 	// this.movePage('/board/BoardDetail?docNo=' + data.item.DOC_NO)
		// 	this.movePage('/boarddetail?docNo=' + data.item.DOC_NO)
		// },
		onClickRow(data) {
			console.log(data);
			//조회수 업데이트
			this.$http.post('/board/board-view-cnt-update', {DOC_NO: data}).then().catch(err => {
				alert(err)
			})

			//this.movePage('/board/BoardDetail?docNo=' + data.item.DOC_NO)
			this.movePage('/boardDetail?docNo=' + data + '&boardTp=' + '1');
		
		},

		fnPage(n) {//페이징
			if(this.page != n) {
				this.page = n;
				this.getBoardList();
			}
		},
	},
}
</script>

<style scoped>
	.dboard-pagination { margin:20px 0 0 0; text-align:center; }
	.dboard-pagination a { text-decoration:none; display:inline-blcok; position: relative; margin: 0 2px; padding:5px; width: 24px; height: 22px; font-size: 14px; color:#555; }
	.dboard-pagination a:hover, .dboard-pagination a:active { color: #1976d2; font-weight: bold; }
	.dboard-pagination .first, 
	.dboard-pagination .prev, 
	.dboard-pagination .next, 
	.dboard-pagination .last { display: inline-block; font-size: 20px; margin: 5px 0 0; color: #1976d2; }
	.dboard-pagination .first:hover, 
	.dboard-pagination .prev:hover, 
	.dboard-pagination .next:hover, 
	.dboard-pagination .last:hover { color: #000; }
	.dboard-pagination a i { display: inline-block; position: absolute; left: 0; top: 5px; margin: 0; padding: 0; } 
</style>
<template>
    <div id="ProjectsOne-Support" class="projects-section ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>지원규모</h2>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-5 col-md-5">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-79e8e2">
                            <i class="flaticon-it"></i>
                        </div>
                        <h5 class="d-pt-30">신규구축</h5>
                        <p>총 사업비의 50% 정부 지원(최대 5천만원)</p>
                        <!-- <router-link to="/single-services" class="read-btn">Read More</router-link> -->
                    </div>
                </div>

                <div class="col-lg-7 col-md-7">
                    <div class="single-services d-min-h-260">
                        <div class="icon bg-84b7fd">
                            <i class="flaticon-setting"></i>
                        </div>
                        <h5 class="d-pt-30">고도화<span class="font-blue">(구축 수준에 따라 지원금액 차등 지원)</span></h5>
                        <p>고도화1(중간1) : 총 사업비의 50% 정부 지원(최대 2억원)<br>고도화2(중간2 이상) : 총 사업비의 50% 정부 지원(최대 4억원)</p>
                        <!-- <router-link to="/single-services" class="read-btn">Read More</router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProjectsSupport'
    }
</script>
<template>
    
    <v-btn class="apply-btn" @click="downloadFiles">{{btnLabel}}</v-btn>

</template>

<script>
import fileService from '@/utils/fileService';
import urlInfo from '@/utils/urlInfo';

export default {
    props: {
        btnLabel: {default:'다운로드'},
        docNo: {default: ''},
    },
    data() {
        return {
            fileLinks: [],
            applyInfo: {DOC_NO: '',
                        PRODUCT_CD: '',
                        PRODUCT_NM: '',
                        MOD_CD: '',
                        MOD_NM: '',
                        CORP_NM: '',
                        BIZ_CD: '',
                        WRITE_DT: '',
                        CEO_NM: '',
                        PM_INFO: '',
                        PM_TEL: '',
                        EMAIL: '',
                        POST_NO: '',
                        ADDR1: '',
                        ADDR2: '',
                        BSNCDT: '',
                        ITM: '',
                        GBIZ_YN: '',
                        GBIZ_NM: '',
                        HOMEPAGE_URL: '',
                        MAJOR_CUST: '',
                        PROBLEM_IMPROVE: '',
                        JOIN_INFO: ''},
        }
    },
    mounted() {
        //테스트용 데이터
        // this.docInfo = {
        //     DOC_NO: '3',
        //     BIZ_CD: '8888888888',
        //     MOD_CD: '02',
        //     WRITE_DT: '20220808',
        //     STEP_NUM: '1',
        // }
    },
    methods: {
        downloadFiles() {
            
            //s3 url 예:  https://docusystem.s3.ap-northeast-2.amazonaws.com/dareadmin/1/step1/%EB%A1%9C%EA%B7%B8%EC%9D%B8%2C%ED%99%98%EA%B2%BD%EC%84%A4%EC%A0%95.sql

            //폴더 root / BIZDOC / 사업자번호 / 사업구분 / 신청일자
            const params = {
                DOC_NO: this.docNo,
            }

            this.$http.get(urlInfo.APPLY_DOWNLOAD_DATA,params).then(res => {
                if (res.result && res.result[0]) {
                    
                    let filePath = decodeURIComponent(res.result);

                    if(filePath) {
                        fileService.downloadFileFromLocal(filePath);
                    }

                    // this.applyInfo.DOC_NO = res.result[0].DOC_NO;            //글번호
                    // this.applyInfo.PRODUCT_CD = res.result[0].PRODUCT_CD;    //제품구분
                    // switch(res.result[0].PRODUCT_CD) {
                    //     case 'PL':
                    //         this.applyInfo.PRODUCT_NM = 'LINK';
                    //         break;
                    //     case 'PE':
                    //         this.applyInfo.PRODUCT_NM = 'ERP';
                    //         break;
                    //     case 'PI':
                    //         this.applyInfo.PRODUCT_NM = '다래 LITE(라이트)';
                    //         break;
                    // }
                    // let MOD_CD = res.result[0].MOD_CD;                   //사업구분
                    // switch(res.result[0].MOD_CD) {
                    //     case '01':
                    //         this.applyInfo.MOD_NM = '일반구매';
                    //         break;
                    //     case '02':
                    //         this.applyInfo.MOD_NM = '스마트공장';
                    //         break;
                    //     case '03':
                    //         this.applyInfo.MOD_NM = '스마트공방';
                    //         break;
                    // }
                    // this.applyInfo.CORP_NM = res.result[0].CORP_NM;        //회사명
                    // this.applyInfo.BIZ_CD = res.result[0].BIZ_CD;          //사업자등록번호
                    // this.applyInfo.WRITE_DT = res.result[0].WRITE_DT;      //신청일자
                    // this.applyInfo.CEO_NM = res.result[0].CEO_NM;          //사업자명(대표자명)
                    // this.applyInfo.PM_INFO = res.result[0].PM_INFO;        //담당자명/직함
                    // this.applyInfo.PM_TEL = res.result[0].PM_TEL;          //담당자 연락처
                    // this.applyInfo.EMAIL = res.result[0].EMAIL;            //담당자 이메일	
                    // this.applyInfo.POST_NO = res.result[0].POST_NO;        //우편번호
                    // this.applyInfo.ADDR1 = res.result[0].ADDR1;            //주소1
                    // this.applyInfo.ADDR2 = res.result[0].ADDR2;            //주소2
                    // this.applyInfo.BSNCDT = res.result[0].BSNCDT;          //업태
                    // this.applyInfo.ITM = res.result[0].ITM;                //업종
                    // this.applyInfo.GBIZ_YN = res.result[0].GBIZ_YN == '1' ? '여' : '부';        //이전정부사업진행여부
                    // this.applyInfo.GBIZ_NM = res.result[0].GBIZ_NM;        //이전 진행한 정부사업명
                    // this.applyInfo.HOMEPAGE_URL = res.result[0].HOMEPAGE_URL;  //홈페이지주소
                    // this.applyInfo.MAJOR_CUST = res.result[0].MAJOR_CUST;      //주요 거래처
                    // this.applyInfo.PROBLEM_IMPROVE = res.result[0].PROBLEM_IMPROVE; //현재의 문제점 및 개선 내용
                    // this.applyInfo.JOIN_INFO = res.result[0].JOIN_INFO;        //e나라도움 가입정보(ID/PW)
                    
                    // // let DOC_NM1 = res.result[0].DOC_NM1;            //사업자등록증(첨부파일)
                    // // let DOC_NM2 = res.result[0].DOC_NM2;            //사업자등록증명원(첨부파일)
                    // // let DOC_NM3 = res.result[0].DOC_NM3;            //회사 소개서(첨부파일)
                    // // let DOC_NM4 = res.result[0].DOC_NM4;            //사업계획서(첨부파일)
                    // // let DOC_NM5 = res.result[0].DOC_NM5;            //19~21년 부가가치세 과세 표준 증명(첨부파일)
                    // // let DOC_NM6 = res.result[0].DOC_NM6;            //21년 부가가치세 신고서(첨부파일)
                    // // let DOC_NM7 = res.result[0].DOC_NM7;            //19~21 면세 사업자 수입 금액 증명(첨부파일)
                    // // let DOC_NM8 = res.result[0].DOC_NM8;            //21년 사업장현황신고서(첨부파일)
                    // // let DOC_NM9 = res.result[0].DOC_NM9;            //주업종 영업사실 확인서(첨부파일)
                    // // let DOC_NM10 = res.result[0].DOC_NM10;          //중소기업(소상공인)확인서(첨부파일)
                    // // let DOC_NM11 = res.result[0].DOC_NM11;          //보험자격득실확인서(첨부파일)
                    // // let DOC_NM12 = res.result[0].DOC_NM12;          //월별 원천징수 이행상황 신고서(첨부파일)
                    // // let DOC_NM13 = res.result[0].DOC_NM13;          //건강보험(월별) 사업장 가입자별 부과 현황(내역)(첨부파일)
                    // // let DOC_NM14 = res.result[0].DOC_NM14;          //개인별 건강보험 고지 산출 내역(첨부파일)
                    // // let DOC_NM15 = res.result[0].DOC_NM15;          //월별 보험료 부 과내역 조회(고용,산재)(첨부파일)
                    // // let DOC_NM16 = res.result[0].DOC_NM16;          //사업신청서(첨부파일)
                    // // let DOC_NM17 = res.result[0].DOC_NM17;          //국세완납증명서(첨부파일)
                    // // let DOC_NM18 = res.result[0].DOC_NM18;          //지방세완납증명서(첨부파일)
                    // // let DOC_NM19 = res.result[0].DOC_NM19;          //고용보험 취득자 명부(첨부파일)
                    // // let DOC_NM20 = res.result[0].DOC_NM20;          //정보 활용 동의서(첨부파일)
                    // // let DOC_NM21 = res.result[0].DOC_NM21;          //완제품 사진(첨부파일)
                    // // let DOC_NM22 = res.result[0].DOC_NM22;          //공장 배치도(평면도)(첨부파일)
                    // // let DOC_NM23 = res.result[0].DOC_NM23;          //공정도 및 공정별 사진(첨부파일)
                    // // let DOC_NM24 = res.result[0].DOC_NM24;          //보유 인증서(첨부파일)
                    // // let DOC_NM25 = res.result[0].DOC_NM25;          //기술서(첨부파일)
                    

                    // this.addToFileLinks(res.result[0].DOC_NM1);
                    // this.addToFileLinks(res.result[0].DOC_NM2);
                    // this.addToFileLinks(res.result[0].DOC_NM3);
                    // this.addToFileLinks(res.result[0].DOC_NM4);
                    // this.addToFileLinks(res.result[0].DOC_NM5);
                    // this.addToFileLinks(res.result[0].DOC_NM6);
                    // this.addToFileLinks(res.result[0].DOC_NM7);
                    // this.addToFileLinks(res.result[0].DOC_NM8);
                    // this.addToFileLinks(res.result[0].DOC_NM9);
                    // this.addToFileLinks(res.result[0].DOC_NM10);
                    // this.addToFileLinks(res.result[0].DOC_NM11);
                    // this.addToFileLinks(res.result[0].DOC_NM12);
                    // this.addToFileLinks(res.result[0].DOC_NM13);
                    // this.addToFileLinks(res.result[0].DOC_NM14);
                    // this.addToFileLinks(res.result[0].DOC_NM15);
                    // this.addToFileLinks(res.result[0].DOC_NM16);
                    // this.addToFileLinks(res.result[0].DOC_NM17);
                    // this.addToFileLinks(res.result[0].DOC_NM18);
                    // this.addToFileLinks(res.result[0].DOC_NM19);
                    // this.addToFileLinks(res.result[0].DOC_NM20);
                    // this.addToFileLinks(res.result[0].DOC_NM21);
                    // this.addToFileLinks(res.result[0].DOC_NM22);
                    // this.addToFileLinks(res.result[0].DOC_NM23);
                    // this.addToFileLinks(res.result[0].DOC_NM24);
                    // this.addToFileLinks(res.result[0].DOC_NM25);
                }
            });
        },
        addToFileLinks(DOC_NM) {
            if(DOC_NM.trim() != '') {
                this.fileLinks.push({
                    filename: DOC_NM,
                    url: `https://docusystem.s3.ap-northeast-2.amazonaws.com/BIZDOC/${this.applyInfo.BIZ_CD}/${this.applyInfo.MOD_CD}/${this.applyInfo.WRITE_DT}/${DOC_NM}`
                });
            }

        },

    }
}
</script>
<style scoped>
.multi-line {
  white-space: pre-line;
}
</style>
<template>
    <div class="choose-section ptb-100 d-promotion">
        <div class="container">
            <div class="section-title">
                <h2>다래의 인재상</h2>
                <div class="bar"></div>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-4 col-md-6">
                    <div class="choose-text">
                        <div class="icon">
                            <i class="mdi mdi-finance"></i>
                        </div>
                        <h3>성장기회</h3>
                        <p>담당 영역의 전문가로 성장할 수 있는 프로그램 지원</p>
                    </div>

                    <div class="choose-text">
                        <div class="icon">
                            <i class="mdi mdi-bullseye-arrow"></i>
                        </div>
                        <h3>성취감</h3>
                        <p>능력과 적성에 맞는 직무</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="image">
                        <img src="../../assets/images/mobile.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="choose-text">
                        <div class="icon">
                            <i class="mdi mdi-scale-balance"></i>
                        </div>
                        <h3>공평한 기회</h3>
                        <p>능력과 자질을 고려한 목표수준을 정하고 공평한 기회 제공</p>
                    </div>

                    <div class="choose-text">
                        <div class="icon">
                            <i class="mdi mdi-trophy-variant-outline"></i>
                        </div>
                        <h3>합리적 보상</h3>
                        <p>평가결과에 따른 보상을 통한 동기부여</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DarePeople'
    }
</script>
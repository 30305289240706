<template>
    <div>
        <Navbar />
        <PageTitle />
        <History />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import PageTitle from '../History/PageTitle'
    import History from '../History/History'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'HistoryPage',
        components: {
            Navbar,
            PageTitle,
            History,
            Footer,
            CopyRight,
        }
    }
</script>
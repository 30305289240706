<template>
	<v-container>
		<v-card elevation="10" outlined width="100%" class="pa-2 mx-auto">
			<v-card-title class="px-4 py-2 ma-0 border-bottom">
				<span class="mr-2 primary--text">수정</span>
			</v-card-title>
			<v-card-text class="px-4 pt-3">
				<v-select
					v-model="modCd"
					:items="items"
					item-value="value"
					item-text="text"
					label="분류를 선택하세요."
				>
				</v-select>

				<v-text-field
					label="제목"
					:rules="rules"
					:counter="100"
					v-model="title"
				></v-text-field>

				<v-text-field
					label="첨부파일경로를 입력하세요."
					:counter="500"
					v-model="filepath"
				></v-text-field>

				<Editor ref="editor" />
			</v-card-text>


			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn @click="edit" color="primary">
					수정
				</v-btn>
				<v-btn v-if = "this.boardTp === '1'" @click="movePage('/boardDetail?docNo=' + docNo + '&boardTp=' + boardTp)" color="secondary">
					뒤로
				</v-btn>
				<v-btn v-if = "this.boardTp === '2'" @click="movePage('/manualDetail?docNo=' + docNo + '&boardTp=' + boardTp)" color="secondary">
					뒤로
				</v-btn>
				<!-- manualEdit -->
				<!-- <Button
					@click="edit"
					color="warning"
					rounded
					small
					iconName="mdi-pencil"
					btnName="수정"
				></Button>
				<Button
					@click="movePage('/boardDetail?docNo=' + docNo)"
					color="grey darken-1"
					rounded
					small
					iconName="mdi-arrow-left"
					btnName="뒤로"
				></Button> -->
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import Editor from '@/components/Controls/Editor'

export default {
	components: {
		Editor,
	},
	data: () => ({
		modCd: '',
		filepath:'',
        items: [
			{ value:'IP', text:'중요' },
			{ value:'NM', text:'일반' },
			{ value:'SY', text:'시스템' },
			{ value:'AC', text:'회계' },
			{ value:'HR', text:'인사' },
			{ value:'MM', text:'물류' },
			{ value:'GW', text:'그룹웨어' },
        ],
		rules: [
			value => !!value || 'Required.',
			value => (value && value.length >= 3) || 'Min 3 characters',
		],
		docNo: '',
		title: '',
		writer: '',		
		boardTp:'',
	}),
	mounted() {
		this.docNo = this.$route.query.docNo;
		this.boardTp = this.$route.query.boardTp;

		console.log(this.$route.query.docNo);
		console.log(this.$route.query.boardTp);

		this.$http.get('/board/board-detail-select', {DOC_NO: this.docNo}).then(res => {

            if(res.result && res.result.length > 0) {
				this.modCd = res.result[0][0].MOD_CD
				this.docNo = res.result[0][0].DOC_NO
				this.title = res.result[0][0].TITLE
				this.filepath = res.result[0][0].FILE_NM	
				this.$refs.editor.setContent(res.result[0][0].CONTENT)
            }
            else
            {                        
                alert(res.errorMsg);  
            }   
        }).catch(err => {
            alert(err)
        })

	},
	methods: {
		async edit() {
			var docNo = this.docNo
			var title = this.title
			var modCd = this.modCd
			var filepath = this.filepath
			var content = this.$refs.editor.getContent()
			
			let res = await this.$confirm('수정사항을 저장하시겠습니까?', {title: 'Confirm Edit'})

			if (res) {
				this.$http.post('/board/board-update', {MOD_CD : modCd, DOC_NO: docNo, TITLE: title, CONTENT: content, FILE_NM: filepath}).then(res => {

					if (res.result) {
							// this.$msgBox.show('Edit Complete');
							this.$msgBox.show('수정완료 되었습니다.');
							this.movePage('/boardDetail?docNo=' + docNo + '&boardTp=' + this.boardTp)
						}
                }).catch(err => {
                    alert(err)
                })
			}

		},
	},
}
</script>

<style></style>

<template>
    <div class="preloader">
        <div class="preloader">
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Preloader'
    }
</script>
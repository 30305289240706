<template>
    <div class="my-5">

        <v-progress-linear v-show="progressValue" v-model="progressValue" rounded></v-progress-linear>

        <!-- <v-row no-gutters justify="center" align="center">
            <v-col :cols="uploadBtn ? 11 : 12"> -->
                <!-- accept="image/*" -->
                <v-file-input
                ref="fileupload"
                show-size
                @change="selectFiles"
                @click:clear="deleteFiles"
                :placeholder="placehoder"
                hide-details
                >
                    <template v-if="uploadBtn" v-slot:append-outer>
                        <v-btn color="primary" dark small @click="uploadFiles">
                        {{btnLabel}}
                        <v-icon right dark>mdi-cloud-upload</v-icon>
                        </v-btn>
                    </template>

                </v-file-input>
                <div v-if="fileName" style="text-align:left;padding-left: 30px;">첨부파일 : <a :href="fileLink" @click="downloadFile">{{fileName}}</a> </div>
            <!-- </v-col> -->

            <!-- <v-col v-if="uploadBtn" cols="1" class="pl-2"> -->

            <!-- </v-col> -->
        <!-- </v-row> -->

    </div>

</template>

<script>
import fileService from '@/utils/fileService';
import urlInfo from '@/utils/urlInfo';

export default {
    props: {
        placehoder: {default:'첨부파일'},
        btnLabel: {default:'업로드'},
        docInfo: {default: undefined},
        stepNo: {default: ''},
        uploadBtn : {default: true},
    },
    data() {
        return {
            progressValue: 0,
            selectedFiles: undefined,
            fileCnt: 0,
            uploadSuccess: 0,
            fileName: '',
            fileLink: '',
        }
    },
    mounted() {
        //테스트용 데이터
        // this.docInfo = {
        //     DOC_NO: '3',
        //     BIZ_CD: '8888888888',
        //     MOD_CD: '02',
        //     WRITE_DT: '20220808',
        //     STEP_NUM: '1',
        // }

        this.getFileLink();
    },
    methods: {
        getFileLink() {

            //s3 url 예:  https://docusystem.s3.ap-northeast-2.amazonaws.com/dareadmin/1/step1/%EB%A1%9C%EA%B7%B8%EC%9D%B8%2C%ED%99%98%EA%B2%BD%EC%84%A4%EC%A0%95.sql

            //폴더 root / BIZDOC / 사업자번호 / 사업구분 / 신청일자
            const params = {
                DOC_NO: this.docInfo?.DOC_NO,
                STEP_NO: this.stepNo,
            }

            this.$http.get(urlInfo.DOWNLOAD_FILENAME,params).then(res => {
                if (res.result && res.result[0]) {
                    this.fileName = res.result[0].DOC_NM,
                    this.fileLink = `https://docusystem.s3.ap-northeast-2.amazonaws.com/BIZDOC/${this.docInfo?.BIZ_CD}/${this.docInfo?.MOD_CD}/${this.docInfo?.WRITE_DT}/${this.fileName}`
                    
                    // if (this.stepNo == 'docnm5' || this.stepNo == 'docnm6' || this.stepNo == 'docnm7')
                    // {
                    //     console.log(this.fileName);
                    //     console.log(this.fileLink);
                    //     console.log((this.fileName || '').length > 0);
                    // }
                    
                    this.$emit('input', (this.fileName || '').length > 0);
                }
            });
        },
        downloadFile (event) {
            if (!this.fileLink)
                return;
            event.preventDefault();
            fileService.downloadFileFromS3(this.fileLink);
        },
        selectFiles(files) {

            if(!files) {
                this.$refs.fileupload.value = this.selectedFiles;
                return;
            }
            this.progressValue = 0;
            this.selectedFiles = files;
            this.uploadSuccess = 0;
            //files.size / 1024 / 1024
            if(files.size / 1024 /1024 > 10) {
                alert('파일 사이즈가 10MB를 초과 하였습니다.');
                this.selectedFiles = undefined;
                this.$refs.fileupload.value = null;
                return;
            }
            if (Array.isArray(this.selectedFiles)) {
                this.fileCnt = files.length;
            }
            else {
                this.fileCnt = 1;
            }
        },
        deleteFiles() {
            this.progressValue = 0;
            this.selectedFiles = undefined;
            this.uploadSuccess = 0;
            this.fileCnt = 0;
            this.$emit('input', false);
        },
        async upload(file) {
            //폴더 root / BIZDOC / 사업자번호 / 사업구분 / 신청일자
            const folderInfo = {
                TYPE: 'BIZDOC',
                DOC_NO: this.docInfo?.DOC_NO,
                BIZ_CD: this.docInfo?.BIZ_CD,
                MOD_CD: this.docInfo?.MOD_CD,
                WRITE_DT: this.docInfo?.WRITE_DT,
                STEP_NUM: this.docInfo?.STEP_NUM ,
                STEP_NO: this.stepNo,
                INSERT_DT: this.$applyuser.CLIENT_IP,
            }

            //uploadFileToS3
            await fileService.uploadFileToS3(file, folderInfo, (event) => {
                this.progressValue += Math.round(100 * event.loaded / event.total / this.fileCnt);
            })
            .then((res) => {
                if(res.status == 200) {
                    this.uploadSuccess++;
                    this.fileLink = res.data?.url;
                    this.fileName = res.data?.filename;
                }
            }).catch((err) => {
                this.progressValue = 0;

                alert(err);

            });
        },
        async uploadFiles() {

            if (this.fileCnt == 0) return;
            if(!this.docInfo?.DOC_NO) {
                alert('글번호가 존재하지 않습니다.');
                return;
            }
            if(!this.docInfo?.BIZ_CD) {
                alert('사업자번호가 존재하지 않습니다.');
                return;
            }
            if(!this.docInfo?.MOD_CD) {
                alert('사업구분이 존재하지 않습니다.');
                return;
            }
            if(!this.docInfo?.WRITE_DT) {
                alert('신청일자가 존재하지 않습니다.');
                return;
            }
            if(!this.stepNo) {
                alert('STEP번호가 존재하지 않습니다.');
                return;
            }
            if(Array.isArray(this.selectedFiles))
            {
                for (let i = 0; i < this.selectedFiles.length; i++) {
                    await this.upload(this.selectedFiles[i]);
                }
            }
            else{
                await this.upload(this.selectedFiles);
            }

            //this.$emit('upload', this.uploadSuccess > 0)
            return this.uploadSuccess > 0;

        }
    }
}
</script>
<style scoped>
.multi-line {
  white-space: pre-line;
}
</style>
<template>
    <div class="choose-section ptb-100 d-download">
        <div class="container">
            <div class="section-title">
                <h2>데모프로그램 무료서비스</h2>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-8 align-center">
                    <!-- <h2>데모프로그램 다운로드</h2> -->
                    <p class="text-left d-w-b-keep-all d-mb-10 mt-12"><i class="flaticon-check font-blue font-w-bold d-mr-10"></i>프로그램 설치를 위해 Microsoft .NET Framework 4.0이 설치되어 있어야 합니다. <a class="dare-btn" href="https://www.microsoft.com/ko-kr/download/confirmation.aspx?id=17718" target="_blank">설치바로가기</a></p>
                    <p class="text-left">
                        <i class="flaticon-check font-blue font-w-bold d-mr-10"></i>
                        <span class="d-mb-10 d-w-b-keep-all">설치 과정에서 오류가 발생하거나 어려움이 있으신 경우 대표번호로 전화 주시기 바랍니다. 원격으로 프로그램 설치를 도와드립니다.</span>
                        <ul class="design-list d-mt-10">
                            <li>대표번호 : 02-6925-0995</li>
                            <li>원격지원 프로그램 <a class="dare-btn" href="http://dareinc.seetrol.com:7070/" target="_blank">설치바로가기</a></li>
                        </ul>
                    </p>
                    <p class="text-left d-mb-10 font-purple d-w-b-keep-all"><i class="flaticon-check font-purple font-w-bold d-mr-10"></i>크롬 브라우저 사용 시 다운로드가 잘 되지 않으면 Download 버튼 우클릭 후 "다른 이름으로 링크 저장" 을 해주시기 바랍니다.</p>
                </div>
                <div class="col-lg-4">
                    <div class="choose-image">
                        <img src="../../assets/images/download.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="row mt-12">
                <div class="col-lg-6">
                    <div class="choose-content d-ptb-30">
                        <div class="icon">
                            <i class="flaticon-shared-folder"></i>
                        </div>
                        <h3>스마트공방 데모버전(Demo Version)</h3>
                        <p>
                            스마트공방 데모버전 테스트계정<br>
                            User ID : daredemo01 부터 ~ 10 까지<br>
                            Password : 1234<br>
                            사업자등록번호 : 3333333333<br>
                            회사코드 : 1000<br>
                            관리자계정 : daredemoadmin
                        </p>
                        <div><a class="dare-btn" href="http://58.120.225.120:8030/www/SmartWorkShop_Setup.zip" target="_blank"><i class="flaticon-check font-white"></i> Download</a></div>
                    </div>

                </div>

                <div class="col-lg-6">
                    <div class="choose-content d-ptb-30">
                        <div class="icon">
                            <i class="flaticon-shared-folder"></i>
                        </div>
                        <h3>링크(LINK) 데모버전(Demo Version)</h3>
                        <p>
                            링크(LINK) 데모버전 테스트계정<br>
                            User ID : linkdemo01 부터 ~ 10 까지<br>
                            Password : 1234<br>
                            사업자등록번호 : 3333355555<br>
                            회사코드 : 1000<br>
                            관리자계정 : linkdemoadmin<br>
                        </p>
                        <div><a class="dare-btn" href="http://58.120.225.120:8030/www/DareLink_Setup.zip" target="_blank"><i class="flaticon-check font-white"></i> Download</a></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="choose-content d-ptb-30">
                        <div class="icon">
                            <i class="flaticon-shared-folder"></i>
                        </div>
                        <h3>다래ERP 데모버전(Demo Version)</h3>
                        <!-- <p>처음 다래를 접하는 분들을 위해 데모버전을 무료로 편하게 제공하고 있습니다. 데모프로그램을 신청하시면 별도의 계정 생성 필요없이 간단한 상담을 받으신 후 데모 계정을 메일로 보내드립니다.</p> -->
                        <p>처음 다래를 접하는 분들을 위해 데모버전을 무료로 편하게 제공하고 있습니다.<br>아래의 계정으로 로그인하여 데모프로그램 접속 할 수 있습니다.<br>프로그램 사용 문의는 02-6925-0995로 문의 바랍니다.</p>
                        <p>
                            다래ERP 데모버전 테스트계정<br>
                            User ID : demo01<br>
                            Password : 123456789D<br>                            
                            회사코드 : 1000<br>                            
                        </p>
                        <div><a class="dare-btn" href="http://58.120.225.120:8030/www/DareERP_Gold_Demo_Setup.zip" target="_blank"><i class="flaticon-check font-white"></i> Download</a></div>
                    </div>
                </div>

                <div class="col-lg-6">

                    <div class="choose-content d-ptb-30">
                        <div class="icon">
                            <i class="flaticon-shared-folder"></i>
                        </div>
                        <h3>모바일 APP</h3>
                        <p></p>
                        <div><a class="dare-btn" href="http://58.120.225.120:8030/www/dareapp.apk" target="_blank"><i class="flaticon-check font-white"></i> Download</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Download'
    }
</script>
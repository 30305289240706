<template>
    <div class="clients-section ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>다래의 조직과 구성</h2>
                <p>다래아이엔씨는 고객님께 보다 전문적이고 효율적인 서비스 제공을 위해 세분화된 조직 체계를 이루어 운영하고 있습니다.</p>
                <div class="bar"></div>
            </div>

            <div class="details-image">
                <img src="../../assets/images/about/organization-chart.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'OrganizationChart'
    }
</script>
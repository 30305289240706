<template>
    <div class="page-title-area item-bg-6">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-title-content">
                        <h2>승진제도</h2>
                        <ul>
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/contact">인재채용</router-link></li>
                            <li>승진제도</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTitle'
    }
</script>
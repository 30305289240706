import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCarousel from 'vue-carousel'
import { BootstrapVue } from 'bootstrap-vue'
import BackToTop from 'vue-backtotop'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App.vue'
import './assets/custom.scss'

import globalinfo from '@/utils/globalinfo'
import HttpHelper from "@/utils/httphelper"
import page from '@/utils/page'
import MsgBox from '@/components/Controls/MsgBox'

import { router } from './router'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import VuetifyConfirm from 'vuetify-confirm'

Vue.prototype.$http = HttpHelper;
Vue.prototype.$user = globalinfo.UserInfo;
Vue.prototype.$applyuser = globalinfo.ApplyUserInfo;

Vue.use(page)
Vue.use(MsgBox)

Vue.use(VueRouter)
Vue.use(VueCarousel)
Vue.use(BootstrapVue)
Vue.use(BackToTop)
Vue.use(VuetifyConfirm, { vuetify })

Vue.config.productionTip = false



new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
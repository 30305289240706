<template>
    <div>
        <Navbar />
        <PageTitle />
        <LocationBox />
        <Location />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import PageTitle from '../Location/PageTitle'
    import LocationBox from '../Location/LocationBox'
    import Location from '../Location/Location'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'LocationPage',
        components: {
            Navbar,
            PageTitle,
            LocationBox,
            Location,
            Footer,
            CopyRight,
        }
    }
</script>
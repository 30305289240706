<template>
    <div>
        <Navbar />
        <PageTitle />
        <Overview />
        <CreativeFeatures />
        <Composition />
        <Modules />
        <ModulesDetail />
        <ModulesValue />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import PageTitle from '../ServicesOne/PageTitle'
    import Overview from '../ServicesOne/Overview'
    import CreativeFeatures from '../ServicesOne/CreativeFeatures'
    import Composition from '../ServicesOne/Composition'
    import Modules from '../ServicesOne/Modules'
    import ModulesDetail from '../ServicesOne/ModulesDetail'
    import ModulesValue from '../ServicesOne/ModulesValue'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'ServicesOnePage',
        components: {
            Navbar,
            PageTitle,
            Overview,
            CreativeFeatures,
            Composition,
            Modules,
            ModulesDetail,
            ModulesValue,
            Footer,
            CopyRight,
        }
    }
</script>
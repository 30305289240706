<template lang="">
    <div>

    </div>
</template>
<script>
export default {
    mounted() {
        window.Kakao.init(process.env.KAKAO_JS_KEY);
        const body = {
            code: this.$route.query.code,
        }

        this.$http.postBeforeAuth('/kakao/login', body).then((res) => {
            //console.log(res);
            
            if(res) {
                window.Kakao.Auth.setAccessToken(res.access_token);
                this.$router.push({name: 'kakaoLogin', params: {id: res.id}});
            }
        });

        

    },
}
</script>
<style lang="">
    
</style>
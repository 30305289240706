<template>
    <div class="team-section bg-background ptb-100 d-home-two">
        <div class="container">
            <div class="section-title">
                <h2>주요서비스</h2>
                <div class="bar"></div>
            </div>

            <div class="row pb-100">
                <div class="col-lg-6 col-md-6">
                    <div class="team-item single-projects single-services">
                        <div class="image">
                            <div class="default-area">
                                <div class="icon bg-79e8e2">
                                    <i class="mdi mdi-factory"></i>
                                </div>
                                <h3 class="d-right">스마트 공장</h3>
                            </div>
                            <div class="projects-content social">
                                <p class="d-w-b-keep-all">제품의 기획부터 판매까지 모든 생산과정을 ICT(정보통신)기술로 통합해 최소 비용과 시간으로 고객 맞춤형제품을 생산하는 사람 중심의 첨단지능형공장사업</p>
                                <a href="/projects-one" class="dare-btn d-mt-20 d-mr-10">자세히보기</a>
                                <a href="/qna" class="dare-btn d-mt-10">상담문의</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="team-item single-projects single-services">
                        <div class="image">
                            <div class="default-area">
                                <div class="icon bg-79e8e2">
                                    <i class="mdi mdi-screwdriver"></i>
                                </div>
                                <h3 class="d-right">스마트 공방</h3>
                            </div>
                            <div class="projects-content social">
                                <p class="d-w-b-keep-all">디지털 전환 등 급속히 변화하는 제조환경에 대응하고자 소공인의 기존 수작업 위주 제조공정 개선을 정부에서 지원</p>
                                <a href="/projects-two" class="dare-btn d-mt-20 d-mr-10">자세히보기</a>
                                <a href="/qna" class="dare-btn d-mt-10">상담문의</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-title">
                <h2>주요제품</h2>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="team-item single-projects single-services">
                        <div class="image">
                            <div class="default-area">
                                <div class="d-left icon bg-79e8e2">
                                    <i class="mdi mdi-finance"></i>
                                </div>
                                <h3 class="d-right">ERP 솔루션</h3>
                            </div>
                            <div class="projects-content social">
                                <p class="d-w-b-keep-all">세분화된 메뉴 구성으로 기업을 보다 체계적·효율적으로 관리</p>
                                <a href="/services-one" class="dare-btn d-mt-20 d-mr-10">자세히보기</a>
                                <a href="/qna" class="dare-btn d-mt-20">상담문의</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="team-item single-projects single-services">
                        <div class="image">
                            <div class="default-area">
                                <div class="d-left icon bg-79e8e2">
                                    <i class="mdi mdi-responsive"></i>
                                </div>
                                <h3 class="d-right">MES</h3>
                            </div>
                            <div class="projects-content social">
                                <p class="d-w-b-keep-all">제품 생산의 전과정을 관리 할 수 있는 최적의 솔루션</p>
                                <a href="/services-two" class="dare-btn d-mt-20 d-mr-10">자세히보기</a>
                                <a href="/qna" class="dare-btn d-mt-20">상담문의</a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- SCM 숨김처리함. 20220805_이태성 -->
                <!-- <div class="col-lg-4 col-md-4">
                    <div class="team-item single-projects single-services">
                        <div class="image">
                            <div class="default-area">
                                <div class="d-left icon bg-79e8e2">
                                    <i class="mdi mdi-state-machine"></i>
                                </div>
                                <h3 class="d-right">SCM</h3>
                            </div>
                            <div class="projects-content social">
                                <p class="d-w-b-keep-all">거래관계에 있는 기업들 간의 실시간 데이터 연동 지원</p>
                                <a href="/services-three" class="dare-btn d-mt-20 d-mr-10">자세히보기</a>
                                <a href="/qna" class="dare-btn d-mt-20">상담문의</a>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-lg-6 col-md-6">
                    <div class="team-item single-projects single-services">
                        <div class="image">
                            <div class="default-area">
                                <div class="d-left icon bg-79e8e2">
                                    <i class="flaticon-setting"></i>
                                </div>
                                <h3 class="d-right">제품4</h3>
                            </div>
                            <div class="projects-content social">
                                <p>제품4 서비스 설명</p>
                                <a href="/services-four" class="dare-btn d-mt-20 d-mr-10">자세히보기</a>
                                <a href="/qna" class="dare-btn d-mt-20">상담문의</a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'Services',        
        data() {
            return {                
            };
        },
        mounted() {            
            this.getIpClient();
        },
        methods: {
            async getIpClient() {
                try {
                    const response = await axios.get('https://ipinfo.io/json');                
                    localStorage.setItem('CLIENTIP', response.data.ip);                    
                } catch (error) {
                    console.error(error);
                }
            }
        },
    }
</script>
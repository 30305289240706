<template>
    <div>
        <Navbar />
        <PageTitle />
        <Apply />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import PageTitle from '../Apply/PageTitle'
    import Apply from '../Apply/ApplyMod02'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'ApplyMod02',
        components: {
            Navbar,
            PageTitle,
            Apply,
            Footer,
            CopyRight,
        }
    }
</script>
import axios from "axios";
import globalinfo from "./globalinfo";
import urlInfo from './urlInfo';

let http = axios.create({
    headers: {
        "Accept": "*/*",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        "Pragma": "no-cache",
        'Access-Control-Allow-Origin': true,
    }

});
//let http = axios.create();
// http.defaults.baseURL = 'http://127.0.0.1:5050';
// http.defaults.baseURL = 'http://192.168.12.155:5050';
// http.defaults.baseURL = 'http://3.35.140.119:5050';

// http.defaults.baseURL = 'https://dareinc.co.kr/api';
http.defaults.baseURL = urlInfo.BASE_URL;
// const API_URL = "https://<<Public IPv4 DNS>>:5050";

const HttpHelper = {

    setHeaders() {
        http.defaults.headers['x-access-token'] = globalinfo.UserInfo.TOKEN;
    },

    setParams(params) {
        // params["MEMB_BUSNPERS_NO"] = globalinfo.UserInfo.MEMB_BUSNPERS_NO;
        // params["CORP_CODE"] = globalinfo.UserInfo.CORP_CODE;
        params["ID_USER"] = globalinfo.UserInfo.ID_USER;
    },

    postBeforeAuth(url, params, tm = 60) {

        console.log(url);
        console.log(params);
        http.defaults.timeout = 2000 * tm;

        this.setHeaders();

        return new Promise((resolve, reject) => {
            http
                .post(url, params)
                .then(response => {
                    JSON.stringify(response);
                    resolve(response.data)
                })
                .catch(error => reject(error));
        })
    },


    getBeforeAuth(url, params, tm = 60) {

        http.defaults.timeout = 2000 * tm;

        this.setHeaders();

        return new Promise((resolve, reject) => {
            http
                .get(url, { params })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    },

    post(url, params = {}, tm = 60) {

        http.defaults.timeout = 2000 * tm;

        this.setHeaders();
        this.setParams(params);

        return new Promise((resolve, reject) => {
            http
                .post(url, params)
                .then(response => {

                    if (response.data.errorcode == "ERR_TOKEN") {
                        //사용자정보 초기화
                        globalinfo.UserInfo.Clear();

                        response.data.errorcode = ''
                        resolve(response.data)

                    } else {
                        resolve(response.data);
                    }

                })
                .catch(error => {
                    reject(error)
                });
        })
    },


    get(url, params = {}, tm = 60) {

        http.defaults.timeout = 2000 * tm;

        this.setHeaders();
        this.setParams(params);

        return new Promise((resolve, reject) => {
            http
                .get(url, { params })
                .then(response => {

                    if (response.data.errorcode == "ERR_TOKEN") {
                        //사용자정보 초기화
                        globalinfo.UserInfo.Clear();

                        response.data.errorcode = ''
                        resolve(response.data)

                    } else {
                        resolve(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                });
        })
    },

}

export default HttpHelper
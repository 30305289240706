<template>
    <div class="projects-section ptb-100 d-customer">
        <div class="container">
            <div class="section-title">
                <!-- <h2>다래 주요고객사</h2> -->
                <p>다래와 항상 함께합니다</p>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.samboauto.com/index.php" target="_blank"><img src="../../assets/images/customer/customer-a-001.png" alt="image"><h5 class="border-top d-py-10 ">삼보오토주식회사</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.enzychem.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-002.png" alt="image"><h5 class="border-top d-py-10">㈜엔지켐생명과학</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://hanmitowel.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-003.png" alt="image"><h5 class="border-top d-py-10">한미타올</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.homecast.net/kr/index.asp" target="_blank"><img src="../../assets/images/customer/customer-a-004.png" alt="image"><h5 class="border-top d-py-10">홈캐스트</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://bion-tech.com/" target="_blank"><img src="../../assets/images/customer/customer-a-005.png" alt="image"><h5 class="border-top d-py-10 ">㈜바이온텍</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.sunfg.co.kr/kor/main/index.php" target="_blank"><img src="../../assets/images/customer/customer-a-006.png" alt="image"><h5 class="border-top d-py-10">㈜썬화인글로벌</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.ybsworld.com/home/" target="_blank"><img src="../../assets/images/customer/customer-a-007.png" alt="image"><h5 class="border-top d-py-10">㈜유창하이텍</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.bondwall.com/bond/index.php" target="_blank"><img src="../../assets/images/customer/customer-a-008.png" alt="image"><h5 class="border-top d-py-10">㈜비온디</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://tngsejung.com/ko/" target="_blank"><img src="../../assets/images/customer/customer-a-009.png" alt="image"><h5 class="border-top d-py-10">㈜티앤지세정</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://truwin.co.kr:6018/" target="_blank"><img src="../../assets/images/customer/customer-a-010.png" alt="image"><h5 class="border-top d-py-10">㈜트루윈</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.dwconstruction.co.kr/Mnmn_main.action" target="_blank"><img src="../../assets/images/customer/customer-a-011.png" alt="image"><h5 class="border-top d-py-10">대우산업개발주식회사</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://www.ajucos.com/default/" target="_blank"><img src="../../assets/images/customer/customer-a-012.png" alt="image"><h5 class="border-top d-py-10">㈜아주화장품</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.sdoel.com/" target="_blank"><img src="../../assets/images/customer/customer-a-013.png" alt="image"><h5 class="border-top d-py-10">삼도산업전기㈜</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://dstechno.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-014.png" alt="image"><h5 class="border-top d-py-10">㈜디에스테크노</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.jssap.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-015.png" alt="image"><h5 class="border-top d-py-10">㈜진성에스앤피</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.tksfood.co.kr/index.php" target="_blank"><img src="../../assets/images/customer/customer-a-016.png" alt="image"><h5 class="border-top d-py-10">태공식품</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.1588-3300.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-017.png" alt="image"><h5 class="border-top d-py-10">㈜장충동왕족발</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.t-robotics.net/" target="_blank"><img src="../../assets/images/customer/customer-a-018.png" alt="image"><h5 class="border-top d-py-10 ">㈜티로보틱스</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.goodfriendsfood.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-019.png" alt="image"><h5 class="border-top d-py-10">굿프랜즈</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.misoya.co.kr/index.php" target="_blank"><img src="../../assets/images/customer/customer-a-020.png" alt="image"><h5 class="border-top d-py-10">미소야</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.vitabio.net/lab/main/" target="_blank"><img src="../../assets/images/customer/customer-a-021.png" alt="image"><h5 class="border-top d-py-10">㈜비타바이오</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.marcellowheel.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-022.png" alt="image"><h5 class="border-top d-py-10">서해정밀㈜</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://www.tyfmall.co.kr/main/index.php" target="_blank"><img src="../../assets/images/customer/customer-a-023.png" alt="image"><h5 class="border-top d-py-10">㈜땡큐파머</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.ftckorea.com/" target="_blank"><img src="../../assets/images/customer/customer-a-024.png" alt="image"><h5 class="border-top d-py-10">㈜에프티씨코리아</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.xn--vh3bq2w.com/" target="_blank"><img src="../../assets/images/customer/customer-a-025.png" alt="image"><h5 class="border-top d-py-10">㈜신한방직</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://hanbang1947.co.kr/index.php" target="_blank"><img src="../../assets/images/customer/customer-a-026.png" alt="image"><h5 class="border-top d-py-10">㈜한방</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-027.png" alt="image"><h5 class="border-top d-py-10">㈜경보포리머</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-028.png" alt="image"><h5 class="border-top d-py-10">농업회사법인 써니빌㈜</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="www.littlemom.co.kr" target="_blank"><img src="../../assets/images/customer/customer-a-029.png" alt="image"><h5 class="border-top d-py-10">㈜개똥이네</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.waterway.kr/kr/index.asp" target="_blank"><img src="../../assets/images/customer/customer-a-030.png" alt="image"><h5 class="border-top d-py-10">진행워터웨이</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.hjyarn.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-031.png" alt="image"><h5 class="border-top d-py-10">㈜효정산업</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-032.png" alt="image"><h5 class="border-top d-py-10">㈜엔에스유통</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-033.png" alt="image"><h5 class="border-top d-py-10">농업회사법인 시골할매㈜</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.pnicompany.com/pnicompany/index.html" target="_blank"><img src="../../assets/images/customer/customer-a-034.png" alt="image"><h5 class="border-top d-py-10">㈜피엔아이시스템</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.datazone.co.kr/main/main.php#area1" target="_blank"><img src="../../assets/images/customer/customer-a-035.png" alt="image"><h5 class="border-top d-py-10">㈜데이타존</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://esimon.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-036.png" alt="image"><h5 class="border-top d-py-10">㈜사이몬</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://jiju.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-037.png" alt="image"><h5 class="border-top d-py-10">㈜지주</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://blog.naver.com/erimmoderns" target="_blank"><img src="../../assets/images/customer/customer-a-038.png" alt="image"><h5 class="border-top d-py-10">㈜이림모던스</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.kosii.or.kr/kosii/" target="_blank"><img src="../../assets/images/customer/customer-a-039.png" alt="image"><h5 class="border-top d-py-10">한국통계정보원</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.younsung.com/" target="_blank"><img src="../../assets/images/customer/customer-a-040.png" alt="image"><h5 class="border-top d-py-10">(주)윤성 ENG</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.changsung.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-041.png" alt="image"><h5 class="border-top d-py-10">창성 주식회사</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.haverun.com/" target="_blank"><img src="../../assets/images/customer/customer-a-042.png" alt="image"><h5 class="border-top d-py-10">헤브런</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.kwmed.co.kr/main.aspx" target="_blank"><img src="../../assets/images/customer/customer-a-043.png" alt="image"><h5 class="border-top d-py-10">㈜광우메딕스</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-044.png" alt="image"><h5 class="border-top d-py-10">MBK Entertainmaent</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://dgtek.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-045.png" alt="image"><h5 class="border-top d-py-10">디지티</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.hansungfood.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-046.png" alt="image"><h5 class="border-top d-py-10">한성푸드영농조합법인</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://www.draju.com/kor/index.php" target="_blank"><img src="../../assets/images/customer/customer-a-047.png" alt="image"><h5 class="border-top d-py-10">법무법인(유한) 대륙아주</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.ybszipper.co.kr/korean/main.htm" target="_blank"><img src="../../assets/images/customer/customer-a-048.png" alt="image"><h5 class="border-top d-py-10">㈜신세계와이비에스</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.jungwooauto.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-049.png" alt="image"><h5 class="border-top d-py-10">정우오토모티브주식회사</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.logisquare.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-050.png" alt="image"><h5 class="border-top d-py-10">㈜로지스퀘어</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-051.png" alt="image"><h5 class="border-top d-py-10">㈜이수산업</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-052.png" alt="image"><h5 class="border-top d-py-10">㈜한일공업</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-053.png" alt="image"><h5 class="border-top d-py-10">경남직물진주실크공업협동조합</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-054.png" alt="image"><h5 class="border-top d-py-10">㈜해비코</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-055.png" alt="image"><h5 class="border-top d-py-10">메탈스타㈜</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-056.png" alt="image"><h5 class="border-top d-py-10">장백프라스틱㈜</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://www.tsne.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-057.png" alt="image"><h5 class="border-top d-py-10">㈜태성에스엔이</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.db8282.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-058.png" alt="image"><h5 class="border-top d-py-10">동방물류㈜</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.hyopwoon.com/" target="_blank"><img src="../../assets/images/customer/customer-a-059.png" alt="image"><h5 class="border-top d-py-10">협운해운㈜</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://sciencemedic.com/" target="_blank"><img src="../../assets/images/customer/customer-a-060.png" alt="image"><h5 class="border-top d-py-10">사이언스메딕㈜</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.smceng.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-061.png" alt="image"><h5 class="border-top d-py-10">에스엠씨엔지니어링㈜</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.kccpr.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-062.png" alt="image"><h5 class="border-top d-py-10">케이시시정공㈜</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.wonbiogen.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-063.png" alt="image"><h5 class="border-top d-py-10">원바이오젠㈜</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.samjufs.com/home/" target="_blank"><img src="../../assets/images/customer/customer-a-064.png" alt="image"><h5 class="border-top d-py-10">삼주외식산업㈜</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://gql.co.kr/introduction" target="_blank"><img src="../../assets/images/customer/customer-a-065.png" alt="image"><h5 class="border-top d-py-10">나노쿼츠 → ㈜지큐엘</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-066.png" alt="image"><h5 class="border-top d-py-10">에스엠큐엔씨 → (주)에스큐엘</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.pncsolution.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-067.png" alt="image"><h5 class="border-top d-py-10">㈜피앤씨솔루션</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://company.fmasset.co.kr/index.jsp" target="_blank"><img src="../../assets/images/customer/customer-a-068.png" alt="image"><h5 class="border-top d-py-10">㈜에프엠에셋</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.kinteko.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-069.png" alt="image"><h5 class="border-top d-py-10">긴키테크코리아</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://purentechkorea.com/" target="_blank"><img src="../../assets/images/customer/customer-a-070.png" alt="image"><h5 class="border-top d-py-10">㈜퓨어앤텍</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.alllitelife.com/" target="_blank"><img src="../../assets/images/customer/customer-a-071.png" alt="image"><h5 class="border-top d-py-10">㈜올라이트라이프</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://pplnetworks.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-072.png" alt="image"><h5 class="border-top d-py-10">피피엘네트웍스</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://blog.naver.com/guys4" target="_blank"><img src="../../assets/images/customer/customer-a-073.png" alt="image"><h5 class="border-top d-py-10">남선씨엔델리</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#/" target="_self"><img src="../../assets/images/customer/customer-a-074.png" alt="image"><h5 class="border-top d-py-10">태흥씨푸드</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://blog.naver.com/widerack" target="_blank"><img src="../../assets/images/customer/customer-a-075.png" alt="image"><h5 class="border-top d-py-10">진성산업</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.hwasungpoc.com/index.php" target="_blank"><img src="../../assets/images/customer/customer-a-076.png" alt="image"><h5 class="border-top d-py-10">유진텍</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-077.png" alt="image"><h5 class="border-top d-py-10">일성기업</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-078.png" alt="image"><h5 class="border-top d-py-10">원창아이엠프레쉬</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-079.png" alt="image"><h5 class="border-top d-py-10">아이케미칼</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-080.png" alt="image"><h5 class="border-top d-py-10">우진테크</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-081.png" alt="image"><h5 class="border-top d-py-10">디알씨코리아</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-082.png" alt="image"><h5 class="border-top d-py-10">명진</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.remohand.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-083.png" alt="image"><h5 class="border-top d-py-10">㈜제이코</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.ky-food.kr/index.html" target="_blank"><img src="../../assets/images/customer/customer-a-084.png" alt="image"><h5 class="border-top d-py-10">㈜건영제과</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.daehwalabel.co.kr/main/main.html" target="_blank"><img src="../../assets/images/customer/customer-a-085.png" alt="image"><h5 class="border-top d-py-10">㈜대화라벨앤택스</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://www.goraesa.com/" target="_blank"><img src="../../assets/images/customer/customer-a-086.png" alt="image"><h5 class="border-top d-py-10">고래사어묵</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://daesanchemical.com/g5/" target="_blank"><img src="../../assets/images/customer/customer-a-087.png" alt="image"><h5 class="border-top d-py-10">대산화학</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-088.png" alt="image"><h5 class="border-top d-py-10">(주)삼흥기연</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.glass6272.com/kor/main/" target="_blank"><img src="../../assets/images/customer/customer-a-089.png" alt="image"><h5 class="border-top d-py-10">㈜신성씨앤씨</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.unionelecom.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-090.png" alt="image"><h5 class="border-top d-py-10">㈜유니온전자통신</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://iclio.com/" target="_blank"><img src="../../assets/images/customer/customer-a-091.png" alt="image"><h5 class="border-top d-py-10">㈜클리오</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.osungco.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-092.png" alt="image"><h5 class="border-top d-py-10">㈜오성기업</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-093.png" alt="image"><h5 class="border-top d-py-10">㈜영신기업</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://ts-ei.com/" target="_blank"><img src="../../assets/images/customer/customer-a-094.png" alt="image"><h5 class="border-top d-py-10">㈜태성환경연구소</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://www.royalmetal.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-095.png" alt="image"><h5 class="border-top d-py-10">로얄금속공업㈜</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.bareundoor.co.kr/bareun/" target="_blank"><img src="../../assets/images/customer/customer-a-096.png" alt="image"><h5 class="border-top d-py-10">바른창호㈜</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://designpark.or.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-097.png" alt="image"><h5 class="border-top d-py-10">㈜디자인파크개발</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-098.png" alt="image"><h5 class="border-top d-py-10">㈜대산테크</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://sjunitec.com/" target="_blank"><img src="../../assets/images/customer/customer-a-099.png" alt="image"><h5 class="border-top d-py-10">새진유니텍</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-100.png" alt="image"><h5 class="border-top d-py-10">대원기계</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-101.png" alt="image"><h5 class="border-top d-py-10">지원코퍼레이션</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.kssystem.co.kr/" target="_blank"><img src="../../assets/images/customer/customer-a-102.png" alt="image"><h5 class="border-top d-py-10">㈜케이에스시스템</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-103.png" alt="image"><h5 class="border-top d-py-10">일성산업</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="http://www.taeilac.com/kor/main.php" target="_blank"><img src="../../assets/images/customer/customer-a-104.png" alt="image"><h5 class="border-top d-py-10">태일</h5></a>
                    </div>
                </div>

                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="#" target="_self"><img src="../../assets/images/customer/customer-a-105.png" alt="image"><h5 class="border-top d-py-10">뉴텍엔지니어링</h5></a>
                    </div>
                </div>
                <div class="col-lg-3 d-mb-30">
                    <div class="border text-center">
                        <a href="https://kookjuan.modoo.at/" target="_blank"><img src="../../assets/images/customer/customer-a-106.png" alt="image"><h5 class="border-top d-py-10">㈜국전테크</h5></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Customer'
    }
</script>
<template>
    <div id="ProjectsTwo-Support" class="projects-section pt-100">
        <div class="container">
            <div class="section-title">
                <h2>지원규모</h2>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-2 col-md-2">

                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="single-services">
                        <div class="icon bg-84b7fd">
                            <i class="mdi mdi-account-tie"></i>
                        </div>
                        <h5 class="d-pt-30">총 사업비 70백만원 국비 49백만원(70%), 자부담 21백만원(30%)</h5>
                        <p>
                            <i class="mdi mdi-check"></i> 2022년 기준 1,250개사 지원 (업체당 국비 최대 49백만원 지원)<br>
                            <i class="mdi mdi-check"></i> 자부담 30% 중 15%는 직전년도 인건비등으로 계상 가능
                        </p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-2">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProjectsSupport'
    }
</script>
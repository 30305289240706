<template>
    <div class="audience-section ptb-100 d-list-overview">
        <div class="container">
            <div class="section-title">
                <h2>다래MES 개요</h2>
                <div class="bar"></div>
            </div>
            <div class="row">
                <div class="col-lg-5">
                    <div class="audience-content">
                        <!-- <h5>다래ERP</h5> -->
                        <!-- <div class="bar"></div> -->
                        <ul class="list d-mt-30">
                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>MES는 제품의 주문 단계에서 완성 단계까지 모든 생산 활동의 최적화를 가능하게 지원합니다.</p>
                            </li>

                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>MES는 실시간으로 정확한 데이터를 이용해 공장 내에 존재하는 모든 작업 활동을 가이드하고 착수하며 응답하고 보고합니다.</p>
                            </li>

                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>MES는 상태 변화에 대한 신속한 응답과 값어치 없는(Non-value-added) 작업 활동의 감소에 초점을 맞추어 공장 내 작업과 공정들의 효율화를 높여줍니다.</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="audience-image">
                        <img src="../../assets/images/services/services-two-1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="section-title pt-100">
                <h2>Location 관리</h2>
                <div class="bar"></div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="audience-image">
                        <img src="../../assets/images/services/services-two-2.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="section-title pt-100">
                <h2>재고 조사 관리 (실사 관리)</h2>
                <div class="bar"></div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="audience-image">
                        <img src="../../assets/images/services/services-two-3.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="section-title pt-100">
                <h2>Lot 추적 관리</h2>
                <div class="bar"></div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="audience-image">
                        <img src="../../assets/images/services/services-two-4.png" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Audience'
    }
</script>
<template>
    <div class="pt-100 d-modules">
        <div class="container">
            <div class="section-title">
                <h2>모듈별 편의성 작성</h2>
                <div class="bar"></div>
            </div>
            <div class="row align-items-top">
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>회계</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">전표 자동분개 생성, 삭제 전표 복구기능, 전표 이동/복사/동일전표 내 전표 사이 전표삽입</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">부서/프로젝트/코스트센터별 공통비 배부처리를 통한 손익, 원가관리</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">자금/예산항목 자동반영 관리, 예산편성, 조정, 집행 기능</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>인사</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">사원 등록에 등록된 사원 정보를 토대로 각종 인사 현황 제공</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">급여 자료 전월 복사  및 급여대장 E-mail 전송</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">근태 기기 연동 또는 엑셀 양식을 이용한 근태 기록 업로드</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>영업</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">견적, 수주, 매출 시 매출 단가(7가지 옵션) 자동 호출</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">LOT, SERIAL, PROJECT 별 재고 관리 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">수주 기준의 매출 판매 기능을 통한 판매 예측 및 분석 가능</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>구매/자재</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">구매품 요청,발주,매입 시 매입단가(7가지 옵션) 자동 호출</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">Bom기준 간단한 재고평가 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">원부자재 LOT추적, 이력관리 가능</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>생산</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">작업지시 라벨BARCODE를 통해 키오스크를 이용한 작업실적가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">BOM등록  기준 생산 투입 자재, 수량 자동계산 후 투입처리 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">작업지시서를 통한 실적 바코드 생성, 리딩을 통한 실적 가능</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>무역</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">구매확인서, MASTER L/C, Local L/C 등 수출,수입 관리 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">invoice/Packing List 등 출력물 제공</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">국내 발생 건, 국외 발생건 에 대한 현황 지원</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>경영</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">안정성, 수익성, 활동성, 성장성을 분석하여 도표와 그래프로 제공</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">물류의 매출/매입 데이터로 매출, 매입원가, 순위, 계획 등 분석한 도표와 그래프 제공</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">사원들의 인사관련 현황 및 급여 증감 변동 현황 제공</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>품질</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">품질관리를 통한 재고 수불 통제 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">출고,입고,실적전,실적후검사 등 세부적인 품질데이터 관리 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">수주/발주 품목,수량 기준 적용해 검사등록 가능</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>외주</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">공정기준 외주, 창고기준 외주 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">외주발주서에 대한 입출고 관리 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">LOT,PROJECT별 재고관리 가능</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>설비/금형/공구</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">설비/금형/공구 관리가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">구성품목, 설비이력 등 세부 관리 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">금형수리 관리</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>원가</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">품목기준,품목공정기준 원가 산출 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">프로젝트 기준  원가 산출 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">BOM기준 roll-up을 통한 원가 산출 가능</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>EIS</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">회계, 인사, 물류, 생산 등 정보를 기반으로 다양한 분석 데이터 산출</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">경영층의 필요한 정보를 그래프를 통한 데이터 식별 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">ERP, 웹,앱으로 제약없이 실시간 분석 데이터를 제공</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3><span>성과</span>관리</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">개인별, 부서별 KPI를 설정하여 평가</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">부서별, 개인별로 평가항목과 평가자를 다양하게 설정 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">평가자별 미평가자 내역 E-mail 전송 기능</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3>프랜차이즈</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">가맹점 미수금기준  결제통제가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">웹과 연동하여 주문관리 가능</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">배송 및 주문관리 가능</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 d-pb-50">
                    <div class="experience-inner-area">
                        <div class="experience-content">
                            <h3>그룹웨어</h3>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">연월차신청, 출장신청, 경비지출 등  ERP와 데이터 연동</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">견적, 수주, 매출/입, 매출/입반품, 구매품요청, 발주 등 전자결재를 통한 ERP 데이터 연동</p>
                        </div>

                        <div class="experience-inner-content">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <p class="d-w-b-keep-all">일정관리를 회사일정, 부서일정, 내일정 등으로 구분하여 관리</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ModulesDetail'
    }
</script>
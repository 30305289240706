<template>
    <div id="ProjectsTwo-Process" class="services-section bg-background pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>추진절차</h2>
                <div class="bar"></div>
            </div>
            <div class="row">
                <!-- 1. 스마트공방 사업 설명 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-01">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-two-step-01.png" alt="01. 스마트공방 사업 설명">
                            <!-- 동영상 재생 아이콘(페이크)
                            <div class="details-video">
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div> -->
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">10인 미만의 제조업에게 자동화 설비 및 디지털화를 지원해주는 사업</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">소개 영상 보기</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn bg-color-yellow mt-1 kakao"><img src="../../assets/images/icon-kakao-mobile.png" alt="아이콘"> 카카오플러스로 채팅 문의하기</a>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">1. 스마트공방 사업 설명</h3>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>                                
                                <div class="d-btn-group">
                                    <!-- 다래본사계정 -->
                                    <a href="http://pf.kakao.com/_Pfxfxob/chat" target="_blank" class="w-100 p-one-process-details dare-btn bg-color-yellow mt-1 kakao"><img src="../../assets/images/icon-kakao-mobile.png" alt="아이콘"> 카카오플러스로 채팅 문의하기</a>
                                </div>
                                <!-- <div class="d-btn-group">
                                    수원계정
                                    <a href="http://pf.kakao.com/_xoCRss/chat" target="_blank" class="w-100 p-one-process-details dare-btn bg-color-yellow mt-1 kakao"><img src="../../assets/images/icon-kakao-mobile.png" alt="아이콘"> 카카오플러스로 채팅 문의하기</a>
                                </div> -->                                
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 2. 예산편성 집행 안내 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-02">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-two-step-02.png" alt="02. 예산편성 집행 안내">
                            <!-- 동영상 재생 아이콘(페이크)
                            <div class="details-video">
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div> -->
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">정부지원금(예산)을 어떻게 쓸수 있는지, 예산금액은 어떻게 어느방식으로 나뉘어져 집행이 되는지 안내드리는 절차입니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">안내 영상 보기</a>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">2. 예산편성 집행 안내</h3>
                                <iframe
                                        src="https://www.youtube.com/embed/MXjcvpdydSg"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 3. 사업 신청 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-03">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-two-step-03.png" alt="03. 사업 신청">
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-red"></i></b>
                                    <p class="font-blue">필요서류를 업로드 해주시면 담당자를 배정해 사업을 신청하실 수 있도록 도움 드리겠습니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">필요서류 보기</a>
                                <a href="/apply-join" target="_self" class="w-100 dare-btn mt-1">사업신청 바로가기</a>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">3. 사업 신청</h3>
                                <iframe
                                        src="https://www.youtube.com/embed/AbFO5Lgc9a0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <a href="/apply-join?autologin=y&ppagetp=3" target="_self" class="w-100 p-one-process-details dare-btn">사업신청 바로가기</a>
                                </div>
                                <div class="mt-2 d-scroll-y">
                                    <div class="faq-accordion">
                                        <ul class="accordion" role="tablist">

                                            <b-card no-body class="accordion-item">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button class="accordion-title" block v-b-toggle.accordion-1 variant="info"><i class='bx bx-chevron-down'></i><h4 class="text-left"><i class='mdi mdi-file-outline'></i> 신청전 확인사항</h4><span></span></b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <ul class="pb-2">
                                                            <li><i class="mdi mdi-menu-right"></i> 10인 미만 제조업 한정</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 휴•폐업, 부도 업체 X</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 세금 체납(국세•지방세, 금융기관 채무불이행) X</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 참여 제한(부정 수급 및 현재 스마트화 사업을 진행 중인 업체) X</li>
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>

                                            <b-card no-body class="accordion-item mt-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button class="accordion-title" block v-b-toggle.accordion-2 variant="info"><i class='bx bx-chevron-down'></i><h4 class="text-left"><i class='mdi mdi-file-outline'></i>  필요서류</h4><span></span></b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <ul class="pb-2">
                                                            <li><i class="mdi mdi-menu-right"></i> 사업자등록증</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 회사소개서</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 최근 3년 부가가치세과세표준증명 + 최근 1년 부가가치세신고서</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 중소기업(소상공인)확인서</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 신청서</li>
                                                            <li><i class="mdi mdi-menu-right"></i> e나라도움 가입</li>

                                                            <!-- <li><i class="mdi mdi-menu-right"></i> 사업 신청서</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 국세 완납 증명서 (최근 3개월 이내 발행)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 지방세 완납 증명서 (최근 3개월 이내 발행)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 자가진단 체크리스트</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 사업계획서 작성 및 제출</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 개인(기업)정보수집이용제공동의서</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 담당세무사 정보 ( 회사명 / 이름 / 연락처 / 이메일)<br><span class="pl-2 font-size-12 font-blue"><i class="mdi mdi-chevron-double-right"></i> 시기적 제출해야하는 추가서류에 대한 대리수령</span></li>

                                                            <li class="mt-2">
                                                                <b><i class="mdi mdi-map-marker-star"></i> 소공인 확인서류 <i class="mdi mdi-numeric-1-circle font-size-15"></i></b><br>
                                                                <span class="pl-4 font-blue"><i class="mdi mdi-lightbulb-on-20 font-size-15"></i> 3개 항목 중 1개 항목 선택 제출</span><br>
                                                                <span class="pl-4"><i class="mdi mdi-numeric-1-circle-outline font-size-15"></i> 19~21년 부가가치세과세표준증명 + </span><br>
                                                                <span class="pl-8">21년 부가가치세신고서</span><br>
                                                                <span class="pl-4"><i class="mdi mdi-numeric-2-circle-outline font-size-15"></i> 19~21년 면세사업자수입금액증명 + </span><br>
                                                                <span class="pl-8">21년 사업장현황신고서</span><br>
                                                                <span class="pl-4"><i class="mdi mdi-numeric-3-circle-outline font-size-15"></i> 22년에 창업하여 위 서류제출이 불가할 경우 </span><br>
                                                                <span class="pl-8">[붙임4] 주업종 영업사실 확인서를 작성하여</span><br>
                                                                <span class="pl-8">제출</span>
                                                            </li>

                                                            <li class="mt-2">
                                                                <b><i class="mdi mdi-map-marker-star"></i> 소공인 확인서류 <i class="mdi mdi-numeric-2-circle font-size-15"></i></b><br>
                                                                <i class="mdi mdi-menu-right"></i><span class="pl-1">중소기업(소성공인)확인서<br><span class="pl-4">(신청 마감일 기준 유효기간이 만료되지 않아야함)</span></span>
                                                            </li>

                                                            <li class="mt-2">
                                                                <b><i class="mdi mdi-map-marker-star"></i> 소공인 확인서류 <i class="mdi mdi-numeric-3-circle font-size-15"></i></b><br>
                                                                <i class="mdi mdi-menu-right"></i> 상시근로자가 없는 경우 :<br><span class="pl-4">보험자격득실확인서 (1개월 이내분)</span>
                                                            </li>

                                                            <li class="mt-1">
                                                                <i class="mdi mdi-menu-right"></i> 상시근로자가 있는 경우 :<br><span class="pl-3 font-blue"><i class="mdi mdi-lightbulb-on-20 font-size-15"></i> 아래 항목중 1개 선택 제출</span><br>
                                                                <span class="pl-3 font-green"><i class="mdi mdi-multiplication"></i>21년 월별 전체 발급하되, 업력이 12개월 미만인</span><br><span class="pl-6 font-green">경우 개업일부터 신청일까지 제출</span><br>
                                                                <span class="pl-3 font-green"><i class="mdi mdi-multiplication"></i>단, 직전연도 업력이 12개월을 넘지 못하는 경우</span><br><span class="pl-6 font-green">최근 12개월분 제출</span><br>
                                                                <span class="pl-4"><i class="mdi mdi-numeric-1-circle-outline font-size-15"></i> 월별 원천징수 이행상황신고서</span><br>
                                                                <span class="pl-4"><i class="mdi mdi-numeric-2-circle-outline font-size-15"></i> 건강보험(월별)사업장가입자별부과현황(내역)</span><br>
                                                                <span class="pl-4"><i class="mdi mdi-numeric-3-circle-outline font-size-15"></i> 개인별건강보험고지산출내역</span><br>
                                                                <span class="pl-4"><i class="mdi mdi-numeric-4-circle-outline font-size-15"></i> 월별보험료부과내역조회(고용, 산재)</span>
                                                            </li>
                                                            <li class="mt-2"><i class="mdi mdi-menu-right"></i> e나라도움 가입정보<br><span class="pl-3">도입기업에서 회원가입 후 ID/PW 공유 받아야함</span><br><span class="pl-3">사업신청 대리신청용.</span></li>
                                                            <li><i class="mdi mdi-menu-right"></i> 생산완제품 이미지 및 설명(소개)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 생산공정도 및 각 공정별 이미지와 설명</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 공장 평면도 1장 / 평면도에 설치하려는 <br><span class="pl-3">설비 표시 1장</span></li>
                                                            <li><i class="mdi mdi-menu-right"></i> 도입하려는 설비 카달로그 또는 이미지와 설명</li>
                                                            <li><i class="mdi mdi-menu-right"></i> KPI 지표 2가지 선택</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 도입기업 사용인감</li> -->
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>

                                            <b-card no-body class="accordion-item mt-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button class="accordion-title" block v-b-toggle.accordion-3 variant="info"><i class='bx bx-chevron-down'></i><h4 class="text-left"><i class='mdi mdi-file-outline'></i>  사업계획서 작성사항</h4><span></span></b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <ul class="pb-2">
                                                            <li><i class="mdi mdi-menu-right"></i> 회사소개</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 생산완제품 이미지 및 설명(소개)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 생산공정도 및 각 공정별 이미지와 설명</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 공장 평면도 1장 / 평면도에 설치하려는 설비 표시 1장</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 도입하려는 설비 카달로그 또는 이미지와 설명</li>
                                                            <li><i class="mdi mdi-menu-right"></i> KPI 지표 중 제품 생산/불량 수량</li>
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 4. 사업계획서 작성 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-04">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-two-step-04.png" alt="04. 사업계획서 작성">
                            <!-- 동영상 재생 아이콘(페이크)
                            <div class="details-video">
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div> -->
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">스마트 공방 사업 신청을 위한 사업계획서 작성 단계입니다.<br>하단의 샘플을 다운로드 하신 후 가이드 영상을 보시면서 작성하시고 업로드 해주시면 됩니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">가이드 영상보기</a>
                                <!-- <a href="http://58.120.225.120:8030/www/apply/2023_스마트공방_샘플_사업계획서.hwp" target="_self" class="w-100 p-one-process-details dare-btn mt-1">사업계획서 샘플 다운</a> -->
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1" :href="fileLink" @click="downloadFile($event, '04')">사업계획서 샘플 다운</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">사업계획서 작성하기</a>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">4. 사업계획서 작성</h3>
                                <iframe
                                        src="https://www.youtube.com/embed/IcwBsCX7FuM"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <!-- <a href="http://58.120.225.120:8030/www/apply/2023_스마트공방_샘플_사업계획서.hwp" target="_blank" class="w-100 p-one-process-details dare-btn">사업계획서 샘플 다운</a> -->
                                    <a target="_blank" class="w-100 p-one-process-details dare-btn" :href="fileLink" @click="downloadFile($event, '04')">사업계획서 샘플 다운</a>
                                    <a href="/apply-join?autologin=y&ppagetp=4" target="_self" class="w-100 p-one-process-details dare-btn mt-1">사업계획서 작성하기</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 5. 서류 심사 통화 여부 전달 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps Two-Step-05">
                        <div class="projects-image">
                            <img src="../../assets/images/projects/p-two-step-05.png" alt="05. 서류 심사 통화 여부 전달">
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="flaticon-tick"></i></b>
                                    <p>1차 서류 결과 발표 후 통과 여부에 관한 문자 메시지가 전송이 되고 답신을 달라고 합니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-gray"></i></b>
                                    <p>통과 여부에 관한 문자를 담당자에게 전달해주시면 답신 문자 내용을 보내실 수 있도록 전달해드릴 것입니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a class="w-100 p-one-process-details dare-btn" @click="mgs()">서류 심사 합격 전달</a>
                                <a class="w-100 p-one-process-details dare-btn mt-1" @click="mgs()">서류 심사 불합격 및 재접수 요청</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 6. 발표 평가 준비 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-06">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-two-step-06.png" alt="06. 발표 평가 준비">
                            <!-- 동영상 재생 아이콘(페이크)
                            <div class="details-video">
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div> -->
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">작성된 사업계획서를 읽으면 발표하는 형태로 진행이 되며 발표 방법 및 예상 질문 영상을 보시고 준비를 해 주시면 됩니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-red"></i></b>
                                    <p class="font-blue">부가적 도움이 필요하시면 발표도움요청 버튼을 누르시면 담당자를 통하여 발표 준비를 도움드리겠습니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">발표 사업계획서 요청</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">발표 준비 영상보기</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">발표 도움 요청</a>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">6. 발표 평가 준비</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction1.png" alt="2023년 1차 서류 통화 후 관련 영상을 올려드리겠습니다."></div>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <a class="w-100 p-one-process-details dare-btn" @click="mgs()">발표 사업계획서 요청</a>
                                    <a class="w-100 p-one-process-details dare-btn mt-1" @click="mgs()">발표 도움 요청</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 7. 최종 선정 여부 전달 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps Two-Step-07">
                        <div class="projects-image">
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-two-step-07.png" alt="07. 최종 선정 여부 전달">
                            </div>
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="flaticon-tick"></i></b>
                                    <p>선정 이후 사업 진행을 위해서 선정 여부 전달을 담당자에게 해주셔야 합니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a class="w-100 p-one-process-details dare-btn" @click="mgs()">최종 선정</a>
                                <a class="w-100 p-one-process-details dare-btn mt-1" @click="mgs()">미선정 및 재접수 요청</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 8. 협약서 작성 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-08">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-two-step-08.png" alt="08. 협약서 작성">
                            <!-- 동영상 재생 아이콘(페이크)
                            <div class="details-video">
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div> -->
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">본 사업을 진행하기 위해 필요한 서류를 작성 및 준비해 주시는 단계입니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-red"></i></b>
                                    <p class="font-blue">이메일에 온 업무협약서 및 기타 서류를 작성하셔서 업로드 해주시면 됩니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a href="#" target="_self" class="w-100 p-one-process-details dare-btn">필요서류 작성 방법 영상보기</a>
                                <a href="#" target="_self" class="w-100 p-one-process-details dare-btn mt-1">필요서류 업로드</a>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">8. 협약서 작성</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction1.png" alt="2023년 1차 서류 통화 후 관련 영상을 올려드리겠습니다."></div>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <a class="w-100 p-one-process-details dare-btn" @click="mgs()">필요서류 업로드</a>
                                </div>
                                <h4 class="text-left mt-1"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-3">
                                    <i class="mdi mdi-menu-right"></i> 업무협약서 및 기타 서류
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 9. 사업계획서 변경 및 1, 2차 위원 방문 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps Two-Step-09">
                        <div class="projects-image">
                            <img src="../../assets/images/projects/p-two-step-09.png" alt="09. 사업계획서 변경 및 1, 2차 위원 방문">
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="flaticon-tick"></i></b>
                                    <p>본 사업 진행을 위해 협회에서 사업계획서 변경 및 사업진행을 위해 위원을 배정해 방문하는 단계입니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-gray"></i></b>
                                    <p>위원 방문 일정이 정해지셨다면 방문 일정 및 위원 연락처를 기입해 주셔야 차질 없이 본 사업을 진행해 드릴 수 있습니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a class="w-100 p-one-process-details dare-btn" @click="mgs()">담당 위원 연락처 및 방문일자 남기기</a>
                            </div>
                        </div>
                        <!-- <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">8. 사업계획서 변경 및 1, 2차 위원 방문</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction1.png" alt="2023년 1차 서류 통화 후 관련 영상을 올려드리겠습니다."></div>
                                <div class="form-group mt-3">
                                    <label for="input-name"><h5 class="font-w-normal">세무담당자 연락처</h5></label>
                                    <input type="phone" name="phone" id="input-phone" v-model="phone" class="form-control" required placeholder="전화번호 '-'생략" autocomplete="off">
                                    <label for="input-name"><h5 class="font-w-normal mt-3">방문일자</h5></label>
                                    <input type="date" name="date" id="input-date" v-model="date" class="form-control" required placeholder="2022/09/26">
                                    <label for="input-name"><h5 class="font-w-normal mt-3">방문시간</h5></label>
                                    <input type="time" name="time" id="input-time" v-model="time" class="form-control" required placeholder="2022/09/26">
                                </div>
                                <div class="d-btn-group mt-1">
                                    <a href="#" target="_self" class="w-100 p-one-process-details dare-btn">저장하기</a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!-- 10. 1차 교부 신청 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-10">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-two-step-10.png" alt="10. 1차 교부 신청">
                            <!-- 동영상 재생 아이콘(페이크)
                            <div class="details-video">
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div> -->
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">본 사업의 예산을 받는 부분입니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-red"></i></b>
                                    <p class="font-blue">이메일로 온 필요서류를 영상과 같이 작성하셔서 보내주시고 샘플 계약서와 견적서, 비교견적서를 다온로드 하셔서 공급업체와 작성 후 업로드 해주시면 됩니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">교부신청 영상보기</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">샘플 서류 다운로드</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">필요서류 업로드</a>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">10. 1차 교부 신청</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction1.png" alt="2023년 1차 서류 통화 후 관련 영상을 올려드리겠습니다."></div>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <a class="w-100 p-one-process-details dare-btn" @click="mgs()">샘플 서류 다운로드</a>
                                    <a class="w-100 p-one-process-details dare-btn mt-1" @click="mgs()">필요서류 업로드</a>
                                </div>
                                <h4 class="text-left mt-1"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-3">
                                    <i class="mdi mdi-menu-right"></i> 샘플 계약서와 견적서, 비교견적서
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 11. 1차 예산 집행 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-11">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-two-step-11.png" alt="11. 1차 예산 집행">
                            <!-- 동영상 재생 아이콘(페이크)
                            <div class="details-video">
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div> -->
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">교부 완료 된 1차 예산(70%)를 공급업체에게 집행하기 위한 계산서 발행과 집행을 위한 공인인증서 및 이체 비밀번호를 설정하는 단계입니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-red"></i></b>
                                    <p class="font-blue">영상을 참조하셔서 계산서 발행과 집행을 해주시면 됩니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">집행 준비 영상보기</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">발행 계산서 업로드</a>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">11. 1차 예산 집행</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction1.png" alt="2023년 1차 서류 통화 후 관련 영상을 올려드리겠습니다."></div>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <a class="w-100 p-one-process-details dare-btn" @click="mgs()">발행 계산서 업로드</a>
                                </div>
                                <!-- <div class="d-scroll-y">
                                    <h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                    <p class="pl-2">
                                        <i class="mdi mdi-menu-right"></i> 업무협약서<br>
                                        <i class="mdi mdi-menu-right"></i> 자부담금 확약서<br>
                                        <i class="mdi mdi-menu-right"></i> 소공인서약서<br>
                                        <i class="mdi mdi-menu-right"></i> 국고보조금신청서<br>
                                        <i class="mdi mdi-menu-right"></i> 다래공방용솔루션견적서<br>
                                        <i class="mdi mdi-menu-right"></i> 솔루션 비교견적서<br>
                                        <i class="mdi mdi-menu-right"></i> 다래솔루션 계약서<br>
                                        <i class="mdi mdi-menu-right"></i> 다래 통장사본<br>
                                        <i class="mdi mdi-menu-right"></i> 다래 사업자등록증<br>
                                        <i class="mdi mdi-menu-right"></i> 솔루션계산서 발행 준비 (교부신청이 완료<br><span class="pl-4">(최대7일소요)되는 즉시 발행 후 집행)</span><br>
                                        <i class="mdi mdi-menu-right"></i> 설비업체 견적서<br>
                                        <i class="mdi mdi-menu-right"></i> 설비업체 비교견적서<br>
                                        <i class="mdi mdi-menu-right"></i> 설비업체 계약서<br>
                                        <i class="mdi mdi-menu-right"></i> 설비업체 통장사본<br>
                                        <i class="mdi mdi-menu-right"></i> 설비업체 사업자등록증<br>
                                        <i class="mdi mdi-menu-right"></i> 설비업체계산서 발행 준비 (교부신청이 완료<br><span class="pl-4">(최대7일소요)되는 즉시 발행 후 집행)</span>
                                    </p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 12. 다래공방용 프로그램 배포 및 설치안내 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-12">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-two-step-12.png" alt="12. 다래 공방용 프로그램 배포 및 설치안내">
                            <!-- 동영상 재생 아이콘(페이크)
                            <div class="details-video">
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div> -->
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">다래 프로그램 설치와 기초자료를 준비해주시는 단계입니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-red"></i></b>
                                    <p class="font-blue">영상을 보시면서 프로그램 설치와 기초자료를 작성하셔서 업로드 해주시면 됩니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">프로그램설치 & 기초자료등록 영상보기</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">프로그램 및 기초자료 샘플 다운</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">기초자료 업로드</a>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">12. 다래공방용 프로그램 배포 및 설치안내</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction1.png" alt="2023년 1차 서류 통화 후 관련 영상을 올려드리겠습니다."></div>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <a class="w-100 p-one-process-details dare-btn" @click="mgs()">프로그램 및 기초자료 샘플 다운</a>
                                    <a class="w-100 p-one-process-details dare-btn mt-1" @click="mgs()">기초자료 업로드</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 13. 중간보고 및 3,4차 위원 방문 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-13">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-two-step-13.png" alt="13. 중간보고 및 3,4차 위원 방문">
                            <!-- 동영상 재생 아이콘(페이크)
                            <div class="details-video">
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div> -->
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">본 사업의 중간점검을 하는 단계입니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-red"></i></b>
                                    <p class="font-blue">중간보고 가이드영상을 보신 후 검수조서 및 설비에 관련된 부분을 담당자에게 알려주시고 담당 위원과 대표자의 도장(사인)이 찍힌 완료된 중간보고서를 업로드 해주시면 됩니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">중간보고 가이드 영상보기</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">검수조서 샘플 다운로드</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">검수조서 및 중간보고서 업로드</a>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">13. 중간보고 및 3,4차 위원 방문</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction1.png" alt="2023년 1차 서류 통화 후 관련 영상을 올려드리겠습니다."></div>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <a class="w-100 p-one-process-details dare-btn" @click="mgs()">검수조서 샘플 다운로드</a>
                                    <a class="w-100 p-one-process-details dare-btn mt-1" @click="mgs()">검수조서 및 중간보고서 업로드</a>
                                </div>
                                <!-- <div class="d-scroll-y">
                                    <h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                    <p class="pl-2">
                                        <i class="mdi mdi-menu-right"></i> 업무협약서<br>
                                        <i class="mdi mdi-menu-right"></i> 자부담금 확약서<br>
                                        <i class="mdi mdi-menu-right"></i> 소공인서약서<br>
                                        <i class="mdi mdi-menu-right"></i> 국고보조금신청서<br>
                                        <i class="mdi mdi-menu-right"></i> 다래공방용솔루션견적서<br>
                                        <i class="mdi mdi-menu-right"></i> 솔루션 비교견적서<br>
                                        <i class="mdi mdi-menu-right"></i> 다래솔루션 계약서<br>
                                        <i class="mdi mdi-menu-right"></i> 다래 통장사본<br>
                                        <i class="mdi mdi-menu-right"></i> 다래 사업자등록증<br>
                                        <i class="mdi mdi-menu-right"></i> 솔루션계산서 발행 (교부신청이 완료(최대<br><span class="pl-4">7일소요)되는 즉시 발행 후 집행)</span><br>
                                        <i class="mdi mdi-menu-right"></i> 설비업체 견적서<br>
                                        <i class="mdi mdi-menu-right"></i> 설비업체 비교견적서<br>
                                        <i class="mdi mdi-menu-right"></i> 설비업체 계약서<br>
                                        <i class="mdi mdi-menu-right"></i> 설비업체 통장사본<br>
                                        <i class="mdi mdi-menu-right"></i> 설비업체 사업자등록증<br>
                                        <i class="mdi mdi-menu-right"></i> 설비업체계산서 발행 (교부신청이 완료(최대<br><span class="pl-4">7일소요)되는 즉시 발행 후 집행)</span>
                                    </p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 14. 2차 교부 및 집행 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-14">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-two-step-14.png" alt="14. 2차 교부 및 집행">
                            <!-- 동영상 재생 아이콘(페이크)
                            <div class="details-video">
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div> -->
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">2차 교부와 집행을 진행하는 단계입니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-red"></i></b>
                                    <p class="font-blue">이메일로 온 2차 교부신청서 작성과 나머지 30%의 잔금 계산서 발행 후 업로드 해주시고 가이드 영상에 따라 집행해 주시면 됩니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">2차 교부 및 집행 가이드 영상보기</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">2차 교부 신청서 및 세금계산서 업로드</a>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">14. 2차 교부 및 집행</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction1.png" alt="2023년 1차 서류 통화 후 관련 영상을 올려드리겠습니다."></div>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <a class="w-100 p-one-process-details dare-btn" @click="mgs()">2차 교부 신청서 및 세금계산서 업로드</a>
                                </div>
                                <!-- <h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-3">
                                    <i class="mdi mdi-menu-right"></i> 변경 관련 공문 (도입기업 직인 필요)<br>
                                    <i class="mdi mdi-menu-right"></i> 협약 변경 신청서 (도입기업 직인 필요)<br>
                                    <i class="mdi mdi-menu-right"></i> 회의록(도입기업 직인 필요)<br>
                                    <i class="mdi mdi-menu-right"></i> 변경사업계획서<br>
                                    <i class="mdi mdi-menu-right"></i> 견적서(하드웨어 변경 시)
                                </p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 15. 결과보고서 제출 및 KPI지표 연동 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-15">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-two-step-15.png" alt="15. 결과보고서 제출 및 KPI지표 연동">
                            <!-- 동영상 재생 아이콘(페이크)
                            <div class="details-video">
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div> -->
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="flaticon-tick"></i></b>
                                    <p class="font-blue">결과보고서를 제출하는 단계입니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-gray"></i></b>
                                    <p class="font-blue">산출된 핵심성과지표(KPI)와 결과보고에 관련된 부분의 이메일을 담당자에게 전달해주시고 결과 점검 일정을 남겨주시면 도움드리겠습니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a class="w-100 p-one-process-details dare-btn d-w-b-keep-all" @click="mgs()">점검위원 연락처 및 점검일 남기기</a>
                            </div>
                        </div>
                        <!-- <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">14. 결과보고서 제출 및 KPI지표 연동</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction1.png" alt="2023년 1차 서류 통화 후 관련 영상을 올려드리겠습니다."></div>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">2차 교부 신청서 및 세금계산서 업로드하기</a>
                                </div>
                                <h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-3">
                                    <i class="mdi mdi-menu-right"></i> 변경 관련 공문 (도입기업 직인 필요)<br>
                                    <i class="mdi mdi-menu-right"></i> 협약 변경 신청서 (도입기업 직인 필요)<br>
                                    <i class="mdi mdi-menu-right"></i> 회의록(도입기업 직인 필요)<br>
                                    <i class="mdi mdi-menu-right"></i> 변경사업계획서<br>
                                    <i class="mdi mdi-menu-right"></i> 견적서(하드웨어 변경 시)
                                </p>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!-- 16. 최종 매출 관련 서류올리기 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-16">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-two-step-16.png" alt="16. 최종 매출 관련 서류올리기">
                            <!-- 동영상 재생 아이콘(페이크)
                            <div class="details-video">
                                <div
                                    class="video-btn popup-youtube"
                                    style="cursor: pointer"
                                >
                                    <i class="bx bx-play"></i>
                                </div>
                            </div> -->
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">본 사업의 마지막 단계입니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-red"></i></b>
                                    <p class="font-blue">사업 진행 년도의 재무제표 혹은 사업소득 증빙 서류를 업로드 해주시면 됩니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">최종 서류 업로드 가이드 영상보기</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">최종 매출 관련 서류 업로드</a>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">16. 최종 매출 관련 서류올리기</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction1.png" alt="2023년 1차 서류 통화 후 관련 영상을 올려드리겠습니다."></div>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <a class="w-100 p-one-process-details dare-btn" @click="mgs()">최종 매출 관련 서류 업로드</a>
                                </div>
                                <!-- <h4 class="text-left"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-3">
                                    <i class="mdi mdi-menu-right"></i> 변경 관련 공문 (도입기업 직인 필요)<br>
                                    <i class="mdi mdi-menu-right"></i> 협약 변경 신청서 (도입기업 직인 필요)<br>
                                    <i class="mdi mdi-menu-right"></i> 회의록(도입기업 직인 필요)<br>
                                    <i class="mdi mdi-menu-right"></i> 변경사업계획서<br>
                                    <i class="mdi mdi-menu-right"></i> 견적서(하드웨어 변경 시)
                                </p> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import fileService from '@/utils/fileService';

    export default {
        name: 'ProjectsProcess',
        data() {
            return {
                fileName: '',
                fileLink: '',
            }
        },
        mounted() {
            this.getIpClient();
        },
        methods: {
            mgs() {
                this.$msgBox.show('준비중입니다.');
            },
            async getIpClient() {
                try {
                    const response = await axios.get('https://ipinfo.io/json');
                    localStorage.setItem('CLIENTIP', response.data.ip);
                    // console.log('HomeTwo > Services.vue > this.$applyuser.CLIENT_IP : ', this.$applyuser.CLIENT_IP);
                } catch (error) {
                    console.error(error);
                }
            },
            downloadFile (event, step) {
                this.fileName = '';
                this.fileLink = 'https://docusystem.s3.ap-northeast-2.amazonaws.com/BIZDOC/0000000000/03/00000000/';

                if(step == '04') {
                    this.fileName = '2023_스마트공방_샘플_사업계획서.hwp';
                }
                this.fileLink = this.fileLink + this.fileName;
                event.preventDefault();
                fileService.downloadFileFromS3(this.fileLink);
            },
        },
    }
</script>
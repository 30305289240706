<template>
    <div class="contact-section ptb-100 d-qna">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="contact-text text-center">
                        <!-- <h3>Have Any Questions About Us?</h3> -->
                        <p>궁금한 부분은 1:1문의사항을 통해 문의해주시길 바랍니다.</p>
                    </div>

                    <div class="contact-form">
                        <form ref="form" @submit.prevent="sendEmail">
                            <div class="form-group">
                                <label>상담분류 <span class="font-blue"> *상담유형</span>은 필수항목입니다</label>
                                <div>                                    
                                    <select name="qna" v-model="qna" class="form-control" @change="changedSelect($event)">
                                        <option disabled value="">상담유형선택</option>
                                        <option value="제품">제품</option>
                                        <option value="정부지원사업">정부지원사업</option>
                                        <option value="견적문의">견적문의</option>
                                    </select>                                
                                </div>
                                <input v-show="false" type="text"  name="txtqna" id="msg_txtqna" v-model="txtqna" class="form-control"  required placeholder="상담유형">
                            </div>

                            <div class="form-group">
                                <label for="input-coname">회사명 <span class="font-blue"> *회사명</span>을 입력해주세요</label>
                                <input type="text" name="company" id="input-coname" v-model="company" class="form-control" required placeholder="회사명">
                            </div>

                            <div class="form-group">
                                <label for="input-name">이름 <span class="font-blue"> *성함</span>을 입력해주세요</label>
                                <input type="text" name="name" id="input-name" v-model="name" class="form-control" required placeholder="이름">
                            </div>

                            <div class="form-group">
                                <label for="input-email">이메일 <span class="font-blue"> *메일</span>을 입력해주세요</label>
                                <input type="email" name="email" id="input-email" v-model="email" class="form-control" required placeholder="Your Email">
                                <div class="d-mt-10">
                                    <form id="">		
                                        <select name="mailtail" v-model="mailtail" class="form-control"  @change="changedSelectMail($event)">
                                            <option value="">전체입력</option>
                                            <option value="@gmail.com">@gmail.com</option>
                                            <option value="@naver.com">@naver.com</option>
                                            <option value="@nate.com">@nate.com</option>
                                        </select>
                                    </form>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="input-tel">전화번호를 입력해주세요</label>
                                <input type="text" name="tel" id="input-tel" v-model="tel" class="form-control" required placeholder="전화번호 '-'생략">
                            </div>

                            <div class="form-group">
                                <label for="input-mobile">휴대폰번호를 입력해주세요</label>
                                <input type="text" name="mobile" id="input-mobile" v-model="mobile" class="form-control" required placeholder="휴대폰번호 '-'생략">
                            </div>

                            <!-- <div class="form-group">
                                <label for="input-file">첨부파일(용량제한:10MB)</label>
                                <input type="file" name="myfile" id="input-file" class="form-control" required placeholder="첨부하실 파일이 있으시면 첨부해주세요.">
                            </div> -->

                            <div class="form-group">
                                <label for="qna-message">문의사항</label>
                                <textarea name="message" class="form-control" id="qna-message" v-model="message" cols="30" rows="6" required placeholder="문의사항작성"></textarea>
                            </div>

                            <div class="send-btn">
                                <button type="submit" class="default-btn">
                                    문의사항 보내기
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="contact-image ">
                        <img src="../../assets/images/contact.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import emailjs from '@emailjs/browser'

    export default {
        name: 'Qna',
        data() {
            return {
                txtqna : '',
                company : '',
                name : '',
                tel : '',
                email : '',
                subject : '',
                txtmodule : '',
                message : '',
                qna : '',
                mailtail : ''
            };
        },

        methods:{
            //EmailJs 사용하여 메일 전송하기
            sendEmail() {                        
                // https://dashboard.emailjs.com/admin 사이트에서 계정정보와 서비스 템플릿 등을 세팅한다.
                emailjs.sendForm('service_8zlc9re', 'template_y003owi', this.$refs.form, 'gRB0-nuhjqlnyQ4-D')
                .then((result) => {
                    console.log('SUCCESS!', result.text);
                    alert("(주)다래아이엔씨 관리자에게 1:1문의가 전송되었습니다.");
                    this.controlClear();
                }, (error) => {
                    alert("1:1문의가 정상적으로 전송되지 않았습니다.");
                    console.log('FAILED...', error.text);
                });
            },
            //입력 컨트롤 초기화
            controlClear(){
                this.company = '',
                this.name = '',
                this.email = '',
                this.tel = '',
                this.mobile = '',           
                this.subject = '',                
                this.message = '',
                this.qna = '',
                this.mailtail = ''
            },
            //상담유형 선택 값 담기
            changedSelect(event){
                this.txtqna = event.target.value;
            },            
            changedSelectMail(event)
            {
                let str = this.email;

                if(str.indexOf('@') >= 0 )                
                    this.email = str.substr(0, str.indexOf('@'));                    
                
                this.email = this.email + event.target.value;    
            },
        }
    }
</script>
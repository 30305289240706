<template>
    <div>
        <Navbar />
        <PageTitle />
        <ProjectsAbout />
        <ProjectsSupport />
        <ProjectsDocument />
        <ProjectsProcess />
        <ProjectsCase />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import PageTitle from '../ProjectsThree/PageTitle'
    import ProjectsAbout from '../ProjectsThree/ProjectsAbout'
    import ProjectsSupport from '../ProjectsThree/ProjectsSupport'
    import ProjectsDocument from '../ProjectsThree/ProjectsDocument'
    import ProjectsProcess from '../ProjectsThree/ProjectsProcess'
    import ProjectsCase from '../ProjectsThree/ProjectsCase'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'ProjectsPageThree',
        components: {
            Navbar,
            PageTitle,
            ProjectsAbout,
            ProjectsSupport,
            ProjectsDocument,
            ProjectsProcess,
            ProjectsCase,
            Footer,
            CopyRight,
        }
    }
</script>
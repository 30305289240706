<template>
    <div class="audience-section ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>다래 SCM이란?</h2>
                <p>원재료의 생산·유통 등 모든 공급망 단계를 최적화해 원하는 제품을 원하는 시간과 장소에 제공하여  부품 공급업체와 생산업체 그리고 고객에 이르기까지 거래관계에 있는 기업들 간  실시간 정보공유를 통해 시장이나 수요자들의 요구에 기민하게 대응토록 지원하는 것이다.</p>
                <div class="bar"></div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="audience-image">
                        <img src="../../assets/images/services/services-three.png" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Audience'
    }
</script>
<template>
    <div class="app-section ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>지원내용</h2>
                <div class="bar"></div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="data-image">
                        <img src="../../assets/images/data.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6">

                    <div class="app-inner-text">
                        <div class="icon">
                            <i class="flaticon-cloud-computing"></i>
                        </div>
                        <h3>소공인</h3>
                        <p>상시근로자수 10인 미만 제조업 지원</p>
                    </div>

                    <div class="app-inner-text">
                        <div class="icon">
                            <i class="flaticon-laptop"></i>
                        </div>
                        <h3>스마트 컨설팅</h3>
                        <p>스마트 전문가 매칭(기술·공정, 시장진단, 사업화 등) 컨설팅 지원</p>
                    </div>

                    <div class="app-inner-text">
                        <div class="icon">
                            <i class="flaticon-it"></i>
                        </div>
                        <h3>연구장비·재료비</h3>
                        <p>스마트공방 구축 S/W·연구시설·장비 등 임차비<br>스마트기술 도입 관련 H/W 부품 등 재료비</p>
                    </div>

                    <div class="app-inner-text">
                        <div class="icon">
                            <i class="flaticon-technical-support"></i>
                        </div>
                        <h3>위탁개발비</h3>
                        <p>스마트공방 구축 SW·공정·제품 개발 등 용역비</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProjectsDetails'
    }
</script>
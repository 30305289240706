<template>
    <div class="footer-section pt-100 pb-70 d-footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 d-about-us">
                    <div class="single-footer-widget">
                        <div class="footer-heading">
                            <h3>About Us</h3>
                        </div>

                        <div class="footer-info-contact">
                            <i class="flaticon-checked"></i>
                            <h3>주식회사 다래아이엔씨</h3>
                            <span>대표 : 김용호, 개인정보책임자 : 김용호</span>
                        </div>

                        <div class="footer-info-contact">
                            <i class="flaticon-checked"></i>
                            <h3>사업자등록번호</h3>
                            <span>107-87-76928</span>
                        </div>

                        <div class="footer-info-contact">
                            <i class="flaticon-checked"></i>
                            <h3>통신판매업신고</h3>
                            <span>제2021-서울영등포-0997호</span>
                        </div>

                        <ul class="footer-social">
                            <li>
                                <a href="https://www.facebook.com/profile.php?id=100069416554984" target="_blank">
                                    <i class="flaticon-facebook"></i>
                                </a>
                            </li>
                            <!-- <li>
                                <a href="#" target="_blank">
                                    <i class="flaticon-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class="flaticon-pinterest"></i>
                                </a>
                            </li> -->
                            <li>
                                <a href="https://www.instagram.com/companydareinc/" target="_blank">
                                    <i class="flaticon-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <div class="footer-heading">
                            <h3>Contact</h3>
                        </div>

                        <div class="footer-info-contact">
                            <i class="flaticon-happy"></i>
                            <h3>제품구매문의</h3>
                            <span><a href="tel:0269250995">02-6925-0995</a></span>
                            <!-- <span><a href="tel:16610098">1661-0098</a></span> -->
                        </div>

                        <div class="footer-info-contact">
                            <i class="flaticon-phone-call"></i>
                            <h3>Phone</h3>
                            <span>대표 : <a href="tel:0269250995">02-6925-0995</a>, 팩스 : 02-2672-0995</span>
                        </div>

                        <div class="footer-info-contact">
                            <i class="flaticon-envelope"></i>
                            <h3>Email</h3>
                            <span><a href="mailto:dareinc2012@gmail.com">dareinc2012@gmail.com</a></span>
                        </div>

                        <div class="footer-info-contact">
                            <i class="flaticon-pin"></i>
                            <h3>Address</h3>
                            <span>도로명 : 서울특별시 영등포구 경인로 775, 1동 1509호(우:07229)</span><br>
                            <span>지번 : 서울특별시 영등포구 문래동 3가, 에이스하이테크시티 1동 1509호</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>
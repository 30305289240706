<template>
    <div class="page-title-area item-bg-4">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-title-content">
                        <h2>자주하는 질문</h2>
                        <ul>
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/notice">고객센터</router-link></li>
                            <li>자주하는 질문</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTitle'
    }
</script>
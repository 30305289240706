<template>
    <div>
        <Navbar />
        <PageTitle />
        <Board />
        <!-- <Notice /> -->
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import PageTitle from '../Notice/PageTitle'
    import Board from '../Board/BoardList'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'
    // import Notice from '../Notice/Notice.vue'

    export default {
        name: 'NoticePage',
        components: {
        Navbar,
        PageTitle,
        Board,
        // Notice,
        Footer,
        CopyRight,
    }
    }
</script>
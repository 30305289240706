<template>
    <div class="clients-section pt-100">
        <div class="container">
            <div class="section-title">
                <h2>사업개요</h2>
                <p>제조 현장의 경쟁력 제고를 위해 중소·중견기업을 대상으로 <br />국내 현실에 적합한 다양한 형태의 스마트공장 구축·고도화 지원</p>
                <div class="bar"></div>
            </div>

            <div class="details-image">
                <img src="../../assets/images/projects/projects-one.jpg" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProjectsAbout'
    }
</script>
<template>
    <div class="clients-section pt-100">
        <div class="container">
            <div class="section-title">
                <h2>사업개요</h2>
                <p>소공인의 수작업 위주 제조공정에 기초 스마트기술(IoT, AI 등) 맞춤 접목을 통해 디지털화 지원</p>
                <div class="bar"></div>
            </div>

            <div class="details-image">
                <img src="../../assets/images/projects/projects-two-1.jpg" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProjectsAbout'
    }
</script>
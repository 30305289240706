<template>
    <div>
        <Navbar />
        <AccPageTitle />
        <AccountResetting />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import AccPageTitle from '../LogIn/AccPageTitle'
    import AccountResetting from '../LogIn/AccountResetting'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'AccountResettingPage',
        components: {
            Navbar,
            AccPageTitle,
            AccountResetting,
            Footer,
            CopyRight,
        }
    }
</script>
<template>
    <div class="audience-section ptb-100 d-business">
        <div class="container">
            <div class="section-title">
                <h2>신청서 REPORT</h2>
                <div class="bar"></div>                
            </div>

            <div>
                <v-row>
                    <v-col clos="4">
                        <v-text-field
                            v-model="FROM_DT"
                            label="조회시작일"                    
                            placeholder="년월일8자리"                    
                        ></v-text-field>
                    </v-col>
                    <v-col clos="4">
                        <v-text-field
                            v-model="TO_DT"
                            label="조회종료일"
                            placeholder="년월일8자리"
                        ></v-text-field>
                    </v-col>                  
                    <v-col cols="4">
                        <v-btn @click="getApplySelect()" >
                    <i class="mdi mdi-archive-edit-outline"></i> <b>조회하기</b>
                    </v-btn>
                    </v-col> 
                </v-row>                  
            </div>            
            <div>
                <v-row>
                    <v-col cols="12">
                        <div class="section-title">                            
                            <h1 class="font-blue">제품구분별</h1>
                            <div class="bar"></div>                
                        </div>
                        <div>
                            <ECharts :options="pieOptionsP" :auto-resize="true"></ECharts> 
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="section-title">                            
                            <h1 class="font-blue">구매방법별</h1>
                            <div class="bar"></div>                
                        </div>
                        <div>
                            <ECharts :options="pieOptionsM" :auto-resize="true"></ECharts> 
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="section-title">                            
                            <h1 class="font-blue">완료/미완료</h1>
                            <div class="bar"></div>                
                        </div>
                        <div>
                            <ECharts :options="pieOptionsC" :auto-resize="true"></ECharts> 
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    import ECharts from 'vue-echarts'

    export default {
        name: 'ApplyReport',
        components: {
            ECharts,
        },
        data() {
            return {
                FROM_DT: '',        //조회기간FROM [필수]
                TO_DT: '',          //조회기간TO [필수]
                
                //제품구분별 차트
                pieOptionsP : {
                    tooltip: {},
                    legend: {},
                    toolbox: {
                        show: true,                        
                    },
                    dataset: {
                        source: [ ]
                    },
                    series: [
                        {                            
                            type: 'pie',
                            radius: '70%',
                            label: { formatter: '{b}:  {d} %' },
                            color: ['#ca8622', '#2f4554', '#61a0a8'],
                        }
                    ]    
                },

                //구매방법별 차트
                pieOptionsM : {
                    tooltip: {},
                    legend: {},
                    toolbox: {
                        show: true,                        
                    },
                    dataset: {
                        source: [ ]
                    },
                    series: [
                        {                            
                            type: 'pie',
                            radius: '70%',
                            label: { formatter: '{b}:  {d} %' },
                            color: ['#ca8622', '#2f4554', '#61a0a8'],
                        }
                    ]    
                },
                
                //완료/미완료
                pieOptionsC : {
                    tooltip: {},
                    legend: {},
                    toolbox: {
                        show: true,                        
                    },
                    dataset: {
                        source: [ ]
                    },
                    series: [
                        {                            
                            type: 'pie',
                            radius: '70%',
                            label: { formatter: '{b}:  {d} %' },
                            color: ['#2f4554', '#c23531'],
                        }
                    ]    
                }
            };
        },
        mounted() {
            if (this.$user.ID_USER != 'dareadmin')
            {
                this.$msgBox.show('관리자만 접근 가능한 페이지 입니다.');
                this.movePage('/apply-join');
                return;
            }
            
            let today = new Date();         
            this.FROM_DT = today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2) + '01';
            this.TO_DT = today.getFullYear() + ('0' + (today.getMonth() + 1)).slice(-2) + ('0' + today.getDate()).slice(-2);
            
            this.getApplySelect();
        },
        methods: {
            //모바일 여부 모바일:true, PC:false
            isMobile() {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            },
            //차트용 데이터 조회
            async getApplySelect() {
                if((this.FROM_DT || '') === '' && (this.TO_DT || '') === '') {
                    this.$msgBox.show('조회시작일과 조회종료일을 입력하세요.');
                    return;
                }

                if((this.FROM_DT.length || '') != 8 || (this.TO_DT.length || '') != 8) {
                    this.$msgBox.show('년월일 8자리를 입력하세요.');
                    return;
                }

                await this.$http.get('/board/apply-report-select', {
                                                                        FROM_DT: this.FROM_DT.replaceAll('-',''), 
                                                                        TO_DT: this.TO_DT.replaceAll('-',''),                                                                         
                                                                    }).then(res => {                    
                    if(res.errorcode=="") {
                        if(res.result[0] == null || res.result[0].length == 0 )
                        {                               
                            // this.APPLYDATA = [];
                            this.pieOptionsP.dataset.source = [{}];
                            this.pieOptionsM.dataset.source = [{}];
                            this.pieOptionsC.dataset.source = [{}];
                            this.$msgBox.show('조회조건을 만족하는 데이터가 없습니다.');
                        }
                        else
                        {                             
                            // this.APPLYDATA = res.result[0];
                            this.pieOptionsP.dataset.source = res.result[0];
                            this.pieOptionsM.dataset.source = res.result[1];
                            this.pieOptionsC.dataset.source = res.result[2];
                        }
                    }
                    else
                    {               
                        alert(res.errorMsg);  
                    }   
                }).catch(err => {
                    this.loading = false;
                    alert(err)
                })
            },  
        },
    }
</script>
<template>
    <div class="about-section ptb-100 d-list-overview">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 p-0">
                    <div class="audience-image">
                        <img src="../../assets/images/about/about-2.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="about-tab pt-0">
                        <h2>다래의 발자취</h2>
                        <p>다래는 변화와 도전을 멈추지 않고 고객과 함께 성공과 발전을 이루어가고 있습니다.</p>
                        <div class="bar"></div>

                        <div class="about-list-tab">
                            <b-tabs content-class="mt-3">
                                <b-tab title="2022~2016">
                                    <div class="tabs_item">
                                        <ul class="list">
                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2020 / 12 </span> 스마트공장 구축 지원사업 추진 및 구축 완료(약 70여 업체)</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2020 / 10 </span> 3차원측정기 연동 시스템 개발</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2019 / 09 </span> 인사평가관리, 성과측정관리(KPI)솔루션 개발</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2019 / 03 </span> 베트남 회계 패키지프로그램 개발 착수</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2018 / 12 </span> NC설비 유·무선 데이터 게더링 및 시스템 ERP 연동</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2018 / 05 </span> PDA, Mobile 솔루션 개발</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2017 / 09 </span> 그룹웨어 솔루션 개발</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2017 / 03 </span> ERP + MES 통합스마트제조 솔루션 개발</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2016 / 10 </span> 초중종물관리 시스템 개발</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2016 / 07 </span> 자동차부품산업진흥재단(KAP) 공급업체 등록</p>
                                            </li>
                                        </ul>

                                        <!-- <a class="default-btn" href="#">
                                            Discover More
                                        </a> -->
                                    </div>
                                </b-tab>
                                <b-tab title="2015~2012">
                                    <div class="tabs_item">
                                        <ul class="list">
                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2015 / 11 </span> 스마트빌 전자세금계산서 협업솔루션 개발 및 공급을 위한 MOU 체결</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2015 / 11 </span> 산자부 스마트공장추진단 스마트공장사업 공급업체 등록</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2015 / 09 </span> 중소기업기술정보진흥원 경영혁신플랫폼 사업자선정 및 솔루션 공급</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2015 / 03 </span> 한국사이버결제(KCP)와 프랜차이즈 ERP 협업에 관한 MOU 체결</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2014 / 11 </span> 프랜차이즈 ERP 출시</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2013 / 12 </span> 다래 표준형 ERP 출시</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2013 / 07 </span> Smart-Tax 자동 장부시스템 출시</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2012 / 12 </span> 기업부설 연구소 설립</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2012 / 09 </span> 다래ERP 소프트웨어 등록, 한국세무사회 전산감리시스템 개발 공급</p>
                                            </li>

                                            <li>
                                                <b><i class="flaticon-tick"></i></b>
                                                <p><span class="font-blue">2012 / 08 </span> 주식회사 다래아이엔씨 법인설립</p>
                                            </li>
                                        </ul>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'History'
    }
</script>
<template>
    <div class="blog-section pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>도입 후 개선 및 성과</h2>
                <p>스마트공장 도입 후 경영 및 공정개선 성과</p>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="single-blog">
                        <div class="image">
                            <router-link to="/single-blog">
                                <img src="../../assets/images/projects/projects-one-2.jpg" alt="image">
                            </router-link>
                        </div>

                        <!-- <div class="content">
                            <span>20 March 2020</span>
                            <h3>
                                <router-link to="/single-blog">
                                    Great Tips To Earn More Money From Digital Industry
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                            <router-link to="/single-blog" class="read-more">Read More</router-link>
                        </div> -->
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="single-blog">
                        <div class="image">
                            <router-link to="/single-blog">
                                <img src="../../assets/images/projects/projects-one-3.jpg" alt="image">
                            </router-link>
                        </div>

                        <!-- <div class="content">
                            <span>25 April 2020</span>
                            <h3>
                                <router-link to="/single-blog">
                                    The Billionaire Guide On Design That will Get You Rich
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                            <router-link to="/single-blog" class="read-more">Read More</router-link>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Performance'
    }
</script>
<template>
    <div class="about-section ptb-100">
        <div class="container">
            <div class="row">
                <div id="dboard-document">
                    <div id="dboard-default-document">
                        <div class="dboard-document-wrap" itemscope="" itemtype="http://schema.org/Article">
                            <div class="dboard-title" itemprop="name">
                            <h1>[모집] 다래아이엔씨 경력직 정규직원 채용공고</h1>
                            </div>
                        
                            <div class="dboard-detail">
                                <div class="detail-attr detail-writer">
                                    <div class="detail-name">작성자</div>
                                    <div class="detail-value">다래 컨설팅</div>
                                </div>
                                <div class="detail-attr detail-date">
                                    <div class="detail-name">작성일</div>
                                    <div class="detail-value">2022-01-27 16:01</div>
                                </div>
                                <div class="detail-attr detail-view">
                                    <div class="detail-name">조회</div>
                                    <div class="detail-value">16150</div>
                                </div>
                            </div>
                        
                            <div class="dboard-content" itemprop="description">
                                <div class="content-view">
                                    <div><a href="https://valzo-vue.envytheme.com/img/faq.2257d457.png" alt="recruit_erpDev1.png" border="0"></a></div>
                                </div>
                            </div>
                        
                            <!-- <div class="dboard-document-action">
                                <div class="left">
                                    <button type="button" class="dboard-button-action dboard-button-like" onclick="dboard_document_like(this)" data-uid="359" title="좋아요">좋아요 <span class="dboard-document-like-count">0</span></button>
                                    <button type="button" class="dboard-button-action dboard-button-unlike" onclick="dboard_document_unlike(this)" data-uid="359" title="싫어요">싫어요 <span class="dboard-document-unlike-count">0</span></button>
                                </div>
                                <div class="right">
                                    <button type="button" class="dboard-button-action dboard-button-print" onclick="dboard_document_print('https://www.ksystem.co.kr/?action=dboard_document_print&amp;uid=359')" title="인쇄">인쇄</button>
                                </div>
                            </div> -->
                        
                        </div>
                        
                        <div class="dboard-document-navi">
                            <div class="dboard-prev-document">
                                <a href="#" title="2021년 스마트공장구축 지원사업 안내">
                                    <span class="navi-arrow">«</span>
                                    <span class="navi-document-title dboard-default-cut-strings">2021년 스마트공장구축 지원사업 안내</span>
                                </a>
                            </div>
                            
                            <div class="dboard-next-document">
                                <a href="#" title="[공지] 스마트공장 보급 확산 사업안내">
                                    <span class="navi-document-title dboard-default-cut-strings">[공지] 스마트공장 보급 확산 사업안내</span>
                                    <span class="navi-arrow">»</span>
                                </a>
                            </div>
                        </div>

                        <div class="dboard-control banner-btn">
                            <a href="/notice" class="default-btn">목록보기</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NoticeView'
    }
</script>
<template lang="">
     <div class="col-lg-12">
        <div class="send-btn text-center">
            <b-button squared variant="outline-secondary" @click="kakaoLogout" class="w-50 dare-btn mr-1 d-btn-admin"><i class="mdi mdi-account-cog-outline"></i> 카카오로그아웃</b-button>
        </div>
    </div>
</template>
<script>

export default {
    mounted() {
        window.Kakao.init(process.env.KAKAO_JS_KEY);

    },
    methods: {
        async kakaoLogout() {

            // const accessToken = window.Kakao.Auth.getAccessToken();
            // console.log(accessToken);
            // if(accessToken) {

                // window.Kakao.API.request({
                //         url: '/v1/user/unlink',
                //     })
                //     .then((response) => {
                //         console.log(response);
                //         this.$router.push({path:'/user-login'})
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //     });

                window.Kakao.Auth.logout().then()
                .catch(err => console.log(err))
                .finally(() => {
                    
                    this.$router.push({path:'/user-login'})
                });

            //}
        }
    }
}
</script>
<style lang="">
    
</style>
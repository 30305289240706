import msgBoxVue from './MsgBox.vue';

const MsgBox = {};


MsgBox.install = function(Vue) {

    const MsgBoxInstance = Vue.extend(msgBoxVue);

    let msgBoxComponent;

    const initInstance = () => {

        msgBoxComponent = new MsgBoxInstance();

        let msgBoxEl = msgBoxComponent.$mount().$el;

        //document.body.append(msgBoxEl);

        document.getElementById('app').append(msgBoxEl);

    }

    Vue.prototype.$msgBox = {
        show(msg, title) {

            if (!msgBoxComponent) {
                initInstance();
            }

            msgBoxComponent.showMbox = true;

            if (title) {
                msgBoxComponent.title = title;
            }

            msgBoxComponent.msg = msg;

            return msgBoxComponent
        }
    }
}

export default MsgBox;
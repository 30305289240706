<template>
    <div class="main-banner-area-two">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="main-banner-content">
                                <h1>기업에 최적화된<br>프로세스통합 솔루션<br><span class="font-blue">다래 스마트제조솔루션</span></h1>
                                <p class="text-left d-mt-50"><b><i class="flaticon-check font-blue"></i></b> 시스템통합을 통해서 비즈니스 효율 및 경쟁력을 강화합니다.</p>
                                <p class="text-left d-mt-10"><b><i class="flaticon-check font-blue"></i></b> 만들어진 시스템을 유지보수하여 정상적인 기능을 수행하도록 합니다.</p>
                                <p class="text-left d-mt-10"><b><i class="flaticon-check font-blue"></i></b> 다양한 솔루션 응용 및 개선 작업을 통해서 더 나은 솔루션을 개발합니다.</p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="banner-image">
                                <img src="../../assets/images/home-two/home-two-1.png" alt="image">
                                <img src="../../assets/images/home-two/home-two-2.png" alt="image">
                                <img src="../../assets/images/home-two/home-two-3.png" alt="image">
                                <img src="../../assets/images/home-two/home-two-4.png" alt="image">
                                <img src="../../assets/images/home-two/home-two-5.png" alt="image">
                                <img src="../../assets/images/home-two/home-two.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-shape">
            <img src="../../assets/images/home-two/cloud.png" alt="image">
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Banner'
    }
</script>
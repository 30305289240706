<template>
    <div class="audience-section ptb-100 d-business">
        <div class="container">
            <div class="section-title">
                <h2>작성내역</h2>
                <div class="bar"></div>
                <h1 class="font-blue" v-show="EXISTS_YN == 'N'">작성된 신청서가 없습니다.</h1>
            </div>
            <div class="row pb-100 d-write-history" v-show="EXISTS_YN == 'Y'">
                <p>※ 미완료된 사업신청은 “신청하기” 버튼을 통해서 수정할 수 있습니다.</p>
                <div>
                    <table hover small caption-top responsive class="text-center" style="width:100%" >
                            <thead>
                                <tr>
                                    <td style="width:5%">NO</td>
                                    <td style="width:11%">작성일자</td>
                                    <td style="width:13%">제품구분</td>
                                    <td style="width:12%">신청구분</td>
                                    <td style="width:10%">완료여부</td>
                                    <td style="width:12%">추진절차<br>보러가기</td>
                                    <td style="width:20%">상호명</td>
                                    <td style="width:17%">사업자번호</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in APPLYDATA" :key="i">
                                    <td>{{item.NUM}}</td>
                                    <td>{{setDateFormat('date', item.WRITE_DT)}}</td>
                                    <td>{{item.PRODUCT_NM}}</td>
                                    <td>{{item.MOD_NM}}</td>
                                    <td class="font-blue">{{item.CLOSE_YN == 'Y'? '완료':'미완료'}}</td>
                                    <td>
                                        <!-- <v-btn v-show="item.CLOSE_YN == 'Y' && item.MOD_CD == '01'" class="apply-btn result-btn"><a href="#">추진절차</a></v-btn>
                                        <v-btn v-show="item.CLOSE_YN == 'Y' && item.MOD_CD == '02'" class="apply-btn result-btn"><a href="/projects-one#ProjectsOne-Process">추진절차</a></v-btn> -->
                                        <v-btn v-show="item.CLOSE_YN == 'Y' && item.MOD_CD == '03'" class="apply-btn result-btn"><a href="/projects-two#ProjectsTwo-Process">추진절차</a></v-btn>
                                        <!-- <v-btn v-show="item.CLOSE_YN == 'Y'" @click="goPage(item.MOD_CD)" class="apply-btn">추진절차</v-btn> -->
                                        <v-btn v-show="item.CLOSE_YN == 'N' && item.MOD_CD == '03'" @click="goApplyMod(item.DOC_NO, item.PRODUCT_CD, item.MOD_CD, item.WRITE_DT, item.CLOSE_YN, item.STEP_NUM)" class="apply-btn">신청하기</v-btn>
                                    </td>
                                    <td>{{item.CORP_NM}}</td>
                                    <td>{{setDateFormat('bizcd', item.BIZ_CD)}}</td>
                                </tr>
                            </tbody>
                    </table>
                </div>
            </div>
            <div class="default-shape">
                <div class="shape-1">
                    <img src="../../assets/images/shapes/shape-4.png" alt="image">
                </div>

                <div class="shape-2 rotateme">
                    <img src="../../assets/images/shapes/shape-5.svg" alt="image">
                </div>

                <div class="shape-3">
                    <img src="../../assets/images/shapes/shape-6.svg" alt="image">
                </div>

                <div class="shape-4">
                    <img src="../../assets/images/shapes/shape-7.png" alt="image">
                </div>

                <div class="shape-5">
                    <img src="../../assets/images/shapes/shape-8.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ApplyResult',
        components: {

        },
        data() {
            return {
                BIZ_CD: '',
                PWD: '',
                EXISTS_YN : 'N',
                APPLYDATA : [],
                PAGE_TP: '', //페이지를 구분을 나누어 시기마다 보여지는 스탭을 다르게 하기 위함.
            };
        },
        mounted() {
            this.BIZ_CD = this.$route.query.pbizcd;
            this.PWD = this.$applyuser.PWD;

            if(this.$route.query.ppagetp == '')
                this.PAGE_TP = '3';
            else
                this.PAGE_TP = this.$route.query.ppagetp;

            this.getApplySelect();
        },
        methods: {
            //모바일 여부 모바일:true, PC:false
            isMobile() {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            },
            //작성된 신청서 조회
            async getApplySelect() {
                await this.$http.get('/board/apply-list-select', {BIZ_CD: this.BIZ_CD, PWD: this.PWD}).then(res => {
                    // console.log('ApplyResult.vue >>>> res.result[0] : ', res.result[0]);
                    if(res.errorcode=="") {
                        if(res.result[0] == null || res.result[0].length == 0 )
                        {
                            this.EXISTS_YN = 'N';
                            // this.$msgBox.show('조회조건을 만족하는 데이터가 없습니다.');
                        }
                        else
                        {
                            this.EXISTS_YN = 'Y';
                            this.APPLYDATA = res.result[0];
                        }
                    }
                    else
                    {
                        alert(res.errorMsg);
                    }
                }).catch(err => {
                    this.loading = false;
                    alert(err)
                })
            },
            //추진절차 페이지 이동
            goPage(modcd) {
                //사업구분 01.일반구매(1, 2단계), 02.스마트공장, 03.스마트공방
                if(modcd === '01') {
                    this.movePage('/apply-result');
                } else if(modcd === '02') {
                    this.movePage('/projects-one#ProjectsOne-Process');
                } else if(modcd === '03') {
                    this.movePage('/projects-two#ProjectsTwo-Process');
                }
            },
            //사업신청서 페이지 이동
            goApplyMod(docno, productcd, modcd, writedt, closeyn, stepnum) {

                if(modcd != '03')
                {
                    this.$msgBox.show('준비중입니다.');
                    return;
                }

                this.movePage('/applymod'+ modcd + '?pbizcd=' + this.BIZ_CD
                            + '&pdocno='+ docno
                            + '&pproductcd=' + productcd
                            + '&pmodcd=' + modcd
                            + '&pwritedt=' + writedt
                            + '&pcloseyn=' + closeyn
                            + '&pstepnum=' + stepnum
                            + '&ppagetp=' + this.PAGE_TP
                            );
            },
            setDateFormat(gubun, val) {
                let result = '';

                if(gubun === 'bizcd') {
                    //123-45-67890
                    result = val.substr(0,3) + '-' + val.substr(3, 2) + '-' + val.substr(5, 5);
                } else if(gubun === 'date') {
                    //2022-01-01
                    result = val.substr(0,4) + '-' + val.substr(4, 2) + '-' + val.substr(6, 2);
                }

                // console.log('setDateFormat >>> result : ', result);

                return result;
            },
        },
    }
</script>
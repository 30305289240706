<template>
    <div class="choose-section pb-100 d-location">
        <div class="container">
            <div class="section-title">
                <h2>오시는 길</h2>
                <div class="bar"></div>
            </div>
            <div class="row align-items-top">
                <div class="col-lg-5">
                    <div class="choose-content d-ptb-30">
                        <div class="icon center">
                            <i class="flaticon-pin subway-line-2"></i>
                        </div>
                        <h3>지하철 2호선 문래역 이용</h3>
                        <p>문래역 7번 출구에서 도보 6분</p>
                        <!-- <p>문래역 3번 출구에서 도보 17분<br>문래역 정류장에서 간선 641, 마을버스 영등포05번 버스 이용 시 13분</p> -->
                        <!-- <div><a class="dare-btn" href="#"><i class="flaticon-check font-white"></i> Download</a></div> -->
                    </div>
                    <div class="choose-content d-mt-30 d-ptb-30">
                        <div class="icon center">
                            <i class="flaticon-pin subway-line-1"></i>
                        </div>
                        <h3>지하철 1호선 영등포역 이용</h3>
                        <p>영등포역 6번 출구에서 도보 12분</p>
                        <!-- <h3>지하철 5호선 양평역 이용</h3>
                        <p>양평역 2번 출구에서 도보 18분<br>양평역 정류장에서 영등포05번 버스 이용 시 13분</p> -->
                        <!-- <div><a class="dare-btn" href="#"><i class="flaticon-check font-white"></i> Download</a></div> -->
                    </div>
                    <!-- <div class="choose-content d-mt-30 d-ptb-30">
                        <div class="icon center">
                            <i class="mdi mdi-bus-stop"></i>
                        </div>
                        <h3>버스</h3>
                        <p><span>간선 :</span> 641(문래중학교 하차)<span><br>마을버스 :</span> 영등포05(e편한세상문래에듀플라츠 하차)</p> -->
                        <!-- <div><a class="dare-btn" href="#"><i class="flaticon-check font-white"></i> Download</a></div> -->
                    <!-- </div> -->
                </div>

                <div class="col-lg-7">
                    <div class="choose-image text-center">
                        <img src="../../assets/images/about/location2.png" alt="image">
                        <!-- <img src="../../assets/images/about/location.png" alt="image"> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Location'
    }
</script>
<template>
    <div class="more-features-section ptb-100 d-promotion">
        <div class="container">
            <div class="section-title">
                <h2>다래의 승진시스템</h2>
                <p>체계적이고 합리적인 인사평가 시스템을 통해 직원의 능력개발, 성과 창출 등에 따라 승진 가능 범위가 확대 됩니다.</p>
                <div class="bar"></div>
            </div>

            <div class="schedule-list-tab">
                <b-tabs content-class="mt-3">
                    <b-tab title="사원">
                        <div></div>
                    </b-tab>

                    <b-tab title="대리">
                        <div></div>
                    </b-tab>

                    <b-tab title="과장">
                        <div></div>
                    </b-tab>

                    <b-tab title="차장">
                        <div></div>
                    </b-tab>

                    <b-tab title="부장">
                        <div></div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Overview'
    }
</script>
import VueRouter from 'vue-router'

// All Pages
import HomePageTwo from './components/pages/HomePageTwo'
import AboutPage from './components/pages/AboutPage'
import HistoryPage from './components/pages/HistoryPage'
import OrganizationChartPage from './components/pages/OrganizationChartPage'
import LocationPage from './components/pages/LocationPage'
import ServicesOnePage from './components/pages/ServicesOnePage'
import ServicesTwoPage from './components/pages/ServicesTwoPage'
import ServicesLinkPage from './components/pages/ServicesLinkPage'
import ServicesThreePage from './components/pages/ServicesThreePage'
import ServicesFourPage from './components/pages/ServicesFourPage'
import ProjectsPageOne from './components/pages/ProjectsPageOne'
import ProjectsPageTwo from './components/pages/ProjectsPageTwo'
import ProjectsPageThree from './components/pages/ProjectsPageThree'
import NoticePage from './components/pages/NoticePage'
import NoticeViewPage from './components/pages/NoticeViewPage'
import NoticePageWrite from './components/pages/NoticePageWrite'
import NoticePageDetail from './components/pages/NoticePageDetail'
import NoticePageEdit from './components/pages/NoticePageEdit'
import ManualPageWrite from './components/pages/ManualPageWrite'
import ManualPageDetail from './components/pages/ManualPageDetail'
import ManualPageEdit from './components/pages/ManualPageEdit'
import DownloadPage from './components/pages/DownloadPage'
import VideoPage from './components/pages/VideoPage'
import ManualPage from './components/pages/ManualPage'
import QnaPage from './components/pages/QnaPage'
import DarePeoplePage from './components/pages/DarePeoplePage'
import BenefitsPage from './components/pages/BenefitsPage'
import JobPostingPage from './components/pages/JobPostingPage'
import PromotionPage from './components/pages/PromotionPage'
import CustomerPage from './components/pages/CustomerPage'
import FaqPage from './components/pages/FaqPage'
import LogInPage from './components/pages/LogInPage'
import SignUpPage from './components/pages/SignUpPage'
import ErrorPage from './components/pages/ErrorPage'
import ApplyLinkPage from './components/pages/ApplyLinkPage'
import ApplyJoinPage from './components/pages/ApplyJoinPage'
import ApplyPPPage from './components/pages/ApplyPPPage'
import ApplyListPage from './components/pages/ApplyListPage'
import ApplyResultPage from './components/pages/ApplyResultPage'
import ApplyPage from './components/pages/ApplyPage'
import ApplyMod01 from './components/pages/ApplyMod01'
import ApplyMod02 from './components/pages/ApplyMod02'
import ApplyMod03 from './components/pages/ApplyMod03'
import ApplyMod04 from './components/pages/ApplyMod04'
import ApplyMod05 from './components/pages/ApplyMod05'
import ApplyManagerPage from './components/pages/ApplyManagerPage'
import ApplyReportPage from './components/pages/ApplyReportPage'
import kakaoCallback from './components/LogIn/Kakao-callback'
import kakaoLogin from './components/LogIn/Kakao-login'
import UserLoginPage from './components/pages/UserLoginPage'
import UserJoinPage from './components/pages/UserJoinPage'
import AccountResettingPage from './components/pages/AccountResettingPage'

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },

    routes: [
        { path: '/', component: HomePageTwo },
        { path: '/home', component: HomePageTwo },
        { path: '/about', component: AboutPage },
        { path: '/history', component: HistoryPage },
        { path: '/organization-chart', component: OrganizationChartPage },
        { path: '/location', component: LocationPage },
        { path: '/services-one', component: ServicesOnePage },
        { path: '/services-two', component: ServicesTwoPage },
        { path: '/services-link', component: ServicesLinkPage },
        { path: '/services-three', component: ServicesThreePage },
        { path: '/services-four', component: ServicesFourPage },
        { path: '/projects-one', component: ProjectsPageOne },
        { path: '/projects-two', component: ProjectsPageTwo },
        { path: '/projects-three', component: ProjectsPageThree },
        { path: '/notice', component: NoticePage },
        { path: '/boardWrite', component: NoticePageWrite },
        { path: '/boardDetail', component: NoticePageDetail },
        { path: '/boardEdit', component: NoticePageEdit },
        { path: '/notice-view', component: NoticeViewPage },
        { path: '/manualWrite', component: ManualPageWrite },
        { path: '/manualDetail', component: ManualPageDetail },
        { path: '/manualEdit', component: ManualPageEdit },
        { path: '/download', component: DownloadPage },
        { path: '/video', component: VideoPage },
        { path: '/manual', component: ManualPage },
        { path: '/qna', component: QnaPage },
        { path: '/dare-people', component: DarePeoplePage },
        { path: '/benefits', component: BenefitsPage },
        { path: '/job-posting', component: JobPostingPage },
        { path: '/promotion', component: PromotionPage },
        { path: '/customer', component: CustomerPage },
        { path: '/faq', component: FaqPage },
        { path: '/log-in', component: LogInPage },
        { path: '/sign-up', component: SignUpPage },
        { path: '/error-404', component: ErrorPage },
        { path: '/apply-link', component: ApplyLinkPage },
        { path: '/apply-join', component: ApplyJoinPage },
        { path: '/apply-pp', component: ApplyPPPage },
        { path: '/apply-list', component: ApplyListPage },
        { path: '/apply-result', component: ApplyResultPage },
        { path: '/apply', component: ApplyPage },
        { path: '/applymod01', component: ApplyMod01 },
        { path: '/applymod02', component: ApplyMod02 },
        { path: '/applymod03', component: ApplyMod03 },
        { path: '/applymod04', component: ApplyMod04 },
        { path: '/applymod05', component: ApplyMod05 },
        { path: '/apply-manager', component: ApplyManagerPage },
        { path: '/apply-report', component: ApplyReportPage },   
        { path: '/kakaoCallback', component: kakaoCallback }, 
        { path: '/user-login', component: UserLoginPage },
        { path: '/user-join', component: UserJoinPage },  
        { path: '/kakaoLogin', name: 'kakaoLogin', component: kakaoLogin, props: true },   
        { path: '/accreset', component: AccountResettingPage},
        
    ]
})
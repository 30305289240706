<template>
    <div class="contact-section ptb-50 d-qna d-apply">
        <div class="container">
            <div class="section-title">
                <h2>세제 환급 신청</h2>
                <div class="bar"></div>
            </div>          
            <div class="row align-items-center">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <div class="contact-form">                        
                        <v-card
                        :loading="loading"
                        class="mb-12"
                        >
                            <iframe                                     
                                src="https://www.youtube.com/embed/7JMpmiQLES0" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen
                            ></iframe>
                        </v-card>                              
                        <div>
                            <v-stepper
                                v-model="stepnum"
                                vertical
                            >   
                                <Step1 :psteptitle="'기본정보'" :pstepnum="1" :psteptp="1" 
                                    @click="stepnum = 1" @saveclick="stepnum = 2" @nextclick="stepnum = 2" @cancelClick="stepnum = 0"/> 

                                <Step2 :psteptitle="'첨부파일1'" :pstepnum="2" :psteptp="2" 
                                    @click="stepnum = 2" @saveclick="stepnum = 0" @nextclick="stepnum = 0" @cancelClick="stepnum = 0"/>
                            </v-stepper>
                        </div>
                        <div class="send-btn">
                            <button v-if="CLOSE_YN === 'N'" class="default-btn font-w-bold" @click="sendApplymod" >
                                신청서 보내기
                            </button>
                        </div>                        
                    </div>
                </div>
                <div class="col-lg-2"></div>
            </div>
        </div>
    </div>
    
</template>

<script>    
    import Step1 from './StepPage/DOC_COMMON.vue';
    import Step2 from './StepPage/DOC_NM1.vue';

    export default {
        components: {
            Step1,
            Step2,
        },
        name: 'ApplyMod05',
        props: {
            // pbizcd: {type:String, default:''},
            // ppwd: {type:String, default:''},
            // pcloseyn: {type:String, default:'N'},
            // pstepnum: {type:Number , default:1},
            // pdocno: {type:Number, default:1},
            // pwritedt: {type:String, default:''},
        }, 
        data() {
            return { 
                // BIZ_CD: '',
                PWD: '',              
                CLOSE_YN: 'N',
                stepnum: 1,
                // DOC_NO: 1,
                WRITE_DT: '',    
            };
        },
        created() {
            this.BIZ_CD = this.$route.query.pbizcd;
            this.PWD = this.$applyuser.PWD;
            this.CLOSE_YN = this.$route.query.pcloseyn;
            this.MOD_CD = this.$route.query.pmodcd;
            // this.stepnum = this.$route.query.pstepnum;
            this.stepnum = '0';
            this.DOC_NO = this.$route.query.pdocno;
            this.WRITE_DT = this.$route.query.pwritedt; 
        },
        mounted() {
            // console.log('ApplyMod01.vue >>>> this.DOC_NO : ', this.DOC_NO
            //             , ', this.BIZ_CD : ', this.BIZ_CD
            //             , ', this.MOD_CD : ', this.MOD_CD
            //             , ', this.STEP_NUM : ', this.stepnum
            //             , ', this.WRITE_DT : ', this.WRITE_DT); 
        },
        methods:{
            async sendApplymod() {
                await this.$http.get('/board/apply-send-select', {DOC_NO: this.DOC_NO, MOD_CD: this.MOD_CD}).then(async res => {

                    if(res.errorcode=="") {
                        if(res.result[0] == null || res.result[0].length == 0 )
                        {
                            // this.movePage('/apply-list?pbizcd=' + this.BIZ_CD + '&ppwd=');
                        }
                        else
                        {
                            //CONF_SEND_YN 값
                            //N.미작성내용 존재, Y.정상적으로 모두 작성함                            
                            if(res.result[0][0].CONF_SEND_YN === 'Y')
                            {
                                this.movePage('/apply-list?pbizcd=' + this.BIZ_CD);
                            }
                            else 
                            {
                                let cmf = await this.$confirm('아직 작성하지 않은 내용이 존재합니다. 확인 후 작성해주시기 바랍니다.', {
                                    title: '확인'
                                })

                                if(cmf) {
                                    this.movePage('/apply-list?pbizcd=' + this.BIZ_CD);
                                }                                
                            }                                                      
                        }
                    }
                    else
                    {
                        alert(res.errorMsg);
                    }
                }).catch(err => {
                    alert(err)
                })
            },                                          
        }
    }
</script>
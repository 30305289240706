<template>
    <b-modal id="modal-1" title="견적문의">
        <div class="sidebar-modal-inner">
            <!-- <div class="sidebar-about">
                <div class="title">
                    <h2>견적문의</h2>
                    <p>견적</p>
                </div>
            </div> -->

            <!-- <div class="contact-us">
                <h3>Contact Us</h3>

                <ul>
                    <li>
                        <i class="bx bx-location-plus"></i>
                        2750 Quadra Street Victoria,
                    </li>
                    <li>
                        <i class="bx bx-envelope"></i>
                        <a href="mailto:hello@quvia.com">hello@quvia.com</a> <br>
                        <a href="mailto:info@quvia.com">info@quvia.com</a>
                    </li>
                    <li>
                        <i class="bx bx-phone-call"></i>
                        <a href="tel:+44-458-895-456">+44 458 895 456</a> <br>
                        <a href="tel:+44-458-895-455">+44 458 895 455</a>
                    </li>
                </ul>
            </div>

            <div class="sidebar-follow-us">
                <h3>Sidebar Follow</h3>

                <ul class="social-wrap">
                    <li>
                        <a href="#" target="_blank">
                            <i class="bx bxl-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <i class="bx bxl-instagram"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <i class="bx bxl-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <i class="bx bxl-youtube"></i>
                        </a>
                    </li>
                </ul>
            </div> -->
            <div class="sidebar-contact-feed">
                <!-- <h2>견적 기본등록</h2> -->
                <div class="contact-form">
                    <form ref="form" @submit.prevent="sendEmail">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">                                   
                                        <select name="modules" v-model="modules" class="form-control" @change="changedSelect($event)">
                                            <option disabled value="">문의종류</option>
                                            <option value="제품">제품</option>
                                            <option value="정부지원사업">정부지원사업</option>
                                            <option value="견적문의">견적문의</option>
                                        </select>                                 
                                    <input v-show="false" type="text"  name="txtmodule" id="msg_txtmodule" v-model="txtmodule" class="form-control"  required placeholder="문의종류">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="company" id="input-coname" v-model="company" class="form-control" required placeholder="회사명">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="input-name" v-model="name" class="form-control" required placeholder="이름">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="tel" id="input-phone" v-model="tel" class="form-control" required placeholder="연락처 '-'생략">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                <input type="text" name="email" id="input-email" class="form-control" v-model="email" required placeholder="이메일 입력">
                                <!-- <div class="d-mt-10">
                                    <form id="">		
                                        <select name="" class="form-control">
                                            <option value="">@gmail.com</option>
                                            <option value="">@naver.com</option>
                                            <option value="">@nate.com</option>
                                        </select>
                                    </form>
                                </div> -->
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="subject" id="msg_subject" v-model="subject" class="form-control" required placeholder="제목">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="msg_message" v-model="message" cols="30" rows="6" required placeholder="문의내용"></textarea>                                    
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="send-btn text-center">
                                    <button type="submit" value="Send" class="send-btn-one">
                                        Send Message
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="sidebar-contact-area">
                <div class="contact-info">
                    <div class="contact-info-content">
                        <h2>
                            <a href="tel:+82269250994">
                                +82-2-6925-0994~5
                            </a>
                            <span>OR</span>
                            <a href="mailto:dareinc2012@gmail.com">
                                dareinc2012@gmail.com
                            </a>
                        </h2>

                        <ul class="social">
                            <li>
                                <a href="#" target="_blank">
                                    <i class="flaticon-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class="flaticon-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class="flaticon-instagram"></i>
                                </a>
                            </li>
                            <!-- <li>
                                <a href="#" target="_blank">
                                    <i class="flaticon-pinterest"></i>
                                </a>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

import emailjs from '@emailjs/browser'

export default {
    name: 'SidebarModal',

    data() {
		return {
            company : '',
            name : '',
            tel : '',
            email : '',
            subject : '',
            txtmodule : '',
            message : '',
            modules : ''
		};
	},

    methods:{
        //EmailJs 사용하여 메일 전송하기
        sendEmail() {
            // this.$router.replace('/services-one');
            // this.$msgBox.show('(주)다래아이엔씨 관리자에게 견적메일이 전송되었습니다.');
            // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', this.$refs.form, 'YOUR_PUBLIC_KEY')
            // https://dashboard.emailjs.com/admin 사이트에서 계정정보와 서비스 템플릿 등을 세팅한다.
            emailjs.sendForm('service_8zlc9re', 'template_oshpfxk', this.$refs.form, 'gRB0-nuhjqlnyQ4-D')
            .then((result) => {
                console.log('SUCCESS!', result.text);
                alert("(주)다래아이엔씨 관리자에게 견적메일이 전송되었습니다.");
                this.controlClear();
            }, (error) => {
                alert("견적메일이 정상적으로 전송되지 않았습니다.");
                console.log('FAILED...', error.text);
            });
        },
        //입력 컨트롤 초기화
        controlClear(){
            this.company = '',
            this.name = '',
            this.tel = '',
            this.email = '',
            this.subject = '',
            this.txtmodule = '',
            this.message = '',
            this.modules = ''
        },
        //모듈구분 선택 값 담기
        changedSelect(event){
            this.txtmodule = event.target.value;
        }
    }
}
</script>
<template>
    <div id="ProjectsThree-Process" class="services-section bg-background pt-100 pb-70">
        <div class="container">
            <!-- 세제환급 사업 -->
            <div class="section-title">
                <h2>추진절차</h2>
                <div class="bar"></div>
            </div>
            <div class="row">
                <!-- 1. 링크접속/진단신청 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-01">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-three-step-01.png" alt="01. 링크접속/진단신청">
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">준비중입니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">소개 영상 보기</a>
                                <!-- <a target="_self" class="w-100 p-one-process-details dare-btn bg-color-yellow mt-1 kakao"><img src="../../assets/images/icon-kakao-mobile.png" alt="아이콘"> 카카오플러스로 채팅 문의하기</a> -->
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">1. 링크접속/진단신청</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction.png" alt="공사중"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group">
                                    <!-- <a href="http://pf.kakao.com/_xoCRss/chat" target="_blank" class="w-100 p-one-process-details dare-btn bg-color-yellow mt-1 kakao"><img src="../../assets/images/icon-kakao-mobile.png" alt="아이콘"> 카카오플러스로 채팅 문의하기</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 2. 자료 업로드 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-02">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-three-step-02.png" alt="02. 자료 업로드">
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">준비중입니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">안내 영상 보기</a>
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">2. 자료 업로드</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction.png" alt="공사중"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/MXjcvpdydSg"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 3. 자료검토/보완(테스트기간 일일 40업체) -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-02">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-three-step-03.png" alt="03. 자료검토/보완(테스트기간 일일 40업체)">
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-red"></i></b>
                                    <p class="font-blue">준비중입니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">가이드 영상보기</a>
                                <!-- <a target="_self" class="w-100 p-one-process-details dare-btn">필요서류 보기</a>
                                <a href="/apply-join" target="_self" class="w-100 dare-btn mt-1">사업신청 바로가기</a> -->
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">3. 자료검토/보완(테스트기간 일일 40업체)</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction.png" alt="공사중"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/AbFO5Lgc9a0"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <!-- <div class="d-btn-group mt-1">
                                    <a href="/apply-join?autologin=y&ppagetp=3" target="_self" class="w-100 p-one-process-details dare-btn">사업신청 바로가기</a>
                                </div> -->
                                <!-- <div class="mt-2 d-scroll-y">
                                    <div class="faq-accordion">
                                        <ul class="accordion" role="tablist">

                                            <b-card no-body class="accordion-item">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button class="accordion-title" block v-b-toggle.accordion-1 variant="info"><i class='bx bx-chevron-down'></i><h4 class="text-left"><i class='mdi mdi-file-outline'></i> 신청전 확인사항</h4><span></span></b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <ul class="pb-2">
                                                            <li><i class="mdi mdi-menu-right"></i> 10인 미만 제조업 한정</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 휴•폐업, 부도 업체 X</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 세금 체납(국세•지방세, 금융기관 채무불이행) X</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 참여 제한(부정 수급 및 현재 스마트화 사업을 진행 중인 업체) X</li>
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>

                                            <b-card no-body class="accordion-item mt-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button class="accordion-title" block v-b-toggle.accordion-2 variant="info"><i class='bx bx-chevron-down'></i><h4 class="text-left"><i class='mdi mdi-file-outline'></i>  필요서류</h4><span></span></b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <ul class="pb-2">
                                                            <li><i class="mdi mdi-menu-right"></i> 사업자등록증</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 회사소개서</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 최근 3년 부가가치세과세표준증명 + 최근 1년 부가가치세신고서</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 중소기업(소상공인)확인서</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 신청서</li>
                                                            <li><i class="mdi mdi-menu-right"></i> e나라도움 가입</li>
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>

                                            <b-card no-body class="accordion-item mt-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button class="accordion-title" block v-b-toggle.accordion-3 variant="info"><i class='bx bx-chevron-down'></i><h4 class="text-left"><i class='mdi mdi-file-outline'></i>  사업계획서 작성사항</h4><span></span></b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <ul class="pb-2">
                                                            <li><i class="mdi mdi-menu-right"></i> 회사소개</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 생산완제품 이미지 및 설명(소개)</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 생산공정도 및 각 공정별 이미지와 설명</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 공장 평면도 1장 / 평면도에 설치하려는 설비 표시 1장</li>
                                                            <li><i class="mdi mdi-menu-right"></i> 도입하려는 설비 카달로그 또는 이미지와 설명</li>
                                                            <li><i class="mdi mdi-menu-right"></i> KPI 지표 중 제품 생산/불량 수량</li>
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>

                                        </ul>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 4. 보고서 산출(예시 별도첨부) -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-01">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-three-step-04.png" alt="04. 보고서 산출(예시 별도첨부)">
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">준비중입니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">가이드 영상보기</a>
                                <!-- <a href="http://58.120.225.120:8030/www/apply/2023_스마트공방_샘플_사업계획서.hwp" target="_self" class="w-100 p-one-process-details dare-btn mt-1">사업계획서 샘플 다운</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1" :href="fileLink" @click="downloadFile($event, '04')">사업계획서 샘플 다운</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">사업계획서 작성하기</a> -->
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">4. 보고서 산출(예시 별도첨부)</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction.png" alt="공사중"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/PTzc8MeReMM"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <!-- <a href="http://58.120.225.120:8030/www/apply/2023_스마트공방_샘플_사업계획서.hwp" target="_blank" class="w-100 p-one-process-details dare-btn">사업계획서 샘플 다운</a>
                                    <a target="_blank" class="w-100 p-one-process-details dare-btn" :href="fileLink" @click="downloadFile($event, '04')">사업계획서 샘플 다운</a>
                                    <a href="/apply-join?autologin=y&ppagetp=4" target="_self" class="w-100 p-one-process-details dare-btn mt-1">사업계획서 작성하기</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 5. 계약서/전자서명(양식 별도첨부) -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-02">
                        <div class="projects-image">
                            <img src="../../assets/images/projects/p-three-step-05.png" alt="05. 계약서/전자서명(양식 별도첨부)">
                            <p></p>
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">준비중입니다.</p>
                                </li>
                                <!-- <li>
                                    <b><i class="flaticon-tick"></i></b>
                                    <p>1차 서류 결과 발표 후 통과 여부에 관한 문자 메시지가 전송이 되고 답신을 달라고 합니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-gray"></i></b>
                                    <p>통과 여부에 관한 문자를 담당자에게 전달해주시면 답신 문자 내용을 보내실 수 있도록 전달해드릴 것입니다.</p>
                                </li> -->
                            </ul>
                            <div class="d-btn-group">
                                <a class="w-100 p-one-process-details dare-btn">가이드 영상보기</a>
                                <!-- <a class="w-100 p-one-process-details dare-btn" @click="mgs()">서류 심사 합격 전달</a>
                                <a class="w-100 p-one-process-details dare-btn mt-1" @click="mgs()">서류 심사 불합격 및 재접수 요청</a> -->
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">5. 계약서/전자서명(양식 별도첨부)</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction.png" alt="공사중"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/PTzc8MeReMM"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <!-- <a href="http://58.120.225.120:8030/www/apply/2023_스마트공방_샘플_사업계획서.hwp" target="_blank" class="w-100 p-one-process-details dare-btn">사업계획서 샘플 다운</a>
                                    <a target="_blank" class="w-100 p-one-process-details dare-btn" :href="fileLink" @click="downloadFile($event, '04')">사업계획서 샘플 다운</a>
                                    <a href="/apply-join?autologin=y&ppagetp=4" target="_self" class="w-100 p-one-process-details dare-btn mt-1">사업계획서 작성하기</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 6. 경정접수(관할 세무서 검토) -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-02">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-three-step-06.png" alt="06. 경정접수(관할 세무서 검토)">
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">준비중입니다.</p>
                                </li>
                                <!-- <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">작성된 사업계획서를 읽으면 발표하는 형태로 진행이 되며 발표 방법 및 예상 질문 영상을 보시고 준비를 해 주시면 됩니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-red"></i></b>
                                    <p class="font-blue">부가적 도움이 필요하시면 발표도움요청 버튼을 누르시면 담당자를 통하여 발표 준비를 도움드리겠습니다.</p>
                                </li> -->
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">가이드 영상보기</a>
                                <!-- <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">발표 준비 영상보기</a>
                                <a target="_self" class="w-100 p-one-process-details dare-btn mt-1">발표 도움 요청</a> -->
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">6. 경정접수(관할 세무서 검토)</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction.png" alt="공사중"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <!-- <a class="w-100 p-one-process-details dare-btn" @click="mgs()">발표 사업계획서 요청</a>
                                    <a class="w-100 p-one-process-details dare-btn mt-1" @click="mgs()">발표 도움 요청</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 7. 환급/이월(홈택스 국세환급계좌등록 or 우체국 현금수령) -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-02">
                        <div class="projects-image">
                            <div class="process-bg">
                                <img src="../../assets/images/projects/p-three-step-07.png" alt="07. 환급/이월(홈택스 국세환급계좌등록 or 우체국 현금수령)">
                            </div>
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <!-- <b><i class="flaticon-tick"></i></b> -->
                                    <p>준비중입니다.</p>
                                </li>
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">가이드 영상보기</a>
                                <!-- <a class="w-100 p-one-process-details dare-btn" @click="mgs()">최종 선정</a>
                                <a class="w-100 p-one-process-details dare-btn mt-1" @click="mgs()">미선정 및 재접수 요청</a> -->
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">7. 환급/이월(홈택스 국세환급계좌등록 or 우체국 현금수령)</h3>
                                <div class="d-construction mt-5"><img src="../../assets/images/under-construction.png" alt="공사중"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <div class="d-btn-group mt-1">
                                    <!-- <a class="w-100 p-one-process-details dare-btn" @click="mgs()">발표 사업계획서 요청</a>
                                    <a class="w-100 p-one-process-details dare-btn mt-1" @click="mgs()">발표 도움 요청</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 8. 계약이행 -->
                <div class="col-lg-4 col-md-6">
                    <div class="Steps One-Step-07 Two-Step-02">
                        <div class="projects-image services-details-video">
                            <img src="../../assets/images/projects/p-three-step-08.png" alt="08. 계약이행">
                            <ul class="list d-mt-20">
                                <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p>준비중입니다.</p>
                                </li>
                                <!-- <li>
                                    <b><i class="mdi mdi-youtube font-size-20 font-red"></i></b>
                                    <p class="font-blue">본 사업을 진행하기 위해 필요한 서류를 작성 및 준비해 주시는 단계입니다.</p>
                                </li>
                                <li>
                                    <b><i class="mdi mdi-webcam font-size-20 font-red"></i></b>
                                    <p class="font-blue">이메일에 온 업무협약서 및 기타 서류를 작성하셔서 업로드 해주시면 됩니다.</p>
                                </li> -->
                            </ul>
                            <div class="d-btn-group">
                                <a target="_self" class="w-100 p-one-process-details dare-btn">가이드 영상보기</a>
                                <!-- <a href="#" target="_self" class="w-100 p-one-process-details dare-btn">필요서류 작성 방법 영상보기</a>
                                <a href="#" target="_self" class="w-100 p-one-process-details dare-btn mt-1">필요서류 업로드</a> -->
                            </div>
                        </div>
                        <div class="projects-content" v-show="upHere1">
                            <div>
                                <h3 class="mt-1">8. 계약이행</h3>
                                <div class="d-construction"><img src="../../assets/images/under-construction.png" alt="공사중"></div>
                                <iframe
                                        src="https://www.youtube.com/embed/IswPd2qdpSU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                        allowTransparency="true"
                                        style="opacity: 1;"
                                    >
                                </iframe>
                                <!-- <div class="d-btn-group mt-1">
                                    <a class="w-100 p-one-process-details dare-btn" @click="mgs()">필요서류 업로드</a>
                                </div>
                                <h4 class="text-left mt-1"><i class='mdi mdi-file-outline'></i> 첨부파일</h4>
                                <p class="pl-3">
                                    <i class="mdi mdi-menu-right"></i> 업무협약서 및 기타 서류
                                </p> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import fileService from '@/utils/fileService';

    export default {
        name: 'ProjectsProcess',
        data() {
            return {
                fileName: '',
                fileLink: '',
            }
        },
        mounted() {
            this.getIpClient();
        },
        methods: {
            mgs() {
                this.$msgBox.show('준비중입니다.');
            },
            async getIpClient() {
                try {
                    const response = await axios.get('https://ipinfo.io/json');
                    localStorage.setItem('CLIENTIP', response.data.ip);
                    // console.log('HomeTwo > Services.vue > this.$applyuser.CLIENT_IP : ', this.$applyuser.CLIENT_IP);
                } catch (error) {
                    console.error(error);
                }
            },
            downloadFile (event, step) {
                this.fileName = '';
                this.fileLink = 'https://docusystem.s3.ap-northeast-2.amazonaws.com/BIZDOC/0000000000/03/00000000/';

                if(step == '04') {
                    this.fileName = '2023_스마트공방_샘플_사업계획서.hwp';
                }
                this.fileLink = this.fileLink + this.fileName;
                event.preventDefault();
                fileService.downloadFileFromS3(this.fileLink);
            },
        },
    }
</script>
<template>
    <div>
        <v-stepper-step
        :complete="getComplete()"
        :step="stepnum"
        @click="clicked"
        >
            <!-- 기본입력 -->
            {{TITLE}}
            <small v-if="bSaved==true">작성완료</small>
            <small v-if="bSaved==false"><span>미완료</span></small>
        </v-stepper-step>

        <v-stepper-content :step="stepnum">
            <div class="form-group">
                <label for="input-coname"><h5 class="font-w-normal">회사명<span class="font-size-15 font-w-normal">(사업자등록증에 기재된 업체명과 동일하게 입력해주시길 바랍니다.)</span></h5></label>
                <input type="text" name="company" id="input-coname" v-model="CORP_NM" class="form-control" required placeholder="회사명">
            </div>
            <div class="form-group mt-4">
                <label for="input-coname"><h5 class="font-w-normal">사업자등록번호<span class="font-size-15 font-w-normal"></span></h5></label>
                <input type="text" name="bizcd" id="input-coname" v-model="BIZ_CD" class="form-control" readonly>
            </div>
            <div class="form-group mt-4">
                <label for="input-coname" class="justify-left">
                    <h5 class="font-w-normal">대표자명</h5>
                </label>
                <input type="text" name="ceonm" id="input-coname" v-model="CEO_NM" class="form-control" required placeholder="대표자명">
            </div>
            <div class="form-group mt-4">
                <label for="input-name"><h5 class="font-w-normal">대표자 연락처</h5></label>
                <input type="text" name="ceotel" id="input-tel" v-model="CEO_TEL" class="form-control" required placeholder="전화번호 '-'생략">
            </div>
            <div class="form-group ceo mt-6">
                <label for="input-name">
                    <h5 class="font-w-normal mr-4">과제책임자명 / 직함</h5>
                    <v-checkbox
                        v-model="isSame"
                        :label="`대표자와 동일함`"
                        @change="isSameChanged"
                        class="mt-0"
                    ></v-checkbox>
                </label>
                <input type="text" name="pminfo" id="input-name" v-model="PM_INFO" class="form-control" required placeholder="담당자명 / 직함">
            </div>
            <div class="form-group mt-4">
                <label for="input-name"><h5 class="font-w-normal">과제책임자 연락처</h5></label>
                <input type="text" name="pmtel" id="input-tel" v-model="PM_TEL" class="form-control" required placeholder="전화번호 '-'생략">
            </div>
            <div class="form-group mt-4">
                <label for="input-name"><h5 class="font-w-normal">이메일</h5></label>
                <input type="email" name="email" id="input-email" v-model="EMAIL" class="form-control" required placeholder="Example@gmail.com">
            </div>
            <div class="form-group mt-4">
                <label for="input-name"><h5 class="font-w-normal">주소</h5></label>
                <input type="text" name="postno" id="input-post" v-model="POST_NO" class="form-control" @click="showApi" required placeholder="클릭하여 주소입력" readonly>
                <input type="text" name="addr1" id="input-addr" v-model="ADDR1" class="form-control mt-1" required placeholder="주소" readonly>
                <input type="text" name="addr2" id="input-addr" v-model="ADDR2" class="form-control mt-1" required placeholder="상세주소">
            </div>
            <div class="form-group mt-4">
                <label for="input-name"><h5 class="font-w-normal">업태</h5></label>
                <input type="text" name="bsncdt" id="input-bsncdt" v-model="BSNCDT" class="form-control" required placeholder="업태">
            </div>
            <div class="form-group mt-4">
                <label for="input-name"><h5 class="font-w-normal">업종</h5></label>
                <input type="text" name="itm" id="input-itm" v-model="ITM" class="form-control" required placeholder="업종">
            </div>
            <div class="form-group exp mt-4">
                <label for="input-name"><h5 class="font-w-normal">이전 정부 사업 진행 여부</h5></label>
                <v-radio-group
                v-model="GBIZ_YN"
                row
                @change="radioChanged"
                class="mt-0"
                >
                    <v-radio
                        label="여"
                        :value="1"
                    ></v-radio>
                    <v-radio
                        label="부"
                        :value="2"
                    ></v-radio>
                </v-radio-group>
                <label for="input-name"><h5 class="font-w-normal">이전 진행한 정부 사업명</h5></label>
                <input type="text" name="gbiznm" id="input-gbiznm" v-model="GBIZ_NM" class="form-control" :readonly="GBIZ_YN==2" required placeholder="이전 진행한 정부사업명">
            </div>
            <div class="form-group mt-4">
                <label for="input-name"><h5 class="font-w-normal">홈페이지주소</h5></label>
                <input type="text" name="homepageurl" id="input-url" v-model="HOMEPAGE_URL" class="form-control" required placeholder="홈페이지주소">
            </div>

            <div class="text-center mt-6">
                <v-btn
                    class="d-inline-block mr-1"
                    color="primary"
                    v-if="CLOSE_YN === 'N'"
                    @click="saveClicked"
                >
                    저장
                </v-btn>
                <v-btn
                    class="d-inline-block mr-1"
                    color="primary"
                    @click="nextClicked"
                >
                    계속
                </v-btn>
                <v-btn
                    class="d-inline-block bg-color-gray"
                    text
                    @click="cancelClicked"
                >
                    닫기
                </v-btn>
            </div>
        </v-stepper-content>
    </div>
</template>

<script>
    export default {
        components: {

		},
        name: 'DOC_COMMON',
        props: {
            psteptitle: {type:String, default:'기본입력'},
            psteptp: {type:String, default:'1'},
            pstepnum: {type:Number, default:1},
            // pdocno: {type:Number, default:1},
            // pmodcd: {type:String, default:''},
            // pbizcd: {type:String, default:''},
            // pcloseyn: {type:String, default:'N'},
        },
        data() {
            return {
                TITLE: '',
                bSaved: false,  //작성완료여부
                CLOSE_YN: 'N',  //신청서가 진행중이면 'N', 마감상태면 'Y' 로 관리함
                STEP_TP: '1',
                stepnum: 1,     //신규작성이면 값이 1 이고 중간저장한 신청서인 경우 진행스탭 값을 받아 적용함
                DOC_NO: 1,
                MOD_CD: '',     //사업구분 01.스마트공장, 02.스마트공방, 03.일반구매(1, 2단계)
                CORP_NM: '',
                BIZ_CD: '',
                CEO_NM: '',
                CEO_TEL: '',
                isSame: false,
                PM_INFO: '',
                PM_TEL: '',
                EMAIL: '',
                POST_NO: '',
                ADDR1: '',
                ADDR2: '',
                BSNCDT: '',
                ITM: '',
                GBIZ_YN: 2,
                GBIZ_NM: '',
                HOMEPAGE_URL : '',
                // userId: this.$user.ID_USER,
            };
        },
        created() {
            this.TITLE = this.psteptitle;
            this.STEP_TP = this.psteptp;
            this.stepnum = this.pstepnum;
            this.DOC_NO = this.$route.query.pdocno;
            this.MOD_CD = this.$route.query.pmodcd;
            this.BIZ_CD = this.$route.query.pbizcd;
            this.CLOSE_YN = this.$route.query.pcloseyn;

            // console.log('DOC_COMMON.vue >>>> created >>>> this.BIZ_CD : ', this.BIZ_CD
            //             , ', this.DOC_NO : ',this.DOC_NO
            //             , ', this.MOD_CD : ', this.MOD_CD
            //             , ', this.STEP_TP : ', this.STEP_TP
            //             , ', this.stepnum : ', this.stepnum
            //             , ', this.CLOSE_YN : ', this.CLOSE_YN
            //             , ', this.GBIZ_YN : ', this.GBIZ_YN
            //             );
        },
        mounted() {


            this.getStepSelect();
        },
        methods:{
            clicked() {
                this.$emit('click');
            },
            getStepSelect() {
                this.$http.get('/board/apply-step-select', {DOC_NO: this.DOC_NO, STEP_TP: this.STEP_TP}).then(res => {

                    if(res.errorcode=="") {
                        if(res.result[0] == null || res.result[0].length == 0 )
                        {
                            // this.$msgBox.show('조회조건을 만족하는 데이터가 없습니다.');
                        }
                        else
                        {
                            this.CORP_NM = res.result[0][0].CORP_NM;
                            this.CEO_NM = res.result[0][0].CEO_NM;
                            this.CEO_TEL = res.result[0][0].CEO_TEL;
                            this.PM_INFO = res.result[0][0].PM_INFO;
                            this.PM_TEL = res.result[0][0].PM_TEL;
                            this.EMAIL = res.result[0][0].EMAIL;
                            this.POST_NO = res.result[0][0].POST_NO;
                            this.ADDR1 = res.result[0][0].ADDR1;
                            this.ADDR2 = res.result[0][0].ADDR2;
                            this.BSNCDT = res.result[0][0].BSNCDT;
                            this.ITM = res.result[0][0].ITM;
                            this.GBIZ_YN = Number(res.result[0][0].GBIZ_YN) === 0 ? 2 : Number(res.result[0][0].GBIZ_YN);
                            this.GBIZ_NM = res.result[0][0].GBIZ_NM;
                            this.HOMEPAGE_URL  = res.result[0][0].HOMEPAGE_URL;
                            // this.stepnum = res.result[0][0].STEP_NUM;
                        }
                    }
                    else
                    {
                        alert(res.errorMsg);
                    }
                }).catch(err => {
                    this.loading = false;
                    alert(err)
                })
            },
            async saveClicked() {
                let res = await this.$confirm('작성하신 내용을 저장하시겠습니까?', {
					title: '확인'
				})

                if (res) { 
					this.$http.post('/board/apply-update', {
                        DOC_NO: this.DOC_NO,
                        STEP_TP: this.STEP_TP,
                        STEP_NUM: this.stepnum,
						ETC1: this.MOD_CD,          //사업구분 / 01.스마트공장, 02.스마트공방, 03.일반구매
						ETC2: this.CORP_NM,         //회사명
                        ETC3: this.CEO_NM,          //사업자명(대표자명)
                        ETC4: this.CEO_TEL,         //사업자명(대표자명) 연락처
                        ETC5: this.PM_INFO,         //담당자명/직함
                        ETC6: this.PM_TEL,          //담당자 연락처
                        ETC7: this.EMAIL,           //담당자 이메일
                        ETC8: this.POST_NO,         //우편번호
                        ETC9: this.ADDR1,           //주소1
                        ETC10: this.ADDR2,           //주소2
                        ETC11: this.BSNCDT,         //업태
                        ETC12: this.ITM,            //업종
                        ETC13: this.GBIZ_YN,        //이전정부사업진행여부
                        ETC14: this.GBIZ_NM,        //이전 진행한 정부사업명
                        ETC15: this.HOMEPAGE_URL,   //홈페이지주소
                        ETC16: '',                        
                        IMG1: null,
                        CLIENT_IP: this.$applyuser.CLIENT_IP,  //수정자IP				
					}).then(res => {

						if (res.result) {
                            this.bSaved = true;
							this.$msgBox.show('저장되었습니다.');
						}
					}).catch(err => {
						alert(err)
					})
				}

                this.$emit('saveclick');
            },
            nextClicked() {
                this.$emit('nextclick');
            },
            cancelClicked() {
                this.$emit('cancelClick');
            },
            getComplete() {

                if(this.CEO_NM != ''            //사업자명(대표자명)
                    && this.CEO_TEL != ''       //사업자명(대표자명) 연락처
                    && this.PM_INFO != ''       //담당자명/직함
                    && this.PM_TEL != ''        //담당자 연락처
                    && this.EMAIL != ''         //담당자 이메일
                    && this.POST_NO != ''       //우편번호
                    && this.ADDR1 != ''         //주소1
                    && this.ADDR2 != ''         //주소2
                ) {
                    this.bSaved = true;
                }
                else {
                    this.bSaved = false;
                }

                return this.bSaved;
            },
            isSameChanged() {
                if(this.isSame == true) {
                    this.PM_INFO = this.CEO_NM;
                    this.PM_TEL = this.CEO_TEL;
                }
                else {
                    this.PM_INFO = '';
                    this.PM_TEL = '';
                }
            },
            radioChanged() {
                if(this.GBIZ_YN === 2)
                {
                   this.GBIZ_NM = '';
                }
            },
            showApi() {
                new window.daum.Postcode({
                    oncomplete: (data) => {
                        // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

                        // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
                        // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
                        let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
                        let extraRoadAddr = ''; // 도로명 조합형 주소 변수

                        // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                        // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                        if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                            extraRoadAddr += data.bname;
                        }
                        // 건물명이 있고, 공동주택일 경우 추가한다.
                        if(data.buildingName !== '' && data.apartment === 'Y'){
                            extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                        }
                        // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                        if(extraRoadAddr !== ''){
                            extraRoadAddr = ' (' + extraRoadAddr + ')';
                        }
                        // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
                        if(fullRoadAddr !== ''){
                            fullRoadAddr += extraRoadAddr;
                        }

                        // 우편번호와 주소 정보를 해당 필드에 넣는다.
                        this.POST_NO = data.zonecode; //5자리 새우편번호 사용
                        this.ADDR1 = fullRoadAddr;
                    }
                // }).embed(this.$refs.embed)
                }).open()
            },
        }
    }
</script>
<template>
    <div>
        <Navbar />
        <PageTitle />
        <ProjectsAbout />
        <ProjectsSupport />
        <ProjectsDetails />
        <Performance />
        <ProjectsProcess />
        <Product />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import PageTitle from '../ProjectsOne/PageTitle'
    import ProjectsAbout from '../ProjectsOne/ProjectsAbout'
    import ProjectsSupport from '../ProjectsOne/ProjectsSupport'
    import ProjectsDetails from '../ProjectsOne/ProjectsDetails'
    import Performance from '../ProjectsOne/Performance'
    import ProjectsProcess from '../ProjectsOne/ProjectsProcess'
    import Product from '../ProjectsOne/Product'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'ProjectsPageOne',
        components: {
            Navbar,
            PageTitle,
            ProjectsAbout,
            ProjectsSupport,
            ProjectsDetails,
            Performance,
            ProjectsProcess,
            Product,
            Footer,
            CopyRight,
        }
    }
</script>
<template>
    <div class="page-title-area item-bg-3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-title-content">
                        <h2>세제환급 사업</h2>
                        <ul>
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/projects-one">정부지원사업</router-link></li>
                            <li>세제환급 사업</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTitle'
    }
</script>
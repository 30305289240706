<template>
    <div>
        <back-to-top bottom="50px" right="50px">
            <div class="go-top">
            <i class="bx bx-chevron-up"></i>
            <i class="bx bx-chevron-up"></i>
        </div>
        </back-to-top>
    </div>
</template>

<script>
    export default {
        name: 'GoTop'
    }
</script>
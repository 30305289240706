<template>
    <div class="contact-section ptb-50 d-qna d-apply">
        <div class="container">
            <div class="section-title">
                <h2>{{TITLE}}</h2>
                <div class="bar"></div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <!-- 추진절차 3단계 [사업신청] 일 때 -->
                    <div class="contact-form" v-if="PAGE_TP =='3'">
                        <v-card
                        :loading="loading"
                        class="mb-12"
                        >
                            <iframe
                                src="https://www.youtube.com/embed/AbFO5Lgc9a0"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </v-card>
                        <div>
                            <v-stepper
                                v-model="stepnum"
                                vertical
                            >
                                <Step3_1 :psteptitle="'기본입력'" :pstepnum="1" :psteptp="1"
                                    @click="stepnum = 1" @saveclick="stepnum = 2" @nextclick="stepnum = 2" @cancelClick="stepnum = 0"/>

                                <Step3_2 :psteptitle="'사업자등록증(첨부파일)'" :pstepnum="2" :psteptp="2"
                                    @click="stepnum = 2" @saveclick="stepnum = 3" @nextclick="stepnum = 3" @cancelClick="stepnum = 0"/>

                                <Step3_3 :psteptitle="'회사 소개서(첨부파일)'" :pstepnum="3" :psteptp="5"
                                    @click="stepnum = 3" @saveclick="stepnum = 4" @nextclick="stepnum = 4" @cancelClick="stepnum = 0"/>

                                <Step3_4 :psteptitle="'3개년 부가가치세 표준 증명(첨부파일)'" :pstepnum="4" :psteptp="7"
                                    @click="stepnum = 4" @saveclick="stepnum = 5" @nextclick="stepnum = 5" @cancelClick="stepnum = 0"/>

                                <Step3_5 :psteptitle="'중소기업확인서 or 월별원천징수 이행상황 신고서(첨부파일)'" :pstepnum="5" :psteptp="8"
                                    @click="stepnum = 5" @saveclick="stepnum = 6" @nextclick="stepnum = 6" @cancelClick="stepnum = 0"/>

                                <Step3_6 :psteptitle="'신청서(첨부파일)'" :pstepnum="6" :psteptp="22"
                                    @click="stepnum = 6" @saveclick="stepnum = 7" @nextclick="stepnum = 7" @cancelClick="stepnum = 0"/>

                                <Step3_7 :psteptitle="'e나라도움 가입정보(ID/PW)'" :pstepnum="7" :psteptp="21"
                                    @click="stepnum = 7" @saveclick="stepnum = 8" @nextclick="stepnum = 8" @cancelClick="stepnum = 0"/>

                                <Step3_8 :psteptitle="'도장 날인(첨부파일)'"  :pstepnum="8" :psteptp="8"
                                    @click="stepnum = 8" @saveclick="stepnum = 0" @nextclick="stepnum = 0" @cancelClick="stepnum = 0"/>
                            </v-stepper>
                        </div>

                        <div class="send-btn">
                            <button v-if="CLOSE_YN === 'N'" class="default-btn font-w-bold" @click="sendApplymod">
                                신청서 보내기
                            </button>
                        </div>
                    </div>
                    <!-- 추진절차 4단계 [사업계획서작성] 일 때 -->
                    <div class="contact-form" v-if="PAGE_TP =='4'">
                        <v-card
                        :loading="loading"
                        class="mb-4"
                        >
                            <iframe
                                src="https://www.youtube.com/embed/IcwBsCX7FuM"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </v-card>
                        <div>
                            <div class="send-btn mb-4">
                                <button class="default-btn font-w-bold" >
                                    <!-- <a  href="http://58.120.225.120:8030/www/apply/2023_스마트공방_샘플_사업계획서.hwp"
                                        target="_blank" style="color:white" >샘플 사업계획서 다운로드</a> -->
                                    <a :href="fileLink" @click="downloadFile($event, '00')"
                                        target="_blank" style="color:white" >샘플 사업계획서 다운로드</a>
                                </button>
                            </div>
                            <v-stepper
                                v-model="stepnum"
                                vertical
                            >
                                <Step4_1 :psteptitle="'사업계획서 작성하기'" :pstepnum="1" :psteptp="1"
                                    @click="stepnum = 1" @saveclick="stepnum = 2" @nextclick="stepnum = 2" @cancelClick="stepnum = 0"/>

                                <Step4_2 :psteptitle="'판매제품 사진(첨부파일)'" :pstepnum="2" :psteptp="2"
                                    @click="stepnum = 2" @saveclick="stepnum = 3" @nextclick="stepnum = 3" @cancelClick="stepnum = 0"/>

                                <Step4_3 :psteptitle="'공장 배치도(첨부파일)'" :pstepnum="3" :psteptp="3"
                                    @click="stepnum = 3" @saveclick="stepnum = 4" @nextclick="stepnum = 4" @cancelClick="stepnum = 0"/>

                                <Step4_4 :psteptitle="'공정도 및 공정별 사진(첨부파일)'" :pstepnum="4" :psteptp="4"
                                    @click="stepnum = 4" @saveclick="stepnum = 5" @nextclick="stepnum = 5" @cancelClick="stepnum = 0"/>

                                <Step4_5 :psteptitle="'특허 및 인증 서류(첨부파일)'" :pstepnum="5" :psteptp="5"
                                    @click="stepnum = 5" @saveclick="stepnum = 0" @nextclick="stepnum = 0" @cancelClick="stepnum = 0"/>
                            </v-stepper>
                        </div>

                        <div class="send-btn">
                            <button v-if="CLOSE_YN === 'N'" class="default-btn font-w-bold" @click="sendApplymod">
                                신청서 보내기
                            </button>
                        </div>
                    </div>
                    <!-- 마감된 신청서 전체내용 보기 -->
                    <div class="contact-form" v-if="PAGE_TP ==''">
                        <div>
                            <v-stepper
                                v-model="stepnum"
                                vertical
                            >
                                <Step3_1 :psteptitle="'기본입력'" :pstepnum="1" :psteptp="1"
                                    @click="stepnum = 1" @saveclick="stepnum = 2" @nextclick="stepnum = 2" @cancelClick="stepnum = 0"/>

                                <Step3_2 :psteptitle="'사업자등록증(첨부파일)'" :pstepnum="2" :psteptp="2"
                                    @click="stepnum = 2" @saveclick="stepnum = 3" @nextclick="stepnum = 3" @cancelClick="stepnum = 0"/>

                                <Step3_3 :psteptitle="'회사 소개서(첨부파일)'" :pstepnum="3" :psteptp="5"
                                    @click="stepnum = 3" @saveclick="stepnum = 4" @nextclick="stepnum = 4" @cancelClick="stepnum = 0"/>

                                <Step3_4 :psteptitle="'3개년 부가가치세 표준 증명(첨부파일)'" :pstepnum="4" :psteptp="7"
                                    @click="stepnum = 4" @saveclick="stepnum = 5" @nextclick="stepnum = 5" @cancelClick="stepnum = 0"/>

                                <Step3_5 :psteptitle="'중소기업확인서 or 월별원천징수 이행상황 신고서(첨부파일)'" :pstepnum="5" :psteptp="8"
                                    @click="stepnum = 5" @saveclick="stepnum = 6" @nextclick="stepnum = 6" @cancelClick="stepnum = 0"/>

                                <Step3_6 :psteptitle="'신청서(첨부파일)'" :pstepnum="6" :psteptp="22"
                                    @click="stepnum = 6" @saveclick="stepnum = 7" @nextclick="stepnum = 7" @cancelClick="stepnum = 0"/>

                                <Step3_7 :psteptitle="'e나라도움 가입정보(ID/PW)'" :pstepnum="7" :psteptp="21"
                                    @click="stepnum = 7" @saveclick="stepnum = 8" @nextclick="stepnum = 8" @cancelClick="stepnum = 0"/>

                                <Step3_8 :psteptitle="'도장 날인(첨부파일)'"  :pstepnum="8" :psteptp="8"
                                    @click="stepnum = 8" @saveclick="stepnum = 9" @nextclick="stepnum = 9" @cancelClick="stepnum = 0"/>

                                    <Step4_1 :psteptitle="'사업계획서 작성하기'" :pstepnum="9" :psteptp="1"
                                    @click="stepnum = 9" @saveclick="stepnum = 10" @nextclick="stepnum = 10" @cancelClick="stepnum = 0"/>

                                <Step4_2 :psteptitle="'판매제품 사진(첨부파일)'" :pstepnum="10" :psteptp="2"
                                    @click="stepnum = 10" @saveclick="stepnum = 11" @nextclick="stepnum = 11" @cancelClick="stepnum = 0"/>

                                <Step4_3 :psteptitle="'공장 배치도(첨부파일)'" :pstepnum="11" :psteptp="3"
                                    @click="stepnum = 11" @saveclick="stepnum = 12" @nextclick="stepnum = 12" @cancelClick="stepnum = 0"/>

                                <Step4_4 :psteptitle="'공정도 및 공정별 사진(첨부파일)'" :pstepnum="12" :psteptp="4"
                                    @click="stepnum = 12" @saveclick="stepnum = 13" @nextclick="stepnum = 13" @cancelClick="stepnum = 0"/>

                                <Step4_5 :psteptitle="'특허 및 인증 서류(첨부파일)'" :pstepnum="13" :psteptp="5"
                                    @click="stepnum = 13" @saveclick="stepnum = 0" @nextclick="stepnum = 0" @cancelClick="stepnum = 0"/>
                            </v-stepper>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2"></div>
            </div>
        </div>
    </div>

</template>

<script>
    import fileService from '@/utils/fileService';

    //추진절차 3단계(1스탭 ~ 8스탭)
    import Step3_1 from './StepPage/DOC_COMMON.vue';        //기본입력
    import Step3_2 from './StepPage/DOC_NM1.vue';           //사업자등록증(첨부파일)
    import Step3_3 from './StepPage/DOC_NM3.vue';           //회사 소개서(첨부파일)
    import Step3_4 from './StepPage/DOC_NM5AND9.vue';       //3개년 부가가치세 표준 증명(첨부파일)
    import Step3_5 from './StepPage/DOC_NM10.vue';          //중소기업확인서 or 월별원천징수 이행상황 신고서(첨부파일)
    import Step3_6 from './StepPage/DOC_NM20.vue';        //신청서(첨부파일)
    import Step3_7 from './StepPage/DOC_JOININFO.vue';      //e나라도움 가입정보(ID/PW)
    import Step3_8 from './StepPage/DOC_NM26.vue';          //도장 날인(첨부파일)

    //추진절차 4단계(1스탭 ~ 5스탭)
    import Step4_1 from './StepPage/DOC_NM4.vue';           //사업계획서 작성하기
    import Step4_2 from './StepPage/DOC_NM21.vue';          //완제품 사진(첨부파일)
    import Step4_3 from './StepPage/DOC_NM22.vue';          //공장 배치도(첨부파일)
    import Step4_4 from './StepPage/DOC_NM23.vue';          //공정도 및 공정별 사진(첨부파일)
    import Step4_5 from './StepPage/DOC_NM25.vue';          //특허 및 인증 서류(첨부파일)

    export default {
        components: {
            Step3_1,
            Step3_2,
            Step3_3,
            Step3_4,
            Step3_5,
            Step3_6,
            Step3_7,
            Step3_8,
            Step4_1,
            Step4_2,
            Step4_3,
            Step4_4,
            Step4_5,
        },
        name: 'ApplyMod03',
        props: {
            // pbizcd: {type:String, default:''},
            // ppwd: {type:String, default:''},
            // pcloseyn: {type:String, default:'N'},
            // pstepnum: {type:Number , default:1},
            // pdocno: {type:Number, default:1},
            // pwritedt: {type:String, default:''},
            ppagetp : {type:String, default:'3'},
        },
        data() {
            return {
                // BIZ_CD: '',
                PWD: '',
                CLOSE_YN: 'N',
                stepnum: 1,
                // DOC_NO: 1,
                WRITE_DT: '',
                PAGE_TP: '3', //페이지를 구분을 나누어 시기마다 보여지는 스탭을 다르게 하기 위함.
                fileName: '',
                fileLink: '',
            };
        },
        created() {
            this.BIZ_CD = this.$route.query.pbizcd;
            this.PWD = this.$applyuser.PWD;
            this.CLOSE_YN = this.$route.query.pcloseyn;
            this.MOD_CD = this.$route.query.pmodcd;
            // this.stepnum = this.$route.query.pstepnum;
            this.stepnum = '0';
            this.DOC_NO = this.$route.query.pdocno;
            this.WRITE_DT = this.$route.query.pwritedt;
            this.PAGE_TP = this.$route.query.ppagetp;

            if(this.PAGE_TP === '3')
            {
                this.TITLE = '스마트 공방 사업 신청';
            }
            else if(this.PAGE_TP === '4')
            {
                this.TITLE = '스마트 공방 사업계획서 작성';
            }
            else if(this.PAGE_TP === '')
            {
                this.TITLE = '스마트 공방 신청서 확인';
            }
        },
        mounted() {
            // console.log('ApplyMod03.vue >>>> this.DOC_NO : ', this.DOC_NO
            //             , ', this.BIZ_CD : ', this.BIZ_CD
            //             , ', this.MOD_CD : ', this.MOD_CD
            //             , ', this.STEP_NUM : ', this.stepnum
            //             , ', this.WRITE_DT : ', this.WRITE_DT);
        },
        methods:{
            async sendApplymod() {
                await this.$http.get('/board/apply-send-select', {DOC_NO: this.DOC_NO, MOD_CD: this.MOD_CD}).then(async res => {

                    if(res.errorcode=="") {
                        if(res.result[0] == null || res.result[0].length == 0 )
                        {
                            // this.movePage('/apply-list?pbizcd=' + this.BIZ_CD + '&ppwd=');
                        }
                        else
                        {
                            //CONF_SEND_YN 값
                            //N.미작성내용 존재, Y.정상적으로 모두 작성함
                            if(res.result[0][0].CONF_SEND_YN === 'Y')
                            {
                                this.movePage('/apply-list?pbizcd=' + this.BIZ_CD + '&ppagetp=' + this.PAGE_TP);
                            }
                            else
                            {
                                let cmf = await this.$confirm('아직 작성하지 않은 내용이 존재합니다. 확인 후 작성해주시기 바랍니다.', {
                                    title: '확인'
                                })

                                if(cmf) {
                                    this.movePage('/apply-list?pbizcd=' + this.BIZ_CD + '&ppagetp=' + this.PAGE_TP);
                                }
                            }
                        }
                    }
                    else
                    {
                        alert(res.errorMsg);
                    }
                }).catch(err => {
                    alert(err)
                })
            },
            downloadFile (event, step) {
                this.fileName = '';
                this.fileLink = 'https://docusystem.s3.ap-northeast-2.amazonaws.com/BIZDOC/0000000000/03/00000000/';

                if(step == '00') {
                    this.fileName = '2023_스마트공방_샘플_사업계획서.hwp';
                }
                this.fileLink = this.fileLink + this.fileName;
                event.preventDefault();
                fileService.downloadFileFromS3(this.fileLink);
            },
        }
    }
</script>
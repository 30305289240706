<template>
    <div>
        <Navbar />
        <PageTitle />
        <Customer />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import PageTitle from '../Customer/PageTitle'
    import Customer from '../Customer/Customer'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'ContactPage',
        components: {
            Navbar,
            PageTitle,
            Customer,
            Footer,
            CopyRight,
        }
    }
</script>
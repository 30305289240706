<template>
    <div class="page-title-area item-bg-1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-title-content">
                        <h2>파트너스 회원가입</h2>
                        <ul>
                            <li><router-link to="/">Home</router-link></li>
                            <li>파트너스 회원가입</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTitle'
    }
</script>

const urlInfo = {

    URL_DEV: 'http://127.0.0.1:5050',
    URL_REAL: 'https://dareinc.co.kr/api',
    BASE_URL: process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5050' : 'https://dareinc.co.kr/api',
    UPLOAD_FILE_LOCAL: 'files/upload-local',
    UPLOAD_FILE_S3: 'files/upload-s3',
    DELETE_FILE_LOCAL: 'files/delete-local',
    DELETE_FILE_S3: 'files/delete-s3',
    DOWNLOAD_FILENAME: 'files/download-filename',
    APPLY_DOWNLOAD_DATA: 'files/apply-download-data',
}

module.exports = urlInfo;
<template>
    <div class="clients-section ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>주요 환급 사례</h2>
                <div class="bar"></div>
            </div>

            <div class="details-image">
                <img src="../../assets/images/projects/projects-three-3.jpg" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProjectsAbout'
    }
</script>
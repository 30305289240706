<template>
    <div class="signup-section ptb-100 d-pp">
        <div class="container">
            <div class="section-title">
                <h2>약관동의</h2>
                <p>주식회사 다래아이엔씨 서비스의 이용과 관련하여 필요한 사항을 규정합니다.</p>
                <div class="bar"></div>
            </div>

            <div class="signup-form">
                <div class="row">
                    <div class="col-lg-12 agree-group">
                        <div>
                            <h5><i class="mdi mdi-asterisk font-blue"></i> 필수적 법인(신용)정보 수집∙이용에 관한 사항</h5>
                            <div class="agree-check">
                                <v-radio-group
                                v-model="applyStep1"
                                row
                                >
                                <v-radio                                    
                                    label="동의함"
                                    :value="1"                                    
                                ></v-radio>
                                <v-radio 
                                    
                                    label="동의안함"
                                    :value="2" 
                                ></v-radio>                               
                                </v-radio-group>
                            </div>
                        </div>
                        <div class="agree-text-box border">
                            <h6>가. 정보 수집∙이용목적</h6>
                            <ul class="list-style-none">
                                <li><p>(1) 서비스 이용신청 및 승낙</p></li>
                                <li><p>(2) 주식회사 다래아이엔씨 기본서비스(사업 신청 방법 안내 영상, 제출 서류 첨부 기능, 문의사항 응답, 신청 현황 확인 등) 제공</p></li>
                                <li><p>(3) 서비스 부정이용 방지</p></li>
                                <li><p>(4) 분쟁조정을 위한 기록보존</p></li>
                                <li><p>(5) 각종 고지 및 통지</p></li>
                            </ul>
                            <h6 class="d-mt-30">나. 수집∙이용되는 법인(신용)정보의 항목</h6>
                            <ul class="list-style-none">
                                <li>
                                    <p>(1) 서비스 이용신청 및 승낙 (회원가입)</p>
                                    <p class="d-pl-30"><i class="mdi mdi-check font-gray"></i> 법인/개인 구분, 사업자등록번호, 법인등록번호, 회사명, 대표자명, 종사업장번호, 업종, 업태, 회사전화번호, 주소</p>
                                </li>
                                <li class="d-mt-10">
                                    <p>(2) 주식회사 다래아이엔씨 기본서비스(사업 신청 방법 안내 영상, 제출 서류 첨부 기능, 문의사항 응답, 신청 현황 확인 등) 제공 관련</p>
                                    <p class="d-pl-30"><i class="mdi mdi-check font-gray"></i> 법인/개인 구분, 사업자등록번호, 법인등록번호, 회사명, 대표자명, 종사업장번호, 업종, 업태, 회사전화번호, 주소</p>
                                    <p class="d-pl-30"><i class="mdi mdi-check font-gray"></i> 서비스 이용시간/이용기록, 이용정지기록, 이용정지/해지 정보, 접속IP, 결제기록, 접속로그, 이용컨텐츠, 쿠키 등 서비스 이용정보 등</p>
                                </li>
                                <li class="d-mt-10">
                                    <p>(3) 서비스 부정이용 방지</p>
                                    <p class="d-pl-30"><i class="mdi mdi-check font-gray"></i> 법인/개인 구분, 사업자등록번호, 법인등록번호, 회사명, 대표자명, 종사업장번호, 업종, 업태, 회사전화번호, 주소</p>
                                    <p class="d-pl-30"><i class="mdi mdi-check font-gray"></i> 서비스 이용시간/이용기록, 이용정지기록, 이용정지/해지 정보, 접속IP, 결제기록, 접속로그, 이용컨텐츠, 쿠키 등 서비스 이용정보 등</p>
                                </li>
                                <li class="d-mt-10">
                                    <p>(4) 분쟁조정을 위한 기록보존</p>
                                    <p class="d-pl-30"><i class="mdi mdi-check font-gray"></i> 법인/개인 구분, 사업자등록번호, 법인등록번호, 회사명, 대표자명, 종사업장번호, 업종, 업태, 회사전화번호, 주소</p>
                                    <p class="d-pl-30"><i class="mdi mdi-check font-gray"></i> 서비스 이용시간/이용기록, 이용정지기록, 이용정지/해지 사유, 스팸차단서비스에 의해 차단되는 스팸메시지 원문 및 발송통신사, 접속IP, 결제기록, 접속로그, 이용컨텐츠, 쿠키 등 서비스 이용정보 등</p>
                                </li>
                                <li class="d-mt-10">
                                    <p>(5) 각종 고지 및 통지</p>
                                    <p class="d-pl-30"><i class="mdi mdi-check font-gray"></i> 법인/개인 구분, 사업자등록번호, 법인등록번호, 회사명, 대표자명, 종사업장번호, 업종, 업태, 회사전화번호, 주소</p>
                                </li>
                            </ul>
                            <h6 class="d-mt-30">다. 수집∙이용되는 법인(신용)정보의 보유기간</h6>
                            <ul class="list-style-none">
                                <li>
                                    <p><i class="mdi mdi-check font-gray"></i> 링크 서비스이용계약 종료시까지 (단, 관계법령에 별도의 규정이 있는 경우에는 그에 따름)</p>
                                </li>
                            </ul>
                            <h6 class="d-mt-30">라. 동의를 거부할 권리가 있다는 사실 및 동의거부에 따른 불이익이 있는 경우에는 그 불이익의 내용</h6>
                            <ul class="list-style-none">
                                <li>
                                    <p><i class="mdi mdi-check font-gray"></i> 이용자는 본 법인(신용)정보 수집이용동의를 거부할 수 있습니다. 단, 위 동의를 거부하는 경우 서비스 이용계약 체결(회원가입)및 기본서비스 제공이 거절되는 불이익이 발생할 수 있습니다.</p>
                                </li>
                            </ul>
                            <h6 class="d-mt-30">마. 정부지원사업에 따른 부정수급 금지 및 제출서류 확인</h6>
                            <ul class="list-style-none">
                                <li>
                                    <p>회원은 정부지원사업을 신청하는 과정에서 제출한 신청서와 사실을 입증하기 위하여 제출한 증빙서류는 모두 모두 거짓 없는 사실임을 확인합니다. 만약 위 사항에 거짓이 있거나, 그 밖의 부적절한 방법으로 정부지원금을 지급받는 등 부정수급 행위가 확인되는 경우, 그 즉시 회사의 사업 지원 서비스는 중단되며 이에 대한 금전적, 법적 불이익은 회원이 책임집니다.</p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="agree-group d-mt-30">
                        <div>
                            <h5><i class="mdi mdi-asterisk font-blue"></i> 선택적 법인(신용)정보 수집∙이용에 관한 사항</h5>
                            <div class="agree-check">
                                <v-radio-group 
                                v-model="applyStep2"
                                row
                                >
                                <v-radio                                    
                                    label="동의함"
                                    :value="1"                                    
                                ></v-radio>
                                <v-radio                                    
                                    label="동의안함"
                                    :value="2" 
                                ></v-radio>                               
                                </v-radio-group>
                            </div>
                        </div>
                        <div class="agree-text-box border">
                            <h6>가. 정보 수집∙이용목적</h6>
                            <ul class="list-style-none">
                                <li><p>(1) 고충 및 문의처리, 서비스 개선 자료 활용</p></li>
                                <li><p>(2) 신규서비스 개발, 통계분석, 서비스 유효성 확인</p></li>
                            </ul>
                            <h6 class="d-mt-30">나. 수집∙이용되는 법인(신용)정보의 항목</h6>
                            <ul class="list-style-none">
                                <li>
                                    <p>(1) 기업회원의 고충 및 문의처리, 서비스 개선 자료 활용</p>
                                    <p class="d-pl-30"><i class="mdi mdi-check font-gray"></i> 법인/개인 구분, 사업자등록번호, 법인등록번호, 회사명, 대표자명, 종사업장번호, 업종, 업태, 회사전화번호, 주소</p>
                                </li>
                                <li class="d-mt-10">
                                    <p>(2) 신규서비스 개발, 통계분석, 서비스 유효성 확인</p>
                                    <p class="d-pl-30"><i class="mdi mdi-check font-gray"></i> 법인/개인 구분, 사업자등록번호, 법인등록번호, 회사명, 대표자명, 종사업장번호, 업종, 업태, 회사전화번호, 주소</p>
                                </li>
                            </ul>
                            <h6 class="d-mt-30">다. 수집∙이용되는 법인(신용)정보의 보유기간</h6>
                            <ul class="list-style-none">
                                <li>
                                    <p><i class="mdi mdi-check font-gray"></i> 링크 서비스이용계약 종료시까지 (단, 관계법령에 별도의 규정이 있는 경우에는 그에 따름)</p>
                                </li>
                            </ul>
                            <h6 class="d-mt-30">라. 동의를 거부할 권리가 있다는 사실 및 동의거부에 따른 불이익이 있는 경우에는 그 불이익의 내용</h6>
                            <ul class="list-style-none">
                                <li>
                                    <p><i class="mdi mdi-check font-gray"></i> 이용자는 본 법인(신용)정보 수집이용동의를 거부할 수 있습니다. 단, 위 동의를 거부하는 경우 기업회원의 고충, 문의처리, 서비스 개선 요청 제한 ,통계분석 기능 제공 제한 등의 불이익이 발생할 수 있습니다.</p>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="agree-group all col-lg-12 text-center">
                        <div class="col-lg-12">
                            <v-radio-group 
                                v-model="applyStepAll"
                                row
                                @change="radioChanged"
                            >
                                <v-radio 
                                    label="모두동의"
                                    :value="1" 
                                ></v-radio>
                                <v-radio 
                                    label="미동의"
                                    :value="2" 
                                ></v-radio>
                            </v-radio-group>
                        </div>
                    </div>

                    <div class="col-lg-12 text-center">
                        <div>
                            <div class="banner-btn text-center">
                                <a class="dare-btn font-white d-mt-20 d-mr-10 d-pa-10 d-ptb-10" @click="setApplyMod" >
                                    <i class="mdi mdi-check"></i> <b>확인</b>
                                </a>
                                
                                <a @click="moveBack" class="dare-btn bg-color-white border font-blue d-mt-20 d-ptb-10">
                                    <i class="mdi mdi-close"></i> <b>취소</b>
                                </a>                                                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ApplyJoin',      
        data() {            
            return {
                DOC_NO: 1,          //문서번호
                BIZ_CD: '',         //사업자등록번호
                PWD: '',            //패스워드
                PRODUCT_CD: '',     //제품구분 CM:일반, PL:LINK, PE:ERP, PI:다래 LITE(라이트), PT:세재환급사업
                MOD_CD: '',         //사업구분 01.일반구매, 02.스마트공장, 03.스마트공방, 04.A/S사업, 05.세제환급사업
                applyStep1: 2,      //약관1 (1:동의, 2:미동의)
                applyStep2: 2,      //약관2 (1:동의, 2:미동의)
                applyStepAll: 2,    //전체동의 (1:전체동의, 2:미동의)
                WRITE_DT: '',       //신청일자 yyyyMMdd 
            };
        },
        mounted() {
            this.BIZ_CD = this.$route.query.pbizcd;
            this.PWD = this.$applyuser.PWD;
            this.PRODUCT_CD = this.$route.query.pproductcd;
            this.MOD_CD = this.$route.query.pmodcd;            
            this.WRITE_DT = new Date().toJSON().slice(0,10).replace(/-/g,'');
            // this.WRITE_DT = (new Date().getFullYear()) 
            // + (new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1 ) : new Date().getMonth() + 1)             
            // + (new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate());

            // console.log('ApplyPP.vue >>>> this.BIZ_CD : ', this.BIZ_CD
            //             , ', this.PWD : ', this.PWD
            //             , ', this.PRODUCT_CD : ', this.PRODUCT_CD
            //             , ', this.MOD_CD : ', this.MOD_CD
            //             , ',this.WRITE_DT : ', this.WRITE_DT
            //             , ',this.CLOSE_YN : ', this.CLOSE_YN
            //             );

        },
        methods:{            
            radioChanged() {
                if(this.applyStepAll === 1)
                {
                    this.applyStep1 = 1
                    this.applyStep2 = 1
                }
                else if(this.applyStepAll === 2)
                {
                    this.applyStep1 = 2
                    this.applyStep2 = 2
                }
            },
            getSelect() {

            },
            async setApplyMod() {
                if(this.applyStep1==1 && this.applyStep2==1)
                {                       
                    await this.$http.post('/board/apply-insert', {
                        PRODUCT_CD: this.PRODUCT_CD,            //제품구분 CM:일반, PL:LINK, PE:ERP, PI:다래 LITE(라이트) [필수]
                        MOD_CD: this.MOD_CD,                    //사업구분 / 01.일반구매, 02.스마트공장, 03.스마트공방, 04.A/S사업 [필수]
                        BIZ_CD: this.BIZ_CD,                    //사업자등록번호 [필수]
                        PWD: this.PWD,                          //패스워드 [필수]
                        WRITE_DT: this.WRITE_DT,                //신청일자 [필수]
                        CLIENT_IP: this.$applyuser.CLIENT_IP,   //입력자IP			
                    }).then(res => {
                        if (res.result) {
                            // this.$msgBox.show('Insert Complete.');                            
                            if(this.MOD_CD === '01') {
                                this.movePage('/applymod01?pbizcd=' + this.BIZ_CD + '&pdocno='+ res.result + '&pproductcd=' + this.PRODUCT_CD + '&pmodcd=' + this.MOD_CD + '&pwritedt=' + this.WRITE_DT + '&pcloseyn=N&pstepnum=1');
                            }
                            else if(this.MOD_CD === '02') {
                                this.movePage('/applymod02?pbizcd=' + this.BIZ_CD + '&pdocno='+ res.result + '&pproductcd=' + this.PRODUCT_CD + '&pmodcd=' + this.MOD_CD + '&pwritedt=' + this.WRITE_DT + '&pcloseyn=N&pstepnum=1');
                            }
                            else if(this.MOD_CD === '03') {
                                this.movePage('/applymod03?pbizcd=' + this.BIZ_CD + '&pdocno='+ res.result + '&pproductcd=' + this.PRODUCT_CD + '&pmodcd=' + this.MOD_CD + '&pwritedt=' + this.WRITE_DT + '&pcloseyn=N&pstepnum=1&ppagetp=3');
                            } 
                            else if(this.MOD_CD === '04') {
                                this.movePage('/applymod04?pbizcd=' + this.BIZ_CD + '&pdocno='+ res.result + '&pproductcd=' + this.PRODUCT_CD + '&pmodcd=' + this.MOD_CD + '&pwritedt=' + this.WRITE_DT + '&pcloseyn=N&pstepnum=1');
                            }     
                            else if(this.MOD_CD === '05') {
                                this.movePage('/applymod05?pbizcd=' + this.BIZ_CD + '&pdocno='+ res.result + '&pproductcd=' + this.PRODUCT_CD + '&pmodcd=' + this.MOD_CD + '&pwritedt=' + this.WRITE_DT + '&pcloseyn=N&pstepnum=1');
                            }                        
                        }
                    }).catch(err => {
                        alert(err)
                    })				    
                }
                else
                {
                    this.$msgBox.show('약관동의가 필요합니다.');
                    return;
                }
            },
            moveBack() {
                this.movePage('/apply-list?pbizcd=' + this.BIZ_CD + '&ppagetp=' + this.PAGE_TP);
            },
        }
    }
</script>
<template>
    <div class="contact-section ptb-50 d-qna d-apply">
        <div class="container">
            <div class="section-title">
                <h2>Link(링크) 사업 신청</h2>
                <div class="bar"></div>
            </div>          
            <div class="row align-items-center">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <div class="contact-form">
                        <form ref="form" @submit.prevent="sendEmail">
                            <v-card
                            :loading="loading"
                            class="mb-12"
                            max-width="560"
                            >
                            <iframe 
                                    width="560" 
                                    height="315" 
                                    src="https://www.youtube.com/embed/7JMpmiQLES0" 
                                    title="YouTube video player" 
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen
                            ></iframe>
                            </v-card>  
                            <div class="d-mt-10 d-mb-30"><span class="font-size-15 font-blue font-w-bold">* 1~7번 항목은 필수 입력값 입니다.</span></div>
                            <div class="form-group">
                                <label for="biz-select"><h5 class="font-w-normal">1. 신청 방법</h5></label>
                                <div>
                                    <select name="qna" id="biz-select" v-model="qna" class="form-control" @change="changedSelect($event)">
                                        <option disabled value="">신청방법선택</option>
                                        <option value="스마트공장">스마트공장</option>
                                        <option value="스마트공방">스마트공방</option>
                                        <option value="일반구매">일반구매</option>
                                    </select>
                                </div>
                                <input v-show="false" type="text"  name="txtqna" id="msg_txtqna" v-model="txtqna" class="form-control" required placeholder="신청사업">
                            </div>

                            <!-- <div class="form-group">
                                <label for="sns-select"><h5 class="font-w-normal">2. 신청경로선택</h5></label>
                                <div>
                                    <select name="sns" id="sns-select" v-model="sns" class="form-control" @change="changedSelect($event)">
                                        <option disabled value="">신청경로선택</option>
                                        <option value="네이버블로그">네이버 블로그</option>
                                        <option value="네이버블로그">네이버 카페</option>
                                        <option value="페이스북">네이버 모두</option>
                                        <option value="유튜브">유튜브</option>
                                        <option value="페이스북">인스타그램</option>
                                        <option value="페이스북">페이스북</option>
                                        <option value="페이스북">카카오플러스친구</option>
                                    </select>
                                </div>
                                <input v-show="false" type="text"  name="txtqna" id="msg_txtqna" v-model="txtqna" class="form-control" required placeholder="신청경로">
                            </div> -->

                            <div class="form-group">
                                <label for="input-coname"><h5 class="font-w-normal">2. 회사명<span class="font-size-15 font-w-normal">(사업자등록증에 기재된 업체명과 동일하게 입력해주시길 바랍니다.)</span></h5></label>
                                <input type="text" name="company" id="input-coname" v-model="company" class="form-control" required placeholder="회사명">
                            </div>

                            <div class="form-group">
                                <label for="input-name"><h5 class="font-w-normal">3. 대표자명</h5></label>
                                <input type="text" name="name" id="input-name" v-model="name" class="form-control" required placeholder="대표자명">
                            </div>

                            <div class="form-group">
                                <label for="input-name"><h5 class="font-w-normal">4. 담당자명 / 직함</h5></label>
                                <input type="text" name="name" id="input-name" v-model="name" class="form-control" required placeholder="담당자명 / 직함">
                            </div>

                            <div class="form-group">
                                <label for="input-name"><h5 class="font-w-normal">5. 담당자 연락처</h5></label>
                                <input type="text" name="tel" id="input-tel" v-model="tel" class="form-control" required placeholder="전화번호 '-'생략">
                            </div>

                            <div class="form-group">
                                <label for="input-name"><h5 class="font-w-normal">6. 담당자 이메일</h5></label>
                                <input type="email" name="email" id="input-email" v-model="email" class="form-control" required placeholder="담당자 이메일">
                                <div class="d-mt-10">
                                    <form id="">		
                                        <select name="mailtail" v-model="mailtail" class="form-control"  @change="changedSelectMail($event)">
                                            <option value="">전체입력</option>
                                            <option value="@gmail.com">@gmail.com</option>
                                            <option value="@naver.com">@naver.com</option>
                                            <option value="@nate.com">@nate.com</option>
                                        </select>
                                    </form>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="input-name"><h5 class="font-w-normal">7. 세무담당자명</h5></label>
                                <input type="text" name="name" id="input-name" v-model="name" class="form-control" required placeholder="담당자명 / 직함">
                            </div>

                            <div class="form-group">
                                <label for="input-name"><h5 class="font-w-normal">8. 세무담당자 연락처</h5></label>
                                <input type="text" name="tel" id="input-tel" v-model="tel" class="form-control" required placeholder="전화번호 '-'생략">
                            </div>

                            <!-- <div class="form-group">
                                <label for="input-mobile">휴대폰번호를 입력해주세요</label>
                                <input type="text" name="mobile" id="input-mobile" v-model="mobile" class="form-control" required placeholder="휴대폰번호 '-'생략">
                            </div> -->
                          
                            <div>
                                <v-stepper
                                    v-model="stepnum"
                                    vertical
                                >                                    
                                    <Step2 v-model="stepnum" @click="stepnum = 1" @saveclick="stepnum = 2" @nextclick="stepnum = 2" @cancelClick="stepnum = 0"/>
                                    <Step3 v-model="stepnum" @click="stepnum = 2" @saveclick="stepnum = 3" @nextclick="stepnum = 3" @cancelClick="stepnum = 0"/>                                    
                                    <Step4 v-model="stepnum" @click="stepnum = 3" @saveclick="stepnum = 0" @nextclick="stepnum = 1" @cancelClick="stepnum = 0"/>                                   
                                </v-stepper>
                            </div>
                            <!-- <div class="form-group">
                                <label for="input-name"><h5 class="font-w-normal">9. 첨부파일1(사업계획서)</h5></label>
                                <input type="file" name="myfile" id="input-file" class="form-control" required placeholder="사업계획서 업로드">
                            </div>

                            <div class="form-group">
                                <label for="input-name"><h5 class="font-w-normal">9. 첨부파일2(사업자등록증)</h5></label>
                                <input type="file" name="myfile" id="input-file" class="form-control" required placeholder="사업자등록증 업로드">
                            </div>

                            <div class="form-group">
                                <label for="input-name"><h5 class="font-w-normal">9. 첨부파일3(중소기업확인서)</h5></label>
                                <input type="file" name="myfile" id="input-file" class="form-control" required placeholder="중소기업확인서 업로드">
                            </div> -->

                            <div class="form-group">
                                <label for="input-name"><h5 class="font-w-normal">9. 첨부파일4(회사소개서)</h5></label>
                                <input type="file" name="myfile" id="input-file" class="form-control" required placeholder="회사소개서 업로드">
                            </div>

                            <div class="form-group">
                                <label for="input-name"><h5 class="font-w-normal">10. 남기는 말(선택사항)</h5></label>
                                <textarea name="message" class="form-control" id="qna-message" v-model="message" cols="30" rows="6" required placeholder="남기는 말 작성"></textarea>
                            </div>

                            <div class="send-btn">
                                <button type="submit" class="default-btn font-w-bold">
                                    신청서 보내기
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-2"></div>
            </div>
        </div>
    </div>
    
</template>

<script>    
    import Step2 from './StepPage/DOC_NM1.vue';
    import Step3 from './StepPage/DOC_NM2.vue';
    import Step4 from './StepPage/DOC_NM3.vue';

    export default {
        components: {
            Step2,
            Step3,
            Step4,
        },
        name: 'Apply',
        data() {
            return {
                txtqna : '',
                company : '',
                name : '',
                tel : '',
                email : '',
                subject : '',
                txtmodule : '',
                message : '',
                qna : '',
                mailtail : '',

                stepnum: 1,    
            };
        },

        methods:{            
            //입력 컨트롤 초기화
            controlClear(){
                this.company = '',
                this.name = '',
                this.email = '',
                this.tel = '',
                this.mobile = '',           
                this.subject = '',                
                this.message = '',
                this.qna = '',
                this.mailtail = ''
            },                       
            changedSelectMail(event)
            {
                let str = this.email;

                if(str.indexOf('@') >= 0 )                
                    this.email = str.substr(0, str.indexOf('@'));                    
                
                this.email = this.email + event.target.value;    
            },
        }
    }
</script>
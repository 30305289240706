<template>
    <div class="audience-section ptb-100 d-link-services">
        <CoolLightBox
            :items="items"
            :fullScreen="true"
            :index="index"
            @close="index = null">
        </CoolLightBox>

        <div class="container">
            <div class="section-title">
                <h2>링크란?</h2>
                <div class="bar"></div>
            </div>

            <div class="details-image">
                <img src="../../assets/images/services/services-three-1.jpg" alt="링크는 터치스크린과 소프트웨어로 결합으로 이루어진 생산현장 중심의 IOT 솔루션">
            </div>

            <div class="section-title pt-100">
                <h2>링크 Process</h2>
                <div class="bar"></div>
            </div>

            <div class="details-image">
                <img src="../../assets/images/services/services-three-2.jpg" alt="image">
            </div>

            <div class="section-title pt-100">
                <h2>링크의 필요성</h2>
                <div class="bar"></div>
            </div>

            <div class="details-image">
                <img src="../../assets/images/services/services-three-3.jpg" alt="image">
            </div>

            <div class="section-title pt-100">
                <h2>링크 홍보동영상</h2>
                <div class="bar"></div>
            </div>

            <div class="link-video">
                <iframe
                        src="https://www.youtube.com/embed/hqADvFHlbIk"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        allowTransparency="true"
                        style="opacity: 1;"
                    >
                </iframe>
                <h4 class="text-center mt-12">[ 링크 홍보동영상 스크린샷 ]</h4>
                <div class="details-image mt-6">
                    <img src="../../assets/images/services/services-three-4.jpg" alt="보안이 어려운 종이 작업지시서... 정확한 업무순서! 알고 싶나요?">
                </div>
                <div class="details-image mt-1">
                    <img src="../../assets/images/services/services-three-5.jpg" alt="글로만 작성된 복잡한 내용의 작업지시서... 답답하지 않나요?">
                </div>
                <div class="details-image mt-1">
                    <img src="../../assets/images/services/services-three-6.jpg" alt="왼쪽부터 작업표준서, Q-Point 링크 프로그램 화면캡쳐 이미지">
                </div>
                <div class="details-image mt-1">
                    <img src="../../assets/images/services/services-three-7.jpg" alt="왼쪽부터 간편실적관리(현장 작업자용), LINK 간편실적관리(관리자용) 링크 프로그램 화면캡쳐 이미지">
                </div>
            </div>

            <div class="section-title pt-100">
                <h2>링크 프로그램</h2>
                <div class="bar"></div>
            </div>

            <div class="details-image">
                <img src="../../assets/images/services/services-three-8.jpg" alt="왼쪽 위부터 작업표준서(작업지시서), 간편실적관리, Q-POINT, LINK 간편실적관리 현황 링크 프로그램 화면캡쳐 이미지">
            </div>

            <!-- <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog services-details-video">
                        <div class="image details-image">
                            <router-link to="#">
                                <img src="../../assets/images/projects/projects-one.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="details-video">
                            <div
                                class="video-btn popup-youtube"
                                v-for="(image, imageIndex) in items"
                                :key="imageIndex"
                                @click="index = imageIndex"
                                style="cursor: pointer;"
                            >
                                <i class="bx bx-play"></i>
                            </div>
                        </div>

                        <div class="content">
                            <h3>
                                <router-link to="#">
                                    1. 스마트공장
                                </router-link>
                            </h3>
                            <p>설명</p>
                            <router-link to="#" class="read-more">자세히보기</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog">
                        <div class="image">
                            <router-link to="#">
                                <img src="../../assets/images/projects/projects-one.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="content">
                            <h3>
                                <router-link to="#">
                                    2. 스마트공방
                                </router-link>
                            </h3>
                            <p>설명</p>
                            <router-link to="#" class="read-more">자세히보기</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog">
                        <div class="image">
                            <router-link to="#">
                                <img src="../../assets/images/projects/projects-one.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="content">
                            <h3>
                                <router-link to="#">
                                    3. 일반구매
                                </router-link>
                            </h3>
                            <p>설명</p>
                            <router-link to="#" class="read-more">자세히보기</router-link>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    import CoolLightBox from 'vue-cool-lightbox'
    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

    export default {
        name: 'Link',
        components: {
            CoolLightBox
            },
        data: function () {
            return {
                items: [
                    {
                        src: 'https://www.youtube.com/watch?v=jV2HPYTzF7w',
                    }
                ],
                index: null
            };
        },
    }
</script>
<template>
    <div class="audience-section ptb-100 d-list-overview">
        <div class="container">
            <div class="section-title">
                <h2>다래ERP 개요</h2>
                <p>다래 ERP 솔루션의 인프라는 어떠한 상황에서도 안정적인 운영이 가능한 기반을 갖추고 있습니다. <br>다래아이엔씨는 10년간 쌓아온 IT 기술력으로 높은 성장을 이뤄왔습니다.</p>
                <div class="bar"></div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="audience-content">
                        <!-- <h5>다래ERP</h5> -->
                        <!-- <div class="bar"></div> -->
                        <ul class="list d-mt-30">
                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>해당 분야에서 다년간 구축 경험을 토대로 실무 중심의 프로세스를 구현함으로써 사용자들의 접근성을 높였습니다.</p>
                            </li>

                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>.NET기반의 자체 프레임워크와 개발스튜디오를 통해 개발생산성과 유지보수가 용이 합니다.</p>
                            </li>

                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>SI가 아닌 솔루션을 기반으로 고객사의 업무특성에 맞는 개발, 구축컨설팅을 통하여 최적화가 가능합니다.</p>
                            </li>

                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>ERP뿐만 아니라 자체 그룹웨어, SCM, WEB주문 시스템, 모바일 솔루션과 자연스러운 연동이 가능합니다.</p>
                            </li>

                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>기준정보 뿐만 아니라 대부분의 메뉴에서 엑셀업로드 기능을 지원하기 때문에 ERP 교체에 따른 데이터 입력이 용이 합니다.</p>
                            </li>

                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>상시 모니터링 및 로그 분석을 통하여 시스템 오류 및 고객 불편사항을 신속하게 처리할 수 있는 인적, 물적 시스템을 상시 운영하고 있습니다.</p>
                            </li>

                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>다국어 버전을 통하여 해외생산공장이 있는 국내 기업은 현지공장의 재고관리, 생산관리 등을 모니터링할 수 있습니다.(현재는 베트남어, 영어)</p>
                            </li>

                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>모바일 EIS를 통하여 최고경영자 또는 대표이사가 언제 어디서든 중요한 의사결정을 하기 위한 경영정보를 제공합니다.</p>
                            </li>

                            <li>
                                <b><i class="flaticon-tick font-blue"></i></b>
                                <p>4차 산업혁명시대를 대비하여 AI, 빅데이터 등을 활용하여 기업에서 필요로하는 스마트한 기능 개발을 위한 끊임없는 연구와 노하우가 담겨 있습니다.</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="audience-image">
                        <img src="../../assets/images/services/services-one-1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Audience'
    }
</script>

import axios from 'axios';
import urlInfo from './urlInfo';
import aws from 'aws-sdk';

const fileService = {


    uploadFileToLocal(file, folderInfo, onUploadProgress) {

        if (!file) return;

        const formData = new FormData();
        formData.append("file", file); // appending file
        formData.append("TYPE", folderInfo?.TYPE);
        formData.append("DOC_NO", folderInfo?.DOC_NO);
        formData.append("BIZ_CD", folderInfo?.BIZ_CD);
        formData.append("MOD_CD", folderInfo?.MOD_CD);
        formData.append("WRITE_DT", folderInfo?.WRITE_DT);
        formData.append("STEP_NO", folderInfo?.STEP_NO);
        formData.append("STEP_NUM", folderInfo?.STEP_NUM);
        
        // Object.keys(folderInfo).forEach(key => {
        //     formData.append(key, folderInfo[key]);
        // });

        // sending file to the backend
        //return axios.post(`${urlInfo.BASE_URL}/${urlInfo.UPLOAD_FILE_LOCAL}?userId=${userId}&baseNo=${baseNo}&stepNo=${stepNo}`, formData, {onUploadProgress});
        return axios.post(`${urlInfo.BASE_URL}/${urlInfo.UPLOAD_FILE_LOCAL}`, formData, {onUploadProgress});

    },
    uploadFileToS3(file, folderInfo, onUploadProgress) {

        if (!file) return;
        
        const formData = new FormData();
        formData.append("file", file); // appending file
        formData.append("TYPE", folderInfo?.TYPE);
        formData.append("DOC_NO", folderInfo?.DOC_NO);
        formData.append("BIZ_CD", folderInfo?.BIZ_CD);
        formData.append("MOD_CD", folderInfo?.MOD_CD);
        formData.append("WRITE_DT", folderInfo?.WRITE_DT);
        formData.append("STEP_NO", folderInfo?.STEP_NO);
        formData.append("STEP_NUM", folderInfo?.STEP_NUM);
        
        // Object.keys(folderInfo).forEach(key => {
        //     formData.append(key, folderInfo[key]);
        // });


        //return axios.post(`${urlInfo.BASE_URL}/${urlInfo.UPLOAD_FILE_S3}?userId=${userId}&baseNo=${baseNo}&stepNo=${stepNo}`, formData, {onUploadProgress});
        return axios.post(`${urlInfo.BASE_URL}/${urlInfo.UPLOAD_FILE_S3}`, formData, {onUploadProgress});

    },
    downloadFileFromLocal(path) {
        
        axios({
            url: `${urlInfo.BASE_URL}/${path}`,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            const name = path.split("\\")[path.split("\\").length - 1];
            fileLink.setAttribute('download', name);
            document.body.appendChild(fileLink);

            fileLink.click();
        });
    },
    downloadFileFromS3(url) {

        //s3 url 예:  https://docusystem.s3.ap-northeast-2.amazonaws.com/dareadmin/1/step1/%EB%A1%9C%EA%B7%B8%EC%9D%B8%2C%ED%99%98%EA%B2%BD%EC%84%A4%EC%A0%95.sql
        //let path = decodeURIComponent(url);
        let path = url;
        
        let urlArray = path.split("/");
        //let bucket = urlArray[2]
        let key = `${urlArray[3]}/${urlArray[4]}/${urlArray[5]}/${urlArray[6]}/${urlArray[7]}`;

        let s3 = new aws.S3({
            accessKeyId: process.env.AWS_ACCESS_KEY,     
            secretAccessKey: process.env.AWS_SECRETKEY,  
            region: process.env.AWS_REGION,
        });

        let params = {
            Bucket: process.env.S3_BUCKET_NAME,
            Key: key,
        };

        s3.getObject(params, (err, data) => {

            if(err) {
                alert(err);
                return;
            }

            let blob = new Blob([data.Body], {
                type: data.ContentType
            });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = urlArray[7];
            link.click();
        });
    },
    deleteFileFromLocal(userInfo) {
        const userId = userInfo.userId;
        const baseNo = userInfo.baseNo;
        const stepNo = userInfo.stepNo;

        // sending file to the backend
        return axios.post(`${urlInfo.BASE_URL}/${urlInfo.DELETE_FILE_LOCAL}?userId=${userId}&baseNo=${baseNo}&stepNo=${stepNo}`);
    },
    deleteFileFromS3(userInfo) {
        const userId = userInfo.userId;
        const baseNo = userInfo.baseNo;
        const stepNo = userInfo.stepNo;

        // sending file to the backend
        return axios.post(`${urlInfo.BASE_URL}/${urlInfo.DELETE_FILE_S3}?userId=${userId}&baseNo=${baseNo}&stepNo=${stepNo}`);
    }

}

export default fileService;
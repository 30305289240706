<template>
    <div>
        <Navbar />
        <PageTitle />
        <DarePeople />
        <Footer />
        <CopyRight />
    </div>
</template>

<script>
    import Navbar from '../Layout/Navbar'
    import PageTitle from '../DarePeople/PageTitle'
    import DarePeople from '../DarePeople/DarePeople'
    import Footer from '../Layout/Footer'
    import CopyRight from '../Layout/CopyRight'

    export default {
        name: 'DarePeoplePage',
        components: {
            Navbar,
            PageTitle,
            DarePeople,
            Footer,
            CopyRight,
        }
    }
</script>